import { Accordion } from "@sunwing/components";
import { ContentfulContentEntry } from "../ContentfulContentEntry";
import "./Accordion.modules.scss";
import config from '../../app/config';
import { HelpfulInfoGroupVwq } from '../HelpfulInfoGroupVwq/HelpfulInfoGroupVwq';

const AccordionConnector = ({
  id,
  variant,
  activeKey,
  items,
  userInfo,
  locale,
}) => {
  return (<>{config.isWestJetMode ? 
  (<HelpfulInfoGroupVwq   
    id={id}
    items={items}
    userInfo={userInfo}
    locale={locale}/>) : (
  <Accordion
    theme={variant}
    activeKey={activeKey}
    tabData={items.map((tab) => ({
      key: tab.key,
      toggle: {
        label: {
          heading: tab.heading,
          subHeading: tab.subheading,
          tag: tab.tag,
        },
        icon: tab.icon,
      },
      panel: tab.contentCollection.items.map((item) => (
        <ContentfulContentEntry
          key={item.sys.id}
          entry={item}
          userInfo={userInfo}
          locale={locale}
        />
      )),
    }))}
  />
)}</>)
};

export default AccordionConnector;
export { AccordionConnector };
