const TEAM_MEMBERS_FRAGMENT = `
  fragment TeamMembersFields on TeamMembers { 
    typename
    membersCollection(limit:50){
      items{ 
        sys{
            id
          }       
          role
          name          
          cloudinaryImage
          backgroundColor
          details {
            json
          }              
      }
      }
    }  
`;

export { TEAM_MEMBERS_FRAGMENT };
