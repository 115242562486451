import { DotsLoader } from "./Dots";
import styles from "./Loader.module.scss";
import config from '../../app/config';

const Loader = () => (
  <>
    {config.isWestJetMode ? 
      <div className={styles.loader}>
        <img src="/WestJet-loader.gif" alt="info" />
      </div> : 
      <div className={styles.loader}>
        <DotsLoader />
    </div>
    }
  </>
);

export default Loader;
export { Loader };
