import React, { Component } from "react";
import StarBalanceLoadCard from "./LoadCard";
import { Button } from "@sunwing/components/dist/Button";
import { Loader } from "../../../components/Loader";
import { Slider, Modal, Row, Col, Image } from "antd";
import {
  throttle,
  convertCurrency,
  convertNumber,
  replaceVariables,
} from "./helpers";
import "./StarBalance.scss";
import { RCL } from "../../RCL";
import config from "../../../app/config";

class StarBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadCard: false,
      isModalOpen: false,
      isConfirmModalOpen: false,
      isRenewed: false,
      submitting: false,
    };
  }


  componentDidMount() {
    // this.meter.toggleTooltipVisible(0, true);
    throttle(window.addEventListener("resize", this.onWindowResize), 250);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.state.showLoadCard) {
      // this.meter.toggleTooltipVisible(0, true);
    }
  };

  handleShowLoadCard = () => {
    this.setState(
      (prevState) => {
        return {
          showLoadCard: !prevState.showLoadCard,
        };
      },
      () => {
        if (!this.state.showLoadCard) {
          // this.meter.toggleTooltipVisible(0, true);
        }
      }
    );
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
    if(this.state.isRenewed){
      this.props.refreshData();
    }
  };

  handleRenewCard= ( )=> {    
    const { locale } = this.props.config;
    window.location.href = 
      window.location.origin + 
      `/${locale==='en'? 'en' : 'fr'}/myaccount?section=star&brand=${config.isWestJetMode ? "vwq" : "swg"}`;
  };

  hideConfirmModal=()=>{
    this.setState({
      isConfirmModalOpen: false,
    });
  };

  render() {
    const { locale, labels, footer } = this.props.config;
    const canLoadCard =
      this.props.globalCanLoadCard === "true" &&
      this.props.initialData >
        this.props.config.footer.loadStar.initialData.MinPntsRedeem;

    if (this.state.showLoadCard && canLoadCard) {
      const {
        labels: loadLabels,
        STARData,
        initialData: redemptionRules,
        token,
        urlPost,
        returnUrl,
        csrf,
      } = footer.loadStar;

      return (
        <div className="star-balance">
          <StarBalanceLoadCard
            locale={locale}
            token={token}
            urlPost={urlPost}
            returnUrl={returnUrl}
            csrf={csrf}
            labels={loadLabels}
            availableBalance={this.props.initialData}
            redemptionRules={redemptionRules}
            handleShowLoadCard={this.handleShowLoadCard}
            handleRefreshData={this.props.refreshData}
          />
        </div>
      );
    } else {
      // Initial view of points balance meter
      const marks = {
        0: "0",
        13750: "",
        1000000: "1 000 000",
        2000000: "2 000 000",
      };

      return (
        <div className="star-balance">
          <p className="star-balance__header">
            <span>
              {this.props.initialData ? (
                this.props.initialData
              ) : (
                <RCL searchKey="star_Loadingyour" />
              )}
            </span>{" "}
            <RCL searchKey="star_totalpoints" />
          </p>
          <p className="star-balance__min-redemption">
            {footer.loadStar.initialData.MinPntsRedeem}{" "}
            <RCL searchKey="star_of" /> {labels.minimumRedemption}.
          </p>
          {this.props.initialData >= 0 && (
            <div className="star-balance__meter">
              <Slider
                tipFormatter={() => (
                  <span className="star-balance__meter-tooltip-balance">
                    {labels.yourPoints}{" "}
                    <span>
                      {convertNumber(this.props.initialData, this.props.locale)}
                    </span>
                  </span>
                )}
                marks={marks}
                max={2000000}
                defaultValue={0}
                value={this.props.initialData}
                handleStyle={{ display: "none" }}
              />
            </div>
          )}
          <div className="star-balance__btns">
            {this.state.submitting && <Loader/>}
            <Button
              type="submit"
              className="star-balance__btn"
              disabled={!canLoadCard}
              onClick={this.handleShowLoadCard}
            >
              {footer.loadStar.labels.loadYourSTARCard}
            </Button>
            {this.props.config.footer.loadStar.STARData.IsExpiring ? (
              <Button
                type="submit"
                className="star-balance__btn"
                onClick={this.handleRenewCard}
              >
                {footer.loadStar.labels.renewYourSTARCard}
              </Button>
            ) : (
              <></>
            )}
            <Button
              to={footer.myPointsTransactions.url}
              target="_self"
              outline={true}
            >
              {footer.myPointsTransactions.labels.myPointsTransactions}
            </Button>
          </div>
          <div className="star-balance__note">
            <p>
              <strong>{labels.note}: </strong>
              {replaceVariables(
                labels.balanceNote,
                convertNumber(
                  footer.loadStar.initialData.MinPntsRedeem,
                  this.props.locale
                ),
                convertCurrency(
                  Math.ceil(
                    footer.loadStar.initialData.MinPntsRedeem *
                      footer.loadStar.initialData.PointRate
                  ),
                  locale
                ).replace("$", "")
              )}
            </p>
          </div>
        </div>
      );
    }
  }
}

export default StarBalance;
export { StarBalance };
