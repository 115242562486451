const SEO_FRAGMENT = `
fragment SeoFields on SeoMetaComponent {
    typename
    sys {
      id
    }
    title    
  }
`;

export { SEO_FRAGMENT };
