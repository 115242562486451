import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Icon, Row, Cell } from "@sunwing/components";
import CONFIG from "../../../app/config";
import styles from "./StarStats.module.scss";
import { RCL } from "../../RCL";
import { Loader } from "../../Loader";

const calculateStats = (stats) => {
  let convertedStat = {
    flights: 0,
    hotels: 0,
    cars: 0,
    excursion: 0,
    insurance: 0,
  };

  var bookingComponents = stats.bookingComponents;

  const addComponents = (type, bookingComponent) => {
    convertedStat[type] += bookingComponent.totalComponents;
  };

  // Aggregate total components for each type of booking
  for (let i = 0; i < bookingComponents.length; i++) {
    let _stat = bookingComponents[i];
    switch (_stat.componentType.trim().toUpperCase()) {
      case "FL":
        addComponents("flights", _stat);
        break;
      case "HO":
        addComponents("hotels", _stat);
        break;
      case "CA":
        addComponents("cars", _stat);
        break;
      case "AT":
      case "EC":
      case "EL":
      case "EW":
        addComponents("excursion", _stat);
        break;
      case "IN":
        addComponents("insurance", _stat);
        break;
      default:
        break;
    }
  }

  return convertedStat;
};

const generateStatsDisplay = (bookingStats, labels) => {
  let stats = [];

  for (let stat in bookingStats) {
    let _stat = bookingStats[stat];
    // Icon + #Booking
    let _statBooking;
    // Booking Type
    let _statType;

    // Generate Stat Type ( Icon + Label )
    switch (stat) {
      case "flights":
        _statBooking = (
          <div className="star-stats__items-stat-info">
            <Icon
              className={styles.starStatsIcon}
              name="flight"
              aria-hidden="true"
            />
            <span className={styles.starStatsStat}>{_stat}</span>
          </div>
        );
        _statType = labels.flightSegments;
        break;
      case "hotels":
        _statBooking = (
          <div className="star-stats__items-stat-info">
            <Icon
              className={styles.starStatsIcon}
              name="hotel"
              aria-hidden="true"
            />
            <span className={styles.starStatsStat}>{_stat}</span>
          </div>
        );
        _statType = labels.hotelRooms;
        break;
      case "cars":
        _statBooking = (
          <div className="star-stats__items-stat-info">
            <Icon
              className={styles.starStatsIcon}
              name="airport-transfer"
              aria-hidden="true"
            />
            <span className={styles.starStatsStat}>{_stat}</span>
          </div>
        );
        _statType = labels.carRentals;
        break;
      case "excursion":
        _statBooking = (
          <div className="star-stats__items-stat-info">
            <Icon
              className={styles.starStatsIcon}
              name="excursions"
              aria-hidden="true"
            />
            <span className={styles.starStatsStat}>{_stat}</span>
          </div>
        );
        _statType = labels.excursions;
        break;
      case "insurance":
        _statBooking = (
          <div className="star-stats__items-stat-info">
            <Icon
              className={styles.starStatsIcon}
              name="protect"
              aria-hidden="true"
            />
            <span className={styles.starStatsStat}>{_stat}</span>
          </div>
        );
        _statType = labels.insurancePlans;
        break;
      default:
        break;
    }

    // Generate Stat Item
    stats.push(
      <div key={"stat_item--" + stat} className="star-stats__items-stat">
        <p className="star-stats__items-stat-type">{_statType}</p>
        {_statBooking}
      </div>
    );
  }

  return stats;
};

const StarStats = ({ labels, filters }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [months, setMonths] = useState(-1);

  const getBookingDeatils = async (token, filter = "") => {
    setLoading(true);
    setMonths(filter);
    await fetch(`${CONFIG.resourceServer.agentApiUrl}/booking/stats${filter}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((res) => {
        setData(res);
        setLoading(false);
      });
  };

  useEffect(() => {
    setMonths(-1);
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(() => {
        getBookingDeatils(oktaAuth.getAccessToken());
      });
    }
  }, [authState, oktaAuth]);

  const handlePointsFilter = (event) => {
    setLoading(true);
    getBookingDeatils(oktaAuth.getAccessToken(), `/${event.target.value}`);
  };

  if (!data) {
    return (
      <div className={styles.starStats}>
        <div className={styles.starStatsContainer}>
          <div className="star-balance__loader">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  const bookingStats = calculateStats(data);
  const displayBookingStats = generateStatsDisplay(bookingStats, labels);

  return (
    <div className={styles.starStats}>
      <div className={styles.starStatsContainer}>
        <p className={styles.starStatsHeading}>
          {labels.bookingActivity}
          <select
            className="star-stats__header-right"
            onChange={handlePointsFilter}
          >
            {filters.map((filter, index) => {
              return (
                <option
                  key={index}
                  value={filter.value}
                  selected={months.replace("/", "") === filter.value}
                >
                  {filter.label}
                </option>
              );
            })}
          </select>
        </p>
        <Row cols={2}>
          {errorMessage?.length > 0 ? (
            <div className={"alert alert-danger"} role="alert">
              {errorMessage}
            </div>
          ) : (
            <>
              <Cell>
                <p>
                  <RCL searchKey="star_Totalbookings" />
                </p>
                <Icon
                  className={styles.starStatsIcon}
                  name="my-bookings"
                  aria-hidden="true"
                />
                <span className={styles.starStatsStat}>
                  {data.totalBookings}
                </span>
              </Cell>
              {displayBookingStats.map((stat) => (
                <Cell>{stat}</Cell>
              ))}
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default StarStats;
export { StarStats };
