const CARD_COLLECTION_FRAGMENT = `
  fragment CardCollectionFields on CardCollection {
    typename
    sys {
      id
    }
    variant
    theme
    link {
      ...LinkFields
    }
    cardsPerPage
    itemsPerRow
    navigationType
    paginationType
    cardsCollection(limit: 10) {
      items {
        ...CardFields
      }
    }
  }
`;

export { CARD_COLLECTION_FRAGMENT };
