import { GET_CONTENT_ENTRY } from "../../query/content.query";
import { Loader } from "../Loader";
import { FluidSection } from "../../FluidSection";
import { useState, useEffect } from "react";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";

const ContentfulContentEntry = ({
  entry,
  userInfo,
  locale,
  isNested = true,
}) => {
  const { oktaAuth } = useOktaAuth();
  const [data, setData] = useState(null);

  const getEntryData = async () => {

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: GET_CONTENT_ENTRY(entry.sys.id, entry.typename),
        variables: { contentfulId : entry.sys.id, locale: locale},
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
          setData(data);
        }
      });

  };

  useEffect(() => {
    if(entry && entry.sys && entry.sys.id && entry.typename)
    {
      getEntryData();
    }
   }, [entry]);

   let entryData = null;
   if(entry && data)
   {
    const entryKey =
      entry.typename.charAt(0).toLowerCase() + entry.typename.slice(1);
     entryData = data[entryKey];
   } 
 
  return (
    <>
      {entryData && (<FluidSection
        sections={[entryData]}
        userInfo={userInfo}
        locale={locale}
        isNested={isNested}
      />)
      }
    </>
  );
};

export default ContentfulContentEntry;
export { ContentfulContentEntry };
