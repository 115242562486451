// Get local / session storage by key name and value type
// @param string type = local , session [local]
// @param string item
// @param boolean boolean = true, false [true]
const getStorage = (type, item, boolean) => {
    let returnBoolean = boolean !== undefined ? boolean : true;

    // Wrapped in try for SSR (does not have localStorage / sessionStorage)
    try {
        // If sessionStorage
        if (type === "session") {
            // If sessionStorage item does exist
            if (sessionStorage.getItem(item) !== null) {
                if (returnBoolean) {
                    return sessionStorage.getItem(item) === "true";
                } else {
                    return sessionStorage.getItem(item);
                }
            } else {
                // Assume item is = false
                if (returnBoolean) {
                    sessionStorage.setItem(item, false);
                } else {
                    sessionStorage.setItem(item, "");
                }
                return false;
            }
        } else {
            // If localStorage item does exist
            if (localStorage.getItem(item) !== null) {
                if (returnBoolean) {
                    return localStorage.getItem(item) === "true";
                } else {
                    return localStorage.getItem(item);
                }
            } else {
                // Assume item is = false
                if (returnBoolean) {
                    localStorage.setItem(item, false);
                } else {
                    localStorage.setItem(item, "");
                }
                return false;
            }
        }
    } catch (e) {
        // By default SSR should return false / ""
        if (returnBoolean) {
            return false;
        } else {
            return "";
        }
    }
};

export default getStorage;
export { getStorage };
