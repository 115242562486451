const COMMON_FIELDS = `
...ParagraphFields
...CardFields
...VideoCardFields
...CardCollectionFields
...LinkFields`;

const SPLIT_CONTENT_WRAPPER_FRAGMENT = `
  fragment NestedSplitContentWrapperField on SplitContentWrapper {
    typename
    sys {
      id
    }
    leftSectionComponentsCollection(limit: 4){
      items{
        ${COMMON_FIELDS}

      }
    }
      rightSectionComponentsCollection(limit: 4){
      items{
         ${COMMON_FIELDS}
      }
    }
  }

  fragment SplitContentWrapperFields on SplitContentWrapper {
    typename
    sys {
      id
    }
    leftSectionComponentsCollection(limit: 4) {
      items {
        ${COMMON_FIELDS}
        ...NestedSplitContentWrapperField
      }
    }
    rightSectionComponentsCollection(limit: 4) {
      items {
        ${COMMON_FIELDS}
        ...NestedSplitContentWrapperField
      }
    }
  }
`;

export { SPLIT_CONTENT_WRAPPER_FRAGMENT };

