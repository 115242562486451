import { FluidSection } from "../../FluidSection";
import { Section } from "@sunwing/components";
import config from "../../app/config";
let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./SectionVwq.module.scss");
  } else {
    styles = await import("./Section.module.scss");
  }
})();

const SectionConnector = ({ data, userInfo, locale }) => {

  return (
  <>   
   {styles && (
    <Section
      title={data.displayTitle}
      titleAs={data.titleAs}
      titleSize={data.titleSize}
      titleAlignment={data.titleAlignment}
      titleId={data.titleId}
      theme={data.theme}
      padding={data?.padding ? data?.padding : "default"}
      className={styles.section}
    >
      <FluidSection
        sections={data.componentsCollection.items}
        userInfo={userInfo}
        locale={locale}
        isNested={true}
      />
    </Section>
    )}
  </>
);
}

export { SectionConnector };
