/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import moment from "moment";
import styles from "./InboxMessages.module.scss";
import { Icon } from "@sunwing/icons/dist/sunwing";
import { Image } from "antd";
import { RCL } from "../../components/RCL";

const MessageItem = ({ active, title, startedAt, endDate, image, redirectUrl, inboxText }) => {
  const [expanded, setExpanded] = useState(active);

  return (
    <div>
      <span className={styles.messageHeader}>
      {title}
        <a>
          <span className={styles.messageArrow} onClick={() => setExpanded(!expanded)}>
            {expanded ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />}
          </span>
        </a>
      </span>
      <br />
      <span className={styles.messageHeaderDate}>{moment(startedAt).format("DD MMMM, YYYY, hh:mm a")}</span>
      {expanded && (
        <div className={styles.messageContainer}>
          {image && (<Image
                    src={image}
                    preview={false}
                    alt=""
              />)}
          <div className={styles.messageContent}>
            {inboxText && inboxText !=='' && 
              (<span dangerouslySetInnerHTML={{__html: `${inboxText}`}}>
              </span> )}
            {redirectUrl && redirectUrl !== '' &&  (<div>
              <a href={redirectUrl} target="_blank" rel="noreferrer">
                {redirectUrl}
              </a>
            </div>)}
            <span className={styles.messageFootDate}><strong><RCL searchKey="messages" /> <RCL searchKey="expires-on" /></strong> {moment(endDate).format("DD MMMM, YYYY")}</span>
          </div>
        </div> 
      )}
      <br />
      <hr/>
      
    </div>
  )
};

export default MessageItem;