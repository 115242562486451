const VIDEOCARD_FRAGMENT = `
  fragment VideoCardFields on VideoCard {
    typename
    sys {
      id
    }
    displayTitle
    variant
    titleAs
    titleSize
    titleWeight
    titleIsUppercase
    colour
    backgroundColour
    image
    logo
    logoPosition
    content {
      json
    }
    videoUrl
  }
`;

export { VIDEOCARD_FRAGMENT };
