/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useOktaAuth, SecureRoute, LoginCallback } from "@okta/okta-react";
import { Container } from "semantic-ui-react";
import { RCLContext } from "./components/RCL/RCLProvider";
import { Page } from "./Page";
import CustomLoginComponent from "./pages/login/Login";
import GenericLoginComponent from "./pages/login/GenericLogin";
import AgentTools from "./pages/agentTools/AgentTools";
import Webinar from "./pages/webinar/Webinar";
import Flyers from "./pages/flyers/Flyers";
import MarketingToolkits from "./pages/marketingToolkits/MarketingToolkits";
import ContactUs from "./pages/contactus/ContactUs";
import Profile from "./Profile";
import MyLoyaltyProgramPage from "./pages/MyLoyaltyProgram";
import StarsTransaction from "./pages/Stars/StarsTransaction";
import MyAccount from "./pages/MyAccount/MyAccount";
import SignUp from "./pages/signup/signup";
import { Header } from "./components/Header";
import { Footer, LogoutFooter } from "./components/Footer";
import { Loader } from "./components/Loader";
import { useRouting } from "./hooks";
import { useCookies } from "react-cookie";
import CookieConsentWrap from "./components/CookieConsent/CookieConsentWrap";
import config from '../src/app/config';
import InboxMessagePage from "./pages/InboxMessages/InboxMessagePage";

const Routing = ({ setCorsErrorModalOpen }) => {
  const history = useHistory();
  const location = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const { lang, setLocale } = useContext(RCLContext);
  const { allRoutes, loading: isLoadingRoutes } = useRouting();
  const [cookies] = useCookies(["AgentPortalCookieConsent"]);

  React.useEffect(() => {
    // Landing page (/en or /fr)
    const rootPath = location.pathname.match(/^\/(en|fr)$/)?.[1];
    // Sub pages (/en/:slug or /fr/:slug)
    const subPathUrl = location.pathname.match(/^\/(en|fr)\//)?.[1];

    if (rootPath || subPathUrl) {
      setLocale(rootPath ?? subPathUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if(authState && authState?.isAuthenticated){
      (function(o,l,a,r,k,y){if(o.olark)return;
        r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];
        y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
        y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date())};
        y.extend=function(i,j){y("extend",i,j)};    
        y.identify=function(i){y("identify",k.i=i)};
        y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
        k=y._={s:[],t:[+new Date()],c:{},l:a};
        })(window,document,"static.olark.com/jsclient/loader.js");
        if (window.olark && typeof window.olark === "function") {
          window.olark.identify(process.env.REACT_APP_OLARK_SITE_ID);
            if (lang === 'fr') {
            window.olark.configure('system.group', process.env.REACT_APP_OLARK_OPERATOR_FR);
            window.olark("api.chat.setOperatorGroup", {
              group: process.env.REACT_APP_OLARK_OPERATOR_FR,
            });
            window.olark.configure("system.localization", "fr-FR");
          } else {
            window.olark.configure('system.group', process.env.REACT_APP_OLARK_OPERATOR_EN);
            window.olark("api.chat.setOperatorGroup", {
              group: process.env.REACT_APP_OLARK_OPERATOR_EN,
            });
            window.olark.configure("system.localization", "en-US");
          }

          //position the chat icon above the Cookie Consent button
          if(!cookies.AgentPortalCookieConsent){
            var sheet = document.createElement('style');
            sheet.innerHTML = `#olark-container 
                                { margin-bottom: 90px !important;  
                                  @media (max-width: 451px) {
                                  display:none;
                                }} 
                              .olark-button-focus-wrapper { margin-bottom: 1px !important}
                              .olark-launch-button-wrapper 
                                { margin-bottom: 90px !important;
                                  @media (max-width: 451px) {
                                  display:none;
                                }}`;
            document.body.appendChild(sheet);
          }

          window.olark("api.chat.onOperatorsAvailable", () => {
            window.olark("api.box.show");
          });
        }
    }
  }, [authState, lang]);

  const onAuthResume = async () => {
    history.push(`/${lang}`);
  };

  if (!authState && oktaAuth.isLoginRedirect()) {
    return (
      <Route
        path="/(en|fr)/login/callback"
        render={(props) => (
          <LoginCallback {...props} onAuthResume={onAuthResume} />
        )}
      />
    );
  }

  if (!authState || (authState?.isAuthenticated && isLoadingRoutes)) {
    return <Loader />;
  }

  function getPage(locale, pageName) {
    let result = Object.entries(allRoutes).find(([id, route]) => {
      return route.en.path === `/en/${pageName}`;   
    });

    return <SecureRoute path={`/${locale}/${pageName}`} 
      render={(props) => (
        <Page
          contentfulId={result[0].substring(1)}
          locale={locale==='en'?result[1].en.locale : result[1].fr.locale}
          route={result[1]}
          {...props}
        />
      )} />
  }

  return (
    <>
      <Header setCorsErrorModalOpen={setCorsErrorModalOpen} />
      <Container text style={{ marginTop: "1em" }} className="page__container">
        <Switch>
          <Route exact path="/">
            <Redirect
              to={
                authState && authState.isAuthenticated === false
                  ? `/${lang}/login`
                  : `/${lang}/home?brand=${config.isWestJetMode ? "vwq": "swg"}`
              }
            />
          </Route>
          <Route exact path="/(en|fr)">
            <Redirect
              to={
                authState && authState.isAuthenticated === false
                  ? `/${lang}/login`
                  : `/${lang}/home?brand=${config.isWestJetMode ? "vwq": "swg"}`
              }
            />
          </Route>
          <SecureRoute path="/(en|fr)/profile" component={Profile} />
          <SecureRoute
            path="/(en|fr)/transactions"
            component={StarsTransaction}
          />
          <SecureRoute
            path="/(en|fr)/my-loyalty-program"
            component={MyLoyaltyProgramPage}
          />
          <SecureRoute path="/(en|fr)/MyAccount" component={MyAccount} />
          <SecureRoute path="/(en|fr)/webinar" component={Webinar} />
          <SecureRoute path="/(en|fr)/flyers" component={Flyers} />
          <SecureRoute path="/(en|fr)/MarketingToolkits" component={MarketingToolkits} />
          
          <SecureRoute path="/(en|fr)/agentTools" component={AgentTools} />
          <SecureRoute path="/(en|fr)/contactUs" component={ContactUs} />
          <SecureRoute path="/(en|fr)/InboxMessage" component={InboxMessagePage} />
          {getPage('en', 'group-sales-resource-centre')}
          {getPage('fr', 'group-sales-resource-centre')}
          {getPage('en', 'vacations-for-every-group-detail')}
          {getPage('fr', 'vacations-for-every-group-detail')}
          {authState && authState.isAuthenticated === false ? (
            <Switch>
              <Route
                exact
                path={"/(en|fr)/login"}
                render={() => (
                  <CustomLoginComponent {...{ setCorsErrorModalOpen }} />
                )}
              />
              <Route
                exact
                path={"/(en|fr)/Genericlogin"}
                render={() => (
                  <GenericLoginComponent {...{ setCorsErrorModalOpen }} />
                )}
              />
               <Route
                path="/(en|fr)/login/callback"
                render={(props) => (
                  <LoginCallback {...props} onAuthResume={onAuthResume} />
                )}
              />
              <Route
                path="/(en|fr)/signup"
                render={() => <SignUp {...{ setCorsErrorModalOpen }} />}
              />
              <Route>
                <Redirect
                  to={`/${lang}/login`}
                />
              </Route>
              {Object.entries(allRoutes).map(([id, route]) =>
                  Object.entries(route).map(([, page]) => (
                    <SecureRoute
                      key={page.path}
                      path={page.path}
                      exact={page.exact}
                      render={(props) => (
                        <Page
                          contentfulId={id.substring(1)}
                          locale={page.locale}
                          route={route}
                          {...props}
                        />
                      )}
                    />
                  ))
                )}
            </Switch>
          ) : (
            !isLoadingRoutes &&
            allRoutes && (
              <Switch>
                {Object.entries(allRoutes).map(([id, route]) =>
                  Object.entries(route).map(([, page]) => (
                    <SecureRoute
                      key={page.path}
                      path={page.path}
                      exact={page.exact}
                      render={(props) => (
                        <Page
                          contentfulId={id.substring(1)}
                          locale={page.locale}
                          route={route}
                          {...props}
                        />
                      )}
                    />
                  ))
                )}
                <Route>
                  <div></div>
                </Route>
              </Switch>
            )
          )}
        </Switch>
      </Container>
      {authState && authState?.isAuthenticated && <Footer />}
      {(!authState || !authState?.isAuthenticated) && <LogoutFooter />}
      {authState && authState?.isAuthenticated && <CookieConsentWrap />}
    </>
  );
};

export default Routing;
export { Routing };
