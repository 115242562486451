import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "@sunwing/components/dist/index.css";
import "./polyfills";
import App from "./app/App";
import registerServiceWorker from "./registerServiceWorker";

import "./styles/index.scss";

ReactDOM.render(
  <CookiesProvider>
    <Router forceRefresh={true}>
      <App />
    </Router>
  </CookiesProvider>,
  document.getElementById("root")
);

registerServiceWorker();
