// Accepts _string + unlimited arguments in order for them to replace with
const replaceVariables = (string, ...args) => {
    let _replaced = string;

    //   Replace one variable at a time for all arguments
    for (let i = 0; i < args.length; i++) {
        _replaced = _replaced.replace(/{{(.*?)}}/, args[i]);
    }

    return _replaced;
};

export default replaceVariables;
