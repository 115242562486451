import React, { Component } from "react";
import { InputNumber,Modal,Image,Row,Col } from "antd";
import { convertCurrency, convertNumber, replaceVariables } from "./helpers";
import { Button } from "@sunwing/components/dist/Button";
import { Icon } from "@sunwing/components";
import { RCL } from "../../RCL";
import { Loader } from "../../Loader";

class StarBalanceLoadCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessMessage: false,
      submitting: false,
      ServerMessage: "",
      ServerMessageType: "error",
      pointsToRedeem: this.props.redemptionRules.MinPntsRedeem,
      isRefetchingData: false,
      isModalOpen:false,
    };
  }

  onChange = (value) => {
    let _value = value;

    if (isNaN(_value)) {
      return false;
    }

    if (_value > this.props.availableBalance) {
      _value = this.props.availableBalance;
    }

    this.setState({
      pointsToRedeem: _value,
    });
  };

  closeSuccessMessage = () => {
    // Add Full Screen Blocker (Loader)
    this.setState({
      isRefetchingData: true,
    });
    this.props.handleRefreshData(() => {
      this.setState({
        isRefetchingData: false,
      });
      this.props.handleShowLoadCard();
    });
  };

  toggleModal = () => {    
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };
 

  handleLoadSTARCard = () => {
    this.setState({
      submitting: true,
    });

    const RedeemInfo = {
      AgentGlobalId: this.props.redemptionRules.AgentGlobalId,
      Cashamount: Math.ceil(
        this.state.pointsToRedeem * this.props.redemptionRules.PointRate
      ),
      PointsRedeemed: this.state.pointsToRedeem,
    };

    fetch(this.props.urlPost, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(RedeemInfo),
    })
      .then((response) => {        
        if (!response.ok) {         
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {        
        if (res) {          
          this.setState({
            ServerMessage: "Successfully loaded.",
            ServerMessageType: "success",
            submitting: true,
          });
        } else {
          this.setState({
            ServerMessage: res.ResponseText,
            ServerMessageType: "error",
            submitting: false,
            isModalOpen:true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          ServerMessage: error.message,
          ServerMessageType: "error",
          submitting: false,
          isModalOpen:true,
        });
      });
  };

  render() {
    const { locale, labels, availableBalance, redemptionRules } = this.props;
    
    const maxRedemptionPoints =
      redemptionRules.MaxPntsRedeem > availableBalance
        ? availableBalance
        : redemptionRules.MaxPntsRedeem;

    return (
      <>
        {this.state.isRefetchingData && (
          <div className="star-balance__loader">
            <Loader />
          </div>
        )}
        <div className="star-balance__load">
          <p>
            {replaceVariables(
              labels.redemptionRules,
              redemptionRules.PointRate,
              convertNumber(redemptionRules.MinPntsRedeem, locale),
              convertCurrency(
                Math.ceil(
                  redemptionRules.MinPntsRedeem * redemptionRules.PointRate
                ),
                locale
              ),
              convertNumber(redemptionRules.MaxPntsRedeem, locale)
            )}
          </p>
          <p className="star-balance__points">
            <RCL searchKey="star_pointsbalance" /> {availableBalance}
          </p>
          {this.state.ServerMessage.length > 0 && this.state.ServerMessageType === "success" && (
            <div
              className={"alert alert-success"}
              role="alert"
            >
              {this.state.ServerMessage}
            </div>
          )}
          {this.state.ServerMessage.length > 0 && this.state.ServerMessageType === "error" && (             
              <Modal               
               visible={this.state.isModalOpen}              
               onCancel={this.toggleModal}
               footer={false} 
               bodyStyle={{color:"red"}}                                     
              >         
              <div
               className={"alert alert-danger"}
               role="alert"
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                   <RCL searchKey="ZoomPass_Timeout_Error" />
                  </Col>
                  <Col className="gutter-row" span={12}>
                  <Image
                    src="https://images.ctfassets.net/l4g28vcnjqdr/5cwvy5thUsIM0O2EosAMoo/7d1bd61c21bd4b4dff711e06a75d2c9a/StarCard.JPG"
                    width={200}
                    height={140}
                    preview={false}
                  />                   
                  </Col>
                </Row>                                   
              </div>                       
              </Modal>                       
          )}
          {!this.state.submitting && (
            <div className="star-balance__load-container">
              <div className="star-balance__load-amount">
                <p>
                  <RCL searchKey="star_Pointsredeem" />
                </p>
                <InputNumber
                  min={redemptionRules.MinPntsRedeem}
                  max={maxRedemptionPoints}
                  step={100}
                  value={this.state.pointsToRedeem}
                  onChange={this.onChange}
                />
              </div>
              <div className="star-balance__load-value">
                <p>{labels.cashValue}</p>
                <p className="star-balance__load-cash">
                  {convertCurrency(
                    Math.ceil(
                      this.state.pointsToRedeem * redemptionRules.PointRate
                    ),
                    locale
                  ).replace("$", "")}
                </p>
              </div>
              <div className="star-balance__load-btns">
                <Button
                  type="submit"
                  className="btn btn-primary"                 
                  disabled={
                    !(
                      this.state.pointsToRedeem <= redemptionRules.MaxPntsRedeem
                    ) ||
                    !(
                      this.state.pointsToRedeem >= redemptionRules.MinPntsRedeem
                    ) ||
                    !(this.state.pointsToRedeem <= availableBalance) ||
                    this.state.submitting
                  }
                  onClick={this.handleLoadSTARCard}
                >
                  {labels.loadYourSTARCard}
                </Button>
                <Button
                  type="submit"
                  outline={true}
                  className="btn btn-primary"
                  onClick={this.props.handleShowLoadCard}
                >
                  <RCL searchKey="star_cancel" />
                </Button>
              </div>
            </div>
          )}
          {this.state.submitting && (
            <div className="star-balance__success-container">
              <p>
                <Icon name="checked" />
                <RCL searchKey="OK_PointsAdded" />
              </p>
              <Button
                type="button"
                label="close"
                aria-label="close"
                onClick={this.closeSuccessMessage}
              >
                <Icon name="close--n" />
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default StarBalanceLoadCard;
export { StarBalanceLoadCard };
