import { useCookies } from "react-cookie"
import { RCL } from "../RCL";
import config from "../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./CookieConsentWrapVwq.module.scss");
  } else {
    styles = await import("./CookieConsentWrap.module.scss");
  }
})();


const CookieConsentWrap = () => {
    const [cookies, setCookie] = useCookies(["AgentPortalCookieConsent"]);

    const handleCookieConsentAccepted = () => {
        var sheet = document.createElement('style');
        sheet.innerHTML = `#olark-container 
                            { margin-bottom: 0px !important; 
                              @media (max-width: 451px) {
                                display:inline;
                            }} 
                            .olark-button-focus-wrapper { margin-bottom: 0px !important}
                            .olark-launch-button-wrapper 
                              { margin-bottom: 0px !important;
                                @media (max-width: 451px) {
                                display:inline;
                              }}`;
        document.body.appendChild(sheet);

        const expiration = new Date();
        expiration.setUTCMonth(expiration.getMonth() + 1);
        setCookie("AgentPortalCookieConsent", true, { path: "/", expires: expiration});
      }
    
    return (
      <>
        {styles && 
          (<>
            {(!cookies.AgentPortalCookieConsent)  && (
              <div className={styles.cookieContainer}>
                <div className={styles.cookieItemContainer}>
                  <div className={styles.cookieContent}>
                    {config.isWestJetMode ? <RCL searchKey="cookie-consent-vwq" /> : <RCL searchKey="cookie-consent" />}
                  </div>
                  <div className={styles.cookieBtnContainer}>
                    <button className={styles.cookieBtn} onClick={handleCookieConsentAccepted}>
                    <RCL searchKey="accept" /> 
                    </button>
                  </div> 
                </div>
              </div>
            )}
          </>)
        }
      </> 
    )
}

export default CookieConsentWrap;