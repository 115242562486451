import { useState } from "react";
import {
  cloudinaryImage,
  DEFAULT_WIDTH_TRANSFORMATION,
} from "../../utils/cloudinary";
import cx from "classnames";
import { getCardComponent } from "../../utils/ComponentUtil";
import { Modal, VideoPlayer, Icon } from "@sunwing/components";
import styles from "./VideoCard.module.scss";
import { RCL } from "../../components/RCL";
import { VWQIcon } from "@sunwing/icons";
import config from "../../app/config";


const VideoCardConnector = ({ data }) => {

  const [isOpen, setIsOpen] = useState(false);
  const CardComponent = getCardComponent(data.variant);
  const toggleVideoModal = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <div
        className={styles.parentDiv}
        onClick={toggleVideoModal}
      >
        {config.isWestJetMode ? <VWQIcon name="play-micro"
          className={cx([styles.iconclass, data.variant === "horizontal" &&
          styles.iconHorizontal, data.variant === "primary" &&
          styles.iconPrimary])}
        /> : <Icon name="play"
          className={cx([styles.iconclass, data.variant === "horizontal" &&
          styles.iconHorizontal, data.variant === "primary" &&
          styles.iconPrimary])}
        />}

        <CardComponent
          header={data.displayTitle}
          headingSize={data.titleSize}
          headingWeight={data.titleWeight}
          uppercase={data.titleIsUppercase}
          image={data.image && {
            src: cloudinaryImage(
              data.image?.[0]?.public_id,
              DEFAULT_WIDTH_TRANSFORMATION
            ),
            alt: "",
          }}
          logo={data.logo && {
            src: cloudinaryImage(
              data.logo?.[0]?.public_id,
              DEFAULT_WIDTH_TRANSFORMATION
            ),
            alt: "",
          }}
          logoPosition={data.logoPosition}
          content={data.content?.json?.content?.[0]?.content?.[0]?.value}
          color={data.colour}
          backgroundColor={data.backgroundColour} />
      </div>

      <Modal
        isOpen={isOpen}
        onClose={toggleVideoModal}
        labels={{ close: RCL({searchKey: "modal-close"}), content: "" }}
        container="xl"
      >
        <Modal.Content>
          <VideoPlayer
            autoPlay={false}
            muted={false}
            publicId={data.videoUrl}
            posterUrl={cloudinaryImage(
              data.image?.[0]?.public_id,
              DEFAULT_WIDTH_TRANSFORMATION
            )}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export { VideoCardConnector };
