const PARAGRAPH_FRAGMENT = `
  fragment ParagraphFields on Paragraph {
    typename
    sys {
      id
    }
    heading
    subHeading
    content {
      json
    }
    paragraphTextAlignment
    textColor
  }
`;

export { PARAGRAPH_FRAGMENT };
