import { Row, Cell } from "@sunwing/components";
import { FluidSection } from "../../FluidSection";

const SplitContentWrapper = ({ data, userInfo, locale }) => (
  <Row cols={2}>
    <Cell>
      <FluidSection
        sections={data.leftSectionComponentsCollection.items}
        userInfo={userInfo}
        locale={locale}
        isNested={true}
      />
    </Cell>
    <Cell>
      <FluidSection
        sections={data.rightSectionComponentsCollection.items}
        userInfo={userInfo}
        locale={locale}
        isNested={true}
      />
    </Cell>
  </Row>
);

export { SplitContentWrapper };
