import { RCL } from "../../components/RCL";
import { Modal } from "@sunwing/components";
import config from "../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./PlatinumClubVwq.module.scss");
  } else {
    styles = await import("./PlatinumClub.module.scss");
  }
})();


const VoucherDetails = ({ voucherInfo, closePopupHandler }) => {

    if(voucherInfo?.vouchers?.length > 0)
    {
        return (
             <Modal
                isOpen={true}
                onClose={closePopupHandler}
                labels={{ close: "Close", content: "" }}
                className="star-balance__modal"
                simple
                top={false}
                >
                <Modal.Content className="star-balance__modal-content">
                    <div >
                        <table className={styles.voucherDetailFirstTable}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{textAlign:"center"}}>{voucherInfo.vouchers[0].voucherCode}</td>
                                </tr>
                                <tr><td></td></tr>
                                <tr>
                                    <td style={{width:"50%"}}>{RCL({ searchKey: "voucher-amount" })}: ${voucherInfo.vouchers[0]?.amount}</td>
                                    <td>{RCL({ searchKey: "issued-on" })}: {voucherInfo.vouchers[0]?.created?.split('T')[0]}</td>
                                </tr>
                                <tr>
                                    <td>{RCL({ searchKey: "amount-redeemed" })}: ${voucherInfo?.vouchers[0]?.usageCounter ===0 ? '0' : voucherInfo.vouchers[0].closedCustomerSessions[0].attributes.AmountToRedeem }</td>
                                    <td>{RCL({ searchKey: "valid-from" })}: {voucherInfo.vouchers[0]?.created?.split('T')[0]}</td>
                                </tr>
                                <tr>
                                    <td>{RCL({ searchKey: "first-name-agent" })}: {voucherInfo.vouchers[0].firstName}</td>
                                    <td>{RCL({ searchKey: "expires-on" })}: {voucherInfo.vouchers[0].expiryDate?.split('T')[0]}</td>
                                </tr>
                                <tr>
                                    <td>{RCL({ searchKey: "last-name-agent" })}: {voucherInfo.vouchers[0].lastName}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <table className={styles.voucherDetailsecondTable}>
                            <thead>
                                <tr>
                                    <td>{RCL({ searchKey: "redeemed-against-booking"})}</td>
                                    <td>{RCL({ searchKey: "redeemed-date-and-time"})}</td>
                                    <td>{RCL({ searchKey: "amount-redeemed"})}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{voucherInfo?.vouchers[0]?.usageCounter ===0 ? 'N/A' : voucherInfo.vouchers[0].closedCustomerSessions[0].attributes.BookingID } </td>
                                    <td>{voucherInfo?.vouchers[0]?.usageCounter ===0 ? 'N/A' : voucherInfo.vouchers[0].closedCustomerSessions[0].created.split('.')[0] } </td>
                                    <td>${voucherInfo?.vouchers[0]?.usageCounter ===0 ? '0' : voucherInfo.vouchers[0].closedCustomerSessions[0].attributes.AmountToRedeem } </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                    </div>
                </Modal.Content>
            </Modal>  
        ) ;
    }
    else{
        return (
            <Modal
                isOpen={true}
                onClose={closePopupHandler}
                labels={{ close: "Close", content: "" }}
                className="star-balance__modal"
                simple
                top={false}
                >
                <Modal.Content className="star-balance__modal-content">
                    <div><h2><RCL searchKey="no-voucher" /></h2></div>
                    <div><RCL searchKey="no-voucher-to-show" /></div>
                </Modal.Content>
            </Modal>  
        )
    }
};

export default VoucherDetails;
export { VoucherDetails };