import { Section } from "@sunwing/components";
import { Link } from "react-router-dom";
import { RCL } from "../../components/RCL";
import styles from "./Breadcrumb.module.scss";

function getBreadcrumbs(parent) {
  if (parent) {
    return getBreadcrumbs(parent.parent).concat([
      { slug: parent.slug, title: parent.title },
    ]);
  } else {
    return [];
  }
}

const Breadcrumb = ({ pageSlug, locale, parent }) => {
  let breadcrumbs = getBreadcrumbs(parent);

  if (breadcrumbs.length === 0) {
    switch (pageSlug) {
      case "webinar":
        breadcrumbs = [
          { slug: "", title: RCL({ searchKey: "homepage" }) },
          {
            slug: "group-sales-resource-centre",
            title: RCL({ searchKey: "group-sales-resources-center" }),
          },
        ];
        break;
      case "flyers":
        breadcrumbs = [
          { slug: "", title: RCL({ searchKey: "homepage" }) },
          {
            slug: "all-agent-tools",
            title: RCL({ searchKey: "AgentTools_Header" }),
          },
        ];
        break;
      default:
        breadcrumbs = [];
    }
  }

  if (breadcrumbs.length > 0) {
    return (
      <Section padding="0" className={styles.backLink}>
        {breadcrumbs.map((breadcrumb) => (
          <Link to={`/${locale.slice(0, 2)}/${breadcrumb.slug}`}>
            {breadcrumb.title}
            {" > "}
          </Link>
        ))}
      </Section>
    );
  } else {
    return <></>;
  }
};

export { Breadcrumb };
