import {
  HeroCard,
  PrimaryCard,
  SecondaryCard,
  HorizontalCard,
} from "@sunwing/components";
import { HorizontalCard_VWQ } from '../components/HorizontalCard/HorizontalCard_vwq';
import config from '../app/config';

const getCardComponent = (variant) => {
  let CardComponent;

  switch (variant.toLowerCase()) {
    case "primary": {
      CardComponent = PrimaryCard;
      break;
    }
    case "hero": {
      CardComponent = HeroCard;
      break;
    }
    case "secondary": {
      CardComponent = SecondaryCard;
      break;
    }
    case "horizontal": {
      if(config.isWestJetMode){
        CardComponent = HorizontalCard_VWQ;
      }else {
        CardComponent = HorizontalCard;
      }
      break;
    }
    default:
      CardComponent = PrimaryCard;
      console.error(`Variant "${variant}" does't exist in CardComponent.`);
  }

  return CardComponent;
};

export { getCardComponent };
