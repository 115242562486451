const COMPONENT_BLOCK_FRAGMENT = `
  fragment ComponentBlockFields on ComponentBlock {
    typename
    sys {
      id
    }
    componentType
    configuration
    padding
  }
`;

export { COMPONENT_BLOCK_FRAGMENT };
