import { ImageWrapper } from "../ImageWrapper";
import cloudinaryImage from "../../utils/cloudinary";

const CloudinaryAssetConnector = ({ data }) => {
  const asset = data?.asset?.[0];

  if (asset.resource_type === "image") {
    return <ImageWrapper src={cloudinaryImage(asset.public_id)} />;
  }

  return null;
};

export default CloudinaryAssetConnector;
export { CloudinaryAssetConnector };
