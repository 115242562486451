const SOCIAL_MEDIA_CARD_FRAGMENT = `
  fragment SocialMediaCardFields on SocialMediaCard {
    typename
    sys {
      id
    }
    image
    imageAlt
    shareToEmail
    shareToFacebook
    shareToTwitter
    shareToPinterest
  }
`;

export { SOCIAL_MEDIA_CARD_FRAGMENT };
