//import { ContentfulContentEntry } from "../ContentfulContentEntry";
import React, { useEffect, useState } from "react";
import  InfoEntry  from "./InfoEntry";
import { VWQIcon } from "@sunwing/icons";
import styles from  "./HelpfulInfoGroupVwq.module.scss";
import { RCL } from "../RCL";

const HelpfulInfoGroupVwq = ({
  id,
  items,
  userInfo,
  locale,
}) => {

  const [expanded, setExpanded] = useState(false);
  const [hash, setHash] = useState('');

  const handleToggle =() => {
    setHash('');
    setExpanded(!expanded);
  }

  useEffect(() => {
      setHash(window.location.hash?.substring(1)); // Remove the '#' from the hash
  }, []);

  return (
    <>
      <div className={styles.expendAllContainer}>
        <button className={styles.expendAllBtn} onClick={() => handleToggle()}> 
        {expanded ? 
        (<><RCL searchKey="collapse-all" /> <VWQIcon name="doublechevron-collapse-micro" className={styles.expendAllIcon} /> </>) 
        : 
        (<><RCL searchKey="expand-all" /> <VWQIcon name="doublechevron-expand-micro" className={styles.expendAllIcon}/> </>) 
        }
        </button>
      </div>
      <br></br>
      
      {items.map((tab) => (
        <InfoEntry 
          active={(expanded || (tab.key === hash && hash))}
          tab={tab}  
          userInfo={userInfo}
          locale={locale} />
      ))}
    </>)
};

export default HelpfulInfoGroupVwq;
export { HelpfulInfoGroupVwq };
