import { TabsConnector } from "./Tabs";
import { AccordionConnector } from "./Accordion";

const TabsAndAccordionConnector = ({ data, userInfo, locale }) => {
  const {
    type,
    variantTabs,
    variantAccordion,
    activeKey,
    itemsCollection,
    sys: { id },
  } = data;

  switch (type.toLowerCase()) {
    case "tabs":
      return (
        <TabsConnector
          id={id}
          variant={variantTabs}
          activeKey={activeKey}
          items={itemsCollection.items}
          userInfo={userInfo}
          locale={locale}
        />
      );
    case "accordion":
    case "faq":
      return (
        <AccordionConnector
          id={id}
          variant={variantAccordion}
          activeKey={activeKey}
          items={itemsCollection.items}
          userInfo={userInfo}
          locale={locale}
        />
      );
    default:
      console.warn(`TabsAndAccordion - Type "${type}" not implemented.`);
  }

  return <div>Hello world</div>;
};

export { TabsAndAccordionConnector };
