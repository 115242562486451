import React, { useRef, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import * as OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { getURLQueries } from "../../helpers/helper";
import config from "../../app/config";
import "./OktaSignInWidget.scss";

const OktaSignInWidget = ({ setCorsErrorModalOpen }) => {
  const widgetRef = useRef(null);

  const { oktaAuth } = useOktaAuth();
  const { locale } = useContext(RCLContext);

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode } =
      config.oidc;
    const redirectUrl= `${redirectUri}/${locale}/login/callback`;  
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split("/oauth2")[0],
      language: locale,
      registration: {
        click: function () {
            window.location.href = window.location.origin + `/${locale}/signup`;
        }
      },
      features: {
          registration: true,
          showPasswordToggleOnSignInPage: true,
          selfServiceUnlock: true
      },
      clientId,
      redirectUri:redirectUrl,
      i18n: {
        en: {
          "primaryauth.title": "Sign In",
          "help":" ",
          "primaryauth.username.placeholder": "Email",
          "error.username.required": "Please enter email",
        },
        fr:{
          "help":" ",
          "primaryauth.username.placeholder": "Courriel",
          "error.username.required": "Please enter email (fr)",
        },
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
      recoveryToken: getURLQueries("token"),
      theme: {
        tokens: {
          PalettePrimaryMain: '#D11DCA',
          TypographyColorBody: '#00297A',
          TypographyColorHeading: '#00297A',
          TypographyFamilyHeading: 'Helvetica',
          TypographyFamilyBody: 'Helvetica',
          TypographyWeightHeading: 600,
          BorderRadiusMain: '24px',
          Spacing5: '2.85714286rem',

        }
      }
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      }
    );

    // Note: Can't distinguish CORS error from other network errors
    const isCorsError = (err) => err.name === "AuthApiError" && !err.statusCode;

    widget.on("afterError", (_context, error) => {
      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    return () => widget.remove();
  }, [oktaAuth, setCorsErrorModalOpen, locale]);

  return <div id="okta-sign-in-container" ref={widgetRef} />;
};

export default OktaSignInWidget;
export { OktaSignInWidget };
