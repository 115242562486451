import { Tabs } from "@sunwing/components";
import { ContentfulContentEntry } from "../ContentfulContentEntry";
import config from '../../app/config';

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("../Tabs/TabsVwq.module.scss");
  } else {
    styles = await import("../Tabs/Tabs.modules.scss");
  }
})();

const onHandleCustomClick = (key) => {
  window.location.hash = key;
}

const TabsConnector = ({ id, variant, activeKey, items, userInfo, locale }) => (
  <>
    {styles && 
      (<>
        <Tabs
          theme={variant}
          activeKey={activeKey}
          customTabWrapperClass={config.isWestJetMode ? styles.tabsWrapper : "tabsWrapper"}
          customPanelClass={ config.isWestJetMode ? styles.tabsPanel : "tabsPanel"}
          customActiveClass={config.isWestJetMode ? styles.tabsActive : "tabsActive"}
          data={items.map((tab) => ({
            key: tab.key,
            toggle: {
              label: {
                heading: tab.heading,
                subHeading: tab.subheading,
                tag: tab.tag,
              },
              icon: tab.icon,
            },
            onHandleCustomClick: () => onHandleCustomClick(tab.key),
            panel: tab.contentCollection.items.map((item) => (
              <ContentfulContentEntry
                key={item.sys.id}
                entry={item}
                userInfo={userInfo}
                locale={locale}
              />
            )),
          }))}
        />
      </>
      )}
  </>
);

export default TabsConnector;
export { TabsConnector };
