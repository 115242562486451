import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import parse from "html-react-parser";
import marked from "marked";
import { Row, Cell, Section } from "@sunwing/components";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import { Loader } from "../../components/Loader";
import styles from "./ContactUs.module.scss";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";

const ContactUs = () => {
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const { oktaAuth } = useOktaAuth();
  const [contactUsInfo, setContactUsInfo] = useState(null);

  const getContactUsInfo = async () => {
    const query = `
    query contactUsData($locale: String) {
      contactUsCollection(locale: $locale) {
        items {
          title
          content
          priorityOrder
        }
      }
    }`;

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: query,
        variables: { 
          locale: locale === "fr" ? locale : "en-CA", 
        },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setContactUsInfo(data?.contactUsCollection?.items);
      });
  };

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/contactUs" },
        fr: { path: "/fr/contactUs" },
      });
    }
    getContactUsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {`Agent Portal - ${RCL({searchKey: "contactUs"})}`}         
        </title>
      </Helmet>
      <Section title={RCL({ searchKey: "contactUs" })} className={`${config.isWestJetMode ? styles.contactUsVwq:''}`} >
        {!contactUsInfo && <Loader />}
        {contactUsInfo && !!contactUsInfo.length && (
          <div className={styles.contactUs}>
            <Row cols={2}>
              {contactUsInfo
                .slice()
                .sort(function (a, b) {
                  return a.priorityOrder - b.priorityOrder;
                })
                .map((info) => (
                  <Cell key={info.title}>{parse(marked(info.content))}</Cell>
                ))}
            </Row>
          </div>
        )}
      </Section>
    </div>
  );
};

export default ContactUs;
