import config from '../app/config';

const replaceRedirectUrl = (url, MBPRedirectUrl, IGQRedirectUrl) => {
  switch (url.toLowerCase()) {
    case "/agents/redirecttombp":
      return MBPRedirectUrl;
    case "/agents/redirecttoigq":
      return IGQRedirectUrl;
    default:
      if(!url.includes("https://") && !url.includes("?brand=") && !url.startsWith('#')){
        if(url.includes("#")){
          var indexPos=url.lastIndexOf("#");
          if(url.includes("?")){
            return url.substring(0,indexPos) + `&brand=${config.isWestJetMode ? "vwq" : "swg"}` + url.substring(indexPos);
          }else {
            return url.substring(0,indexPos) + `?brand=${config.isWestJetMode ? "vwq" : "swg"}` + url.substring(indexPos);
          }
        }else{
          if(url.includes("?")){
            return url + `&brand=${config.isWestJetMode ? "vwq" : "swg"}`
          }
          else {
            return url + `?brand=${config.isWestJetMode ? "vwq" : "swg"}`
          }
        }
      }
      return url;
  }
};

export default replaceRedirectUrl;
export { replaceRedirectUrl };
