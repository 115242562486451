import React from "react";
import classNames from "classnames/bind";
import { Button } from "@sunwing/components/dist/Button";
import { RCL } from "../../components/RCL";
import { Img } from "@sunwing/shared-components";
import { Row, Cell, Heading, Icon } from "@sunwing/components";
import { SocialData } from "./SocialData";
import styles from "./SocialAndAppsSection.module.scss";

const cx = classNames.bind(styles);

const AppsLink = ({ href, target, title, img, className, locale }) => (
  <a href={href} target={target} title={title} className={cx("link")}>
    <Img
      src={locale === "fr" ? img.url.fr : img.url.en}
      fixed={img.fixed}
      svg={img.svg}
      wrapperClassName={className}
      alt={img.title ? img.title : title}
    />
  </a>
);
const SocialLink = ({ href, target, title, icon }) => (
  <a
    href={href}
    target={target}
    title={title}
    className={cx("link", "socialLink", icon)}
  >
    <Icon name={icon} />
  </a>
);
const SocialAndAppsSection = ({ locale }) => {
  const {
    appIcon,
    appsTitle,
    appsSubTitle,
    appsDescription,
    appsLinks,
    appsButtons,
    socialTitle,
    socialSubTitle,
    socialDescription,
    socialLinks,
    socialAppsLinks,
    socialButtons,
    socialIcon,
    variant,
  } = SocialData;
  return (
    <Row cols={2} className={cx("outer")}>
      <Cell>
        <div className={cx("section", variant)}>
          <div
            className={cx("socialHead", !appIcon ? "socialHeadWithIcon" : "")}
          >
            {appIcon && (
              <Icon
                name={appIcon}
                className={`swg-text-6xl ${cx("socialHeadIcon")}`}
              />
            )}
            <div className={cx("socialHeadContent")}>
              <Heading as="h3" size="h4" className={cx("socialHeadTitle")}>
                {RCL({ searchKey: appsTitle })}
              </Heading>
              <div>{RCL({ searchKey: appsSubTitle })}</div>
            </div>
          </div>
          <div className={cx("socialContent")}>
            <div className={cx("socialContentDesc")}>
              {RCL({ searchKey: appsDescription })}
            </div>
            <div className={cx("linksContainer")}>
              {appsLinks?.map(
                (link) =>
                  link.id && (
                    <AppsLink
                      locale={locale}
                      key={link.id}
                      href={link.link.url}
                      target={link.link.target}
                      title={link.title}
                      img={link.icon.img}
                      className={cx("appsImages")}
                    />
                  )
              )}
              {appsButtons?.map(
                (button) =>
                  button.id && (
                    <div>
                      <Button
                        key={button.id}
                        outline={button.invertButtonStyle}
                        to={button.link && button.link}
                        className={cx("button")}
                        icon={button.iconName}
                      >
                        {button.text && button.text}
                      </Button>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </Cell>
      <Cell>
        <div className={cx("section", "social", variant)}>
          <div
            className={cx("socialHead", !appIcon ? "socialHeadWithIcon" : "")}
          >
            {socialIcon && (
              <Icon
                name={socialIcon}
                className={`swg-text-6xl ${cx("socialHeadIcon")}`}
              />
            )}
            <div className={cx("socialHeadContent")}>
              <Heading as="h3" size="h4" className={cx("socialHeadTitle")}>
                {RCL({ searchKey: socialTitle })}
              </Heading>
              <div>{socialSubTitle}</div>
            </div>
          </div>
          <div className={cx("socialContent")}>
            <div className={cx("description", "socialContentDesc")}>
              {RCL({ searchKey: socialDescription })}
            </div>
            <div className={cx("linksContainer")}>
              {socialLinks?.map(
                (link) =>
                  link.id && (
                    <SocialLink
                      key={link.id}
                      href={link.link.url}
                      target={link.link.target}
                      title={link.title}
                      icon={link.iconName}
                      className={cx("appsImages")}
                    />
                  )
              )}
              {socialButtons?.map(
                (button) =>
                  button.id && (
                    <div>
                      <Button
                        key={button.id}
                        className={cx("button")}
                        outline={button.invertButtonStyle}
                        to={button.link && button.link}
                        icon={button.iconName}
                      >
                        {button.text && button.text}
                      </Button>
                    </div>
                  )
              )}
              {socialAppsLinks?.map(
                (link) =>
                  link.id && (
                    <AppsLink
                      key={link.id}
                      locale={locale}
                      href={link.link.url}
                      target={link.link.target}
                      title={link.title}
                      img={link.icon.img}
                      className={cx("socialAppsImages")}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default SocialAndAppsSection;
export { SocialAndAppsSection };
