/* eslint-disable react/no-danger */
import React from "react";
import cx from "classnames";
import { Card } from "@sunwing/components";
import styles from "./HorizontalCard.module.scss";
import { Link } from "react-router-dom";
import replaceRedirectUrl from "../../utils/replaceRedirectUrl";
import { useApp } from "../../hooks";

const HorizontalCard = ({
  image,
  header,
  content,
  link,
  button,
  logo,
  logoPosition,
  className,
  style,
}) => {
  const { MBPRedirectUrl, IGQRedirectUrl } = useApp();

  return (
    <div className={styles.horizontalCard}>
      <div className={styles.horizontalCardContainer} style={style}>
        <div className={cx(styles.contentContainer, className)}>
          <div className={styles.contentTextContainer}>
            {header && (
              <Card.Header className={styles.heading}>{header}</Card.Header>
            )}
            {content && (
              <div
                className={styles.contentContainerText}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
          <div className={styles.horizontalCardContentLinks}>
            {link && (
              <Link
                to={replaceRedirectUrl(link.to, MBPRedirectUrl, IGQRedirectUrl)}
                className={styles.link}
              >
                {link.text}
              </Link>
            )}
            {button && (
              <Card.Button
                className={styles.buttonLink}
                to={button.to}
                label={button.label}
                size={button.size}
                target={button.target}
                icon={button.icon}
                iconPlacement={button.iconPlacement}
                onClick={button.onClick}
              >
                {button.text}
              </Card.Button>
            )}
          </div>
        </div>
        <div className={styles.imageContainer}>
          {image && (
            <Card.Image
              className={styles.imageCover}
              src={!image.srcSet && image.src}
              source={image.srcSet && image}
              alt={image.alt}
              fluid={image.fluid}
              clickable={link}
            />
          )}
          {logo && <Card.Logo logo={logo} logoPosition={logoPosition} />}
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;
export { HorizontalCard };
