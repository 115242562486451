import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { RCL, RCL as useTranslation } from "../../components/RCL";
import { Row, Cell, Modal } from "@sunwing/components";
import { Button as ButtonComponent } from "@sunwing/components/dist/Button";
import { Img } from "@sunwing/shared-components";
import { Loader } from "../../components/Loader";
import config from "../../app/config";
import styles from "./StarCardModal.module.scss";
import marked from "marked";
import parse from "html-react-parser";

const StarCardModal = ({
  isOpen,
  setIsOpen,
  provinceList,
  starCardData,
  setStarCardData,
  setStarCardInfo,
  setLoyaltyCardRegistered,
  locale,
}) => {
  const { oktaAuth } = useOktaAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fax, setFax] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [validation, setValidation] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(null);
  const [isValidCity, setIsValidCity] = useState(null);
  const [isValidProvince, setIsValidProvince] = useState(null);
  const [isValidPostal, setIsValidPostal] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [starContentData, setStarContentData] = useState(null);

  const getStarContentData = async () => {
    const starRegistrationQuery = `
      query starRegistration($contentfulId: String!, $locale: String!) {
        starContent(id: $contentfulId, locale: $locale) {
          sys {
            id
          }
          title
          content
          imageIfApplicable {
            url
            size
          }
        }
      }
      `;

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: starRegistrationQuery,
        variables: { 
          contentfulId: "30V3aw57sksO2yCyaaeG0W", 
          locale: locale === "fr" ? locale : "en-CA", 
        },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
        setStarContentData(data?.starContent);
        }
      });
  };

    useEffect(() => {
      getStarContentData();
    }, [locale]);

  const dictionary = {
    OK_StarRegistrationSuccessful: useTranslation({
      searchKey: "OK_StarRegistrationSuccessful",
    }),
    ERR_InvalidFirstName: useTranslation({
      searchKey: "ERR_InvalidFirstName",
    }),
    ERR_InvalidLastName: useTranslation({
      searchKey: "ERR_InvalidLastName",
    }),
    ERR_InvalidPhone: useTranslation({ searchKey: "ERR_InvalidPhone" }),
    ERR_InvalidAddress: useTranslation({
      searchKey: "ERR_InvalidAddress",
    }),
    ERR_InvalidCity: useTranslation({
      searchKey: "ERR_InvalidCity",
    }),
    ERR_InvalidEmail: useTranslation({ searchKey: "ERR_InvalidEmail" }),
    ERR_InvalidProvince: useTranslation({
      searchKey: "ERR_InvalidProvince",
    }),
    ERR_InvalidPostalCode: useTranslation({
      searchKey: "ERR_InvalidPostalCode",
    }),
  };

  // Validation for Field function
  const validateField = (input, validation) => {
    let isValid = true;
    let isRequired = true;

    if (input.required === undefined) {
      if (input.value.toString().length === 0) {
        isRequired = false;
      }
    }

    if (isRequired) {
      switch (validation) {
        case "name":
          if (input.value.replace(/\s+/g, "").length < 1) {
            isValid = false;
          } else {
            isValid = /^[A-Za-zÀ-ú\s\-'’ ]*$/.test(input.value);
          }
          break;
        case "length":
          if (input.value.length < 1) {
            isValid = false;
          } else {
            isValid = true;
          }
          break;
        case "dropdown":
          if (input.value.length < 1) {
            isValid = false;
          } else {
            isValid = true;
          }
          break;
        case "email":
          // eslint-disable-next-line no-useless-escape
          var emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (input.value.length < 1) {
            isValid = false;
          } else {
            isValid = emailRegex.test(input.value);
          }
          break;
        case "postal":
          // Canadian Postal Code "Rules" - https://stackoverflow.com/questions/1146202/canadian-postal-code-validation
          // https://en.wikipedia.org/wiki/Postal_code#Reserved_characters
          var postalRegex =
            /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ].?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;
          if (input.value.replace(/\s/g, "").length !== 6) {
            isValid = false;
          } else {
            isValid = postalRegex.test(input.value);
          }
          break;
        default:
          isValid = true;
      }
    }
    return isValid;
  };

  const onChangeInput = (event) => {
    let input = event.target;
    if (input.id === "phoneNumber") setPhoneNumber(input.value);
    else if (input.id === "address") setAddress(input.value);
    else if (input.id === "city") setCity(input.value);
    else if (input.id === "fax") setFax(input.value);
    else if (input.id === "province") {
      setProvince(input.value);
      if (input.value !== "") setIsValidProvince(true);
      else setIsValidProvince(false);
    } else if (input.id === "postalCode") setPostalcode(input.value);
  };

  const handleValidation = (event) => {
    let input = event.target;
    let isValid = true;
    let validationString = "";

    if (
      input.id === "phoneNumber" ||
      input.id === "address" ||
      input.id === "city"
    ) {
      setValidation("length");
      validationString = "length";
    }

    if (input.id === "province") {
      setValidation("dropdown");
      validationString = "dropdown";
    }

    if (input.id === "postalCode") {
      setValidation("postal");
      validationString = "postal";
    }

    // Validate field
    if (input.getAttribute("required") !== null && input.value !== "") {
      if (
        input.id === "phoneNumber" ||
        input.id === "address" ||
        input.id === "city"
      ) {
        if (input.id === "phoneNumber") {
          setIsValidPhone(validateField(input, validationString));
          input.isValid = isValidPhone;
        }
        if (input.id === "address") {
          setIsValidAddress(validateField(input, validationString));
          input.isValid = isValidAddress;
        }
        if (input.id === "city") {
          setIsValidCity(validateField(input, validationString));
          input.isValid = isValidCity;
        }
      }
      if (input.id === "province") {
        setIsValidProvince(validateField(input, validationString));
        input.isValid = isValidProvince;
      }
      if (input.id === "postalCode") {
        setIsValidPostal(validateField(input, validationString));
        input.isValid = isValidPostal;
      }
    }

    onChangeInput(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setServerMessage("");

    let validForm =
      isValidPhone &&
      isValidAddress &&
      isValidCity &&
      isValidProvince &&
      isValidPostal;

    if (validForm) {
      var AgentInfo = {
        Agent: {
          cLoginEmail: starCardData?.cLoginEmail,
          cContactPhone: phoneNumber,
          cFax: fax,
          cAddress1: address,
          cCity: city,
          cProvince: province,
          cPostalCode: postalcode.trim(),
        },
      };

      fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify(AgentInfo),
      })
        // .then((response) => response.json())
        .then((response) => {
          if (response.ok) {            
            setServerMessage(dictionary["OK_StarRegistrationSuccessful"]); 
            setIsSuccess(true);
            setSubmitting(false);                                  
          } else {
            setServerMessage(response.ResponseText);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          setServerMessage("error");
          setSubmitting(false);
        });
    }
  };

  const handleClose = () => {
    getStarCardInfo(oktaAuth.getAccessToken());    
    setIsSuccess(false);
    toggleModal(false);
  };

  const getStarCardInfo = (token) => {
    fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((res) => {
        const data = res.starAgentInfo;
        const starCard = res.starCard;
        setStarCardData(data);
        setStarCardInfo(starCard);
        setLoyaltyCardRegistered(res.isStarRegistered);
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsOpen(!isOpen);
    if(isSuccess){
      getStarCardInfo(oktaAuth.getAccessToken());
    }
    
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={toggleModal}
      labels={{ close: "Close" }}
      simple={true}
      top={false}
    >
      {starCardData && <Loader />}
      {starContentData && (
        <Modal.Content className={styles.modal}>
          <p className={styles.modalTitle}>
            <RCL searchKey="becomeMember" />
          </p>
          <Row cols={2} className={styles.modalGrid}>
            <Cell>
              <div>{parse(marked(starContentData.content))}</div>
            </Cell>
            <Cell>
              <Img
                src={starContentData.imageIfApplicable.url}
                alt="Star Card"
              />
            </Cell>
          </Row>         
          <form action="">           
            {serverMessage !== null && serverMessage.length > 0 && (
              <div className={cx(isSuccess?styles.successful:styles.error)}>{serverMessage}</div>
            )}
            {submitting && <Loader/>}
            {!isSuccess && (
            <div>
            <Row
              cols={2}
              className={cx(styles.modalGrid, styles.modalGridNames)}
            >
              <Cell>
                <label name="firstName">                
                  <RCL searchKey="firstName" />                                                 
                </label>               
                <input
                  type="text"
                  id="FirstName"
                  required={true}
                  readOnly={true}
                  value={starCardData?.cFirstName}
                  onBlur={(event) => handleValidation(event)}
                />
              </Cell>
              <Cell>
                <label name="lastName">                
                  <RCL searchKey="lastName" />                
                </label>
                <input
                  type="text"
                  id="lastName"
                  readOnly={true}
                  required={true}
                  value={starCardData?.cLastName}
                  onBlur={(event) => handleValidation(event)}
                />
              </Cell>
            </Row>
            <Row
              cols={2}
              className={cx(styles.modalGrid, styles.modalGridContact)}
            >
              <Cell>
                <label name="phoneNumber">
                <span 
                  className={cx(styles.required)}
                > 
                  <RCL searchKey="phone-number" />
                </span>  
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={phoneNumber}
                  required={true}
                  onChange={(event) => onChangeInput(event)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={(event) => handleValidation(event)}
                />
                {(isValidPhone !== null || submitting) && !isValidPhone ? (
                  <span className={cx(styles.error)}>
                    {dictionary["ERR_InvalidPhone"]}
                  </span>
                ) : null}
              </Cell>
              <Cell>
                <label name="fax">
                  <RCL searchKey="fax" />
                  <span>(optional)</span>
                </label>
                <input
                  type="text"
                  id="fax"
                  maxLength="10"
                  onChange={(event) => onChangeInput(event)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={(event) => handleValidation(event)}
                />
                {(isValidPhone !== null || submitting) && !isValidPhone ? (
                  <span className={cx(styles.errorEmpty)}></span>
                ) : null}
              </Cell>
            </Row>
            <Row className={styles.modalGrid}>
              <Cell>
                <label name="email">
                  <p>
                    <RCL searchKey="email" />
                    <span>
                      <RCL searchKey="emailToSignIn" />
                    </span>
                  </p>
                </label>
                <input
                  type="text"
                  id="email"
                  value={starCardData?.cLoginEmail}
                  readOnly={true}
                  required={true}
                  size="100"
                  onChange={(event) => onChangeInput(event)}
                  onBlur={(event) => handleValidation(event)}
                />
              </Cell>
            </Row>
            <Row className={styles.modalGrid}>
              <Cell>
                <label name="address">
                <span 
                  className={cx(styles.required)}
                > 
                  <RCL searchKey="Address_STAR" />
                </span>  
                </label>
                <input
                  type="text"
                  id="address"
                  required={true}
                  size="100"
                  value={address}
                  onChange={(event) => onChangeInput(event)}
                  onBlur={(event) => handleValidation(event)}
                />
                {(isValidAddress !== null || submitting) && !isValidAddress ? (
                  <span className={cx(styles.error)}>
                    {dictionary["ERR_InvalidAddress"]}
                  </span>
                ) : null}
              </Cell>
            </Row>
            <Row cols={3} className={styles.modalGrid}>
              <Cell>
                <label name="city">
                <span 
                  className={cx(styles.required)}
                > 
                  <RCL searchKey="city" />
                </span>  
                </label>
                <input
                  type="text"
                  id="city"
                  required={true}
                  value={city}
                  onChange={(event) => onChangeInput(event)}
                  onBlur={(event) => handleValidation(event)}
                />
                {(isValidCity !== null || submitting) && !isValidCity ? (
                  <span className={cx(styles.error)}>
                    {dictionary["ERR_InvalidCity"]}
                  </span>
                ) : null}
              </Cell>
              <Cell>
                <label name="province">
                <span 
                  className={cx(styles.required)}
                > 
                  <RCL searchKey="province" />
                </span>  
                </label>
                <select
                  id="province"
                  required={true}
                  onChange={(event) => onChangeInput(event)}
                >
                  <option value="">
                    {RCL({
                      searchKey: "select",
                    })}
                  </option>
                  {provinceList &&
                    provinceList.map((prv) => (
                      <option key={prv.cProvince} value={prv.cProvince}>
                        {prv.cProvinceName}
                      </option>
                    ))}
                </select>
                {(isValidProvince !== null || submitting) &&
                !isValidProvince ? (
                  <span className={cx(styles.error)}>
                    {dictionary["ERR_InvalidProvince"]}
                  </span>
                ) : null}
              </Cell>
              <Cell>
                <label name="postalCode">
                <span 
                  className={cx(styles.required)}
                > 
                  <RCL searchKey="postalCode" />
                </span>  
                </label>
                <input
                  type="text"
                  id="postalCode"
                  required={true}
                  value={postalcode}
                  onChange={(event) => onChangeInput(event)}
                  onBlur={(event) => handleValidation(event)}
                />
                {(isValidPostal !== null || submitting) && !isValidPostal ? (
                  <span className={cx(styles.error)}>
                    {dictionary["ERR_InvalidPostalCode"]}
                  </span>
                ) : null}
              </Cell>
            </Row>
            <div className={styles.modalConfirm}>             
              <ButtonComponent size="md" onClick={handleSubmit}>
                <RCL searchKey="register" />
              </ButtonComponent>              
              <p>
                <RCL searchKey="terms" />
              </p>             
            </div>
            </div>)}
          </form>
          {isSuccess && (
            <div>
             <ButtonComponent size="md" onClick={handleClose}>
                <RCL searchKey="modal-close" />
             </ButtonComponent>
            </div>)}          
        </Modal.Content>
      )}
    </Modal>
  );
};

export default StarCardModal;
export { StarCardModal };
