import { Link } from "react-router-dom";
import {
  cloudinaryImage,
  DEFAULT_WIDTH_TRANSFORMATION,
} from "../../utils/cloudinary";
import replaceRedirectUrl from "../../utils/replaceRedirectUrl";
import { useApp } from "../../hooks";
import { getCardComponent } from "../../utils/ComponentUtil";
import moment from "moment";
import styles from "./Card.module.scss";
import config from '../../app/config';

const CardConnector = ({ data }) => {
  const { MBPRedirectUrl, IGQRedirectUrl } = useApp();

  const CardComponent = getCardComponent(data.variant);

  const link = {
    size: data.link?.size,
    target: data.link?.target,
    text: data.link?.linkText,
    to: replaceRedirectUrl(data.link?.url, MBPRedirectUrl, IGQRedirectUrl),
    label: data.link?.label,
  };

  let showCard = (data?.effectiveDate == null &&  data?.expiryDate == null)
    || (data?.effectiveDate == null && data?.expiryDate!==null && moment().isBefore(data?.expiryDate))
    || (data?.effectiveDate !== null && data?.expiryDate!==null && moment().isAfter(data?.effectiveDate) && moment().isBefore(data?.expiryDate));
  
  let strEffectiveDate = "";
  if(showCard && data?.effectiveDate !== null)
    strEffectiveDate = moment(data?.effectiveDate).format("MMM DD, YYYY")

  return (showCard && 
    <CardComponent
    className={config.isWestJetMode ? styles.card_vwq : ""}
      header={data.displayTitle?.length > 80 ? 
        data.displayTitle?.substring(0, 80) + " ..." :
        data.displayTitle}
      headingSize={data.titleSize}
      headingWeight={data.titleWeight}
      uppercase={data.titleIsUppercase}
      link={link}
      button={link.label !== undefined ? link : null}
      image={
        data.image && {
          src: cloudinaryImage(
            data.image?.[0]?.public_id,
            DEFAULT_WIDTH_TRANSFORMATION
          ),
          alt: "",
        }
      }
      logo={
        data.logo && {
          src: cloudinaryImage(
            data.logo?.[0]?.public_id,
            DEFAULT_WIDTH_TRANSFORMATION
          ),
          alt: "",
        }
      }
      subheader={ strEffectiveDate }
      logoPosition={data.logoPosition}
      content={data.content?.json?.content?.[0]?.content?.[0]?.value}
      color={data.colour}
      backgroundColor={data.backgroundColour}
      renderLink={(link) => {
        if (link.isInternal && link.to.indexOf("#") !== 0) {
          return (
            <Link to={link.to} target={link.target} className={link.className}>
              {link.children}
            </Link>
          );
        }
        return (
          <a href={link.to} target={link.target} className={link.className}>
            {link.children}
          </a>
        );
      }}
    />
  );
};

export { CardConnector };
