import React from "react";
import {InfoPanel} from "@sunwing/components";
import { TeamMemberDetailConnector } from "../TeamMemberDetail";
import { cloudinaryImage } from "../../utils/cloudinary";
import styles from "./TeamMembers.module.scss";
import config from "../../app/config";

const TeamMembersConnector = ({ data, locale }) => {

	return (
		<div className={config.isWestJetMode ? styles.teamMemberVwq:""}>   
			<InfoPanel
				members={data.membersCollection.items.map(m => ({
					id: m.sys.id,
					profile: {
						name: m.name,
						role: m.role,
						image: {
							src: cloudinaryImage(
							m.cloudinaryImage?.[0]?.public_id,
							{width:360}
							),
							alt: "",
						}, 
						profileBackgroundColor:m.backgroundColor,				               			
					},
					details: m.details && (<TeamMemberDetailConnector
						data={m}
						locale={locale === "fr" ? locale : "en-CA"}
					/>),
											
				}))}
			/>
		</div>
	)
}

export default TeamMembersConnector;
export { TeamMembersConnector }