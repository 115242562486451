
import React, { useState } from "react";

import { Select } from "antd";
import "antd/dist/antd.css";
import styles from "./PreferredRegions.module.scss";
import classNames from "classnames/bind";

const SelectOption = Select.Option;
const cx = classNames.bind(styles);


const PreferredRegions = ({regions,label}) => {
    	
	const [selectedRegions,setSelectedRegions]=useState( [{ key: "CA", label: "Canada" }]);



      
      const handleMultipleSelect =(value) => {	

       let updatedRegion =value;
       if (value.length > 1 && value[value.length - 1].key === "CA")
        {
            updatedRegion = value.filter(x => x.key === "CA");
        }

        if (value.length > 1 && value[value.length - 1].key !== "CA") {
            updatedRegion = value.filter(x => x.key !== "CA");
        }
        
        setSelectedRegions(updatedRegion);        
    }; 

 

  if ( regions !== null) {   
    return (
       <div>
        <label htmlFor="ap-preferredRegion">
            <span className={cx("inputContainer__label", "required")}>           
                {label}
            </span>
            </label>	  

            <Select style={{minWidth:400,minHeight:40,border:1}}
                id="ap-preferredRegion"
                name="preferredRegion"
                mode={"multiple"}
                filterOption={(inputValue, option) =>
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                labelInValue={true}
                onChange={handleMultipleSelect}	
                value={selectedRegions}																							
            >
                {regions && regions.map(item => {
                return (
                    <SelectOption key={item["cProvinceCode"]} value={item["cProvinceCode"]}>
                    {item["cProvinceName"]}
                    </SelectOption>
                );
                })}
            </Select>               
        </div>     
    );
  }
  else
  {
      return (<div></div>);
  }

};
export default PreferredRegions;

