export const headerQuery = `query headerNavigationEntryQuery ($locale: String!) {
    headerNavigation(id: "55hZI8r4wKIx5916HI9UVX", locale: $locale) {
      sys {
        id
      }
      logo
      logoLink {
        ... on Link {
          sys {
            id
          }
          linkText
          url
          target
        }
      }
      displayLogoOnly
      ctaMessageLink {
        ... on Link {
          sys {
            id
          }
          linkText
          url
          target
        }
      }
      # Main Nav
      linksCollection {
        items {
          sys {
            id
          }
          linkText
          url
          target
        }
      }
      # Slide out Nav
      menuCollection(limit: 20) {
        items {
          sys {
            id
          }
          displayTitle
          menuItemsCollection(limit: 20) {
            items {
              ... on HeaderMenu {
                sys {
                  id
                }
                displayTitle
                menuItemsCollection(limit: 20) {
                  items {
                    ... on Link {
                      sys {
                        id
                      }
                      linkText
                      url
                      target
                    }
                  }
                }
              }
              ... on Link {
                sys {
                  id
                }
                linkText
                url
                target
              }
            }
          }
        }
      }
    }
  }
  `;
