import React, { useState, useEffect } from "react";
import { Loader } from "../../components/Loader";

const DeferredDetail = ({ keys, source, data }) => {
  const [hasExternalData, setHasExternalData] = useState(false);
  const [externalData, setExternalData] = useState();

  useEffect(() => {
    if (keys.filter((item) => item.external).map((a) => a).length > 0) {
      let urlGet = source.url;

      console.log(data);

      if (source.key !== undefined) {
        urlGet += data[source.key];
      }

      fetch(urlGet, {
        credentials: "same-origin",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${source.token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((res) => {
          setHasExternalData(true);
          setExternalData(res);
        })
        .catch((error) => {
          throw Error(error);
        });
    }
  }, []);

  if (!hasExternalData || !externalData) {
    return <Loader />;
  } else {
    return (
      <table className="expanded-table">
        <thead>
          <tr>
            {keys.map((item, index) => {
              return <th key={index}>{item.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {externalData.map((external, index) => {
            return (
              <tr key={index}>
                {keys.map((item, index) => {
                  if (item.external) {
                    return (
                      <td key={index}>
                        {external[item.dataIndex] !== null
                          ? external[item.dataIndex]
                          : ""}
                      </td>
                    );
                  } else {
                    return <td key={index}>{data[item.dataIndex]}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
};

export default DeferredDetail;
export { DeferredDetail };
