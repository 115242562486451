import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RCL } from "../../components/RCL";
import { convertNumber, convertCurrency } from "../../helpers/helper";
import { Button as ButtonComponent } from "@sunwing/components/dist/Button";
import { Img } from "@sunwing/shared-components";
import { Icon, Heading, Section } from "@sunwing/components";
import StarCardModal from "./StarCardModal";

import config from "../../app/config";
import cx from "classnames";
import styles from "./StarCard.module.scss";

const StarCard = ({ locale }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [starCardInfo, setStarCardInfo] = useState(null);
  const [loyaltyInfo, setLoyaltyInfo] = useState(null);
  const [provinceList, setProvinceList] = useState(null);
  const [loyaltyCardRegistered, setLoyaltyCardRegistered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getStarCardInfo = async (token) => {
    await fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((res) => {
        const data = res.starAgentInfo;
        const provinceData = res.provinces;
        const starCard = res.starCard;
        setLoyaltyInfo(data);
        setLoyaltyCardRegistered(res.isStarRegistered);
        setProvinceList(provinceData);
        setStarCardInfo(starCard);
      });
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      getStarCardInfo(accessToken);
    }
  }, [authState, oktaAuth, locale]);

  const handleShowRegistrationModal = () => {
    //code for popup model
    setIsOpen(!isOpen);
  };

  if (!loyaltyCardRegistered) {
    // User is not registered
    return (
      <Section padding="bottom">
        <div className={cx(styles.starCard, styles.starCardUnregistered)}>
          <div className={styles.starCardHeading}>
            <div className={styles.starCardImage}>
              <Img
                src="https://assets.sunwingtravelgroup.com/image/upload/v1635177525/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/STAR-Card.jpg"
                alt="Star Card"
              />
            </div>
            <Heading as="h4">
              <RCL searchKey="yourStarCard" />
            </Heading>
          </div>
          <div className={styles.starCardUnregisteredDesc}>
            <p>
              <Icon
                className={styles.starCardUnregisteredIcon}
                name="info"
                aria-hidden="true"
              />
              <RCL searchKey="STAR_NoCard" />
            </p>
          </div>
          {isOpen && loyaltyInfo && provinceList && (
            <StarCardModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              provinceList={provinceList}
              starCardData={loyaltyInfo}
              setStarCardData={setLoyaltyInfo}
              setStarCardInfo={setStarCardInfo}
              setLoyaltyCardRegistered={setLoyaltyCardRegistered}
              locale={locale}
            />
          )}
          <div className={styles.starCardUnregisteredCtas}>
            <ButtonComponent
              className={styles.registerButton}
              onClick={handleShowRegistrationModal}
            >
              {RCL({
                searchKey: "registerToStar",
              })}
            </ButtonComponent>
            <Link to={`/${locale === "fr" ? "fr" : "en"}/my-loyalty-program`}>
              {RCL({
                searchKey: "STAR_whatprogram",
              })}{" "}
              <Icon name="arrow-right" />
            </Link>
          </div>
        </div>
      </Section>
    );
  } else {
    return (
      <Section padding="bottom">
        <div className={cx(styles.starCard, styles.starCardRegistered)}>
          <div className={styles.starCardHeading}>
            <div className={styles.starCardImage}>
              <Img
                src="https://assets.sunwingtravelgroup.com/image/upload/v1635177525/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/STAR-Card.jpg"
                alt="Star Card"
              />
            </div>
            <Heading as="h4">
              <RCL searchKey="yourStarCard" />
            </Heading>
          </div>
          <div className={styles.starCardBalance}>
            <div className={styles.starCardBalanceItem}>
              <p>
                <RCL searchKey="totalPointsEarned" />
              </p>
              <Heading size="h3" as="p">
                {starCardInfo != null &&
                  convertNumber(starCardInfo?.totalPtnsEarned, locale)}
              </Heading>
            </div>
            <div className={styles.starCardBalanceItem}>
              <p>
                <RCL searchKey="totalPointsUsed" />
              </p>
              <Heading size="h3" as="p">
                {starCardInfo != null &&
                  convertNumber(starCardInfo?.totalPtnsUsed, locale)}
              </Heading>
            </div>
            <div className={styles.starCardBalanceItem}>
              <p>
                <RCL searchKey="currentPointsBalance" />
              </p>
              <Heading size="h3" as="p">
                {starCardInfo != null &&
                  convertNumber(
                    starCardInfo?.totalPtnsEarned - starCardInfo?.totalPtnsUsed,
                    locale
                  )}
              </Heading>
            </div>
            <div className={styles.starCardBalanceItem}>
              <p>
                <Icon
                  name="budget"
                  className={cx(styles.highlighted, styles.budget)}
                />
                <RCL searchKey="starCashBalance" />
              </p>
              <Heading size="h3" as="p">
                <span className={styles.highlighted}>
                  {starCardInfo != null &&
                    convertCurrency(starCardInfo?.starCashBal, locale)}
                </span>
              </Heading>
            </div>
          </div>
          <div className={styles.starCardCtas}>
            <ButtonComponent
              key="seepromotion"
              className={cx("button")}
              to={`/${locale === "fr" ? "fr" : "en"}/flyers`}
            >
              <RCL searchKey="STAR_seepromotion" />
            </ButtonComponent>
            {starCardInfo != null && starCardInfo?.ewalletLink != null && (
              <a
                href={starCardInfo?.ewalletLink}
                target="_blank"
                rel="noreferrer"
              >
                <RCL searchKey="goToEWallet" /> <Icon name="arrow-right" />
              </a>
            )}
          </div>
        </div>
      </Section>
    );
  }
};

export default StarCard;
export { StarCard };
