import { CARD_FRAGMENT } from "../fragments/Card.fragment";
import { VIDEOCARD_FRAGMENT } from "../fragments/VideoCard.fragment";
import { CARD_COLLECTION_FRAGMENT } from "../fragments/CardCollection.fragment";
import { CLOUDINARY_ASSET_FRAGMENT } from "../fragments/CloudinaryAsset.fragment";
import { COMPONENT_BLOCK_FRAGMENT } from "../fragments/ComponentBlock.fragment";
import { LINK_FRAGMENT } from "../fragments/Link.fragment";
import { PARAGRAPH_FRAGMENT } from "../fragments/Paragraph.fragment";
import { SECTION_FRAGMENT } from "../fragments/Section.fragment";
import { SEO_FRAGMENT } from "../fragments/SEO.fragment";
import { SPLIT_CONTENT_WRAPPER_FRAGMENT } from "../fragments/SplitContentWrapper.fragment";
import { TABS_AND_ACCORDION_FRAGMENT } from "../fragments/TabsAndAccordion.fragment";
import { TEAM_MEMBERS_FRAGMENT } from "../fragments/TeamMembers.fragment";
import { GRID_FRAGMENT } from "../fragments/Grid.fragment";
import {
  BASE_CARD_FRAGMENT,
  BASE_CARD_COLLECTION_FRAGMENT,
  BASE_COMPONENT_BLOCK_FRAGMENT,
  BASE_LINK_FRAGMENT,
  BASE_TABS_AND_ACCORDION_FRAGMENT,
  BASE_WHITE_SPACE_COMPONENT_FRAGMENT,
  BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT,
  BASE_SECTION_FRAGMENT,
  BASE_CLOUDINARY_ASSET_FRAGMENT,
  BASE_PARAGRAPH_FRAGMENT,  
} from "../fragments/BaseContentModelMeta.fragment";

const PAGE_BREADCRUMB_FRAGMENT = `
  fragment PageBreadcrumbFields on Page {
    sys {
      id
    }
    title
    slug
  }
`;

const PAGE_BREADCRUMB_RECURSIVE = `
  ${PAGE_BREADCRUMB_FRAGMENT}
  fragment PageBreadcrumbRecursive on Page {
    parent {
      ...PageBreadcrumbFields
      parent {
        ...PageBreadcrumbFields
        parent {
          ...PageBreadcrumbFields
        }
      }
    }
  }
`;

const GET_PAGE_SECTIONS_COUNT = `
  ${SEO_FRAGMENT}
  ${PAGE_BREADCRUMB_RECURSIVE}
  query page($contentfulId: String!, $locale: String!) {
    page(id: $contentfulId, locale: $locale) {
      sys {
        id
      }
      slug
      seo(locale: $locale) {
        ...SeoFields
      }
      sectionsCollection(locale: $locale) {
        total
      }
      ...PageBreadcrumbRecursive
    }
  }
`;

const GET_PAGE_SECTION = (sectionIndex) => `
${BASE_CARD_FRAGMENT}
${BASE_CARD_COLLECTION_FRAGMENT}
${BASE_CLOUDINARY_ASSET_FRAGMENT}
${BASE_COMPONENT_BLOCK_FRAGMENT}
${BASE_LINK_FRAGMENT}
${BASE_PARAGRAPH_FRAGMENT}
${BASE_TABS_AND_ACCORDION_FRAGMENT}
${BASE_WHITE_SPACE_COMPONENT_FRAGMENT}
${BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT}
${BASE_SECTION_FRAGMENT}
${LINK_FRAGMENT}
${CARD_FRAGMENT}
${VIDEOCARD_FRAGMENT}
${CARD_COLLECTION_FRAGMENT}
${CLOUDINARY_ASSET_FRAGMENT}
${COMPONENT_BLOCK_FRAGMENT}
${PARAGRAPH_FRAGMENT}
${SECTION_FRAGMENT}
${SPLIT_CONTENT_WRAPPER_FRAGMENT}
${TABS_AND_ACCORDION_FRAGMENT}
${TEAM_MEMBERS_FRAGMENT}
${GRID_FRAGMENT}
  query pageSection_${sectionIndex} ($contentfulId: String!, $locale: String!, $skip: Int!) {
    page(id: $contentfulId, locale: $locale) {
      sys {
        id
      }             
      sectionsCollection(locale: $locale, limit: 1, skip: $skip) {
        items {          
          ...CardFields
          ...VideoCardFields
          ...CardCollectionFields
          ...CloudinaryAssetFields
          ...ComponentBlockFields
          ...LinkFields
          ...ParagraphFields
          ...SectionFields
          ...SplitContentWrapperFields
          ...TabsAndAccordionFields
          ...TeamMembersFields           
        }
      }
    }
  }
`;

export { GET_PAGE_SECTIONS_COUNT, GET_PAGE_SECTION };
