import { useEffect, useState } from "react";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";
import { GET_PARAGRAPH_ENTRY } from "../../query/paragraph.query";

export const useParagraphEntry = (contentfulId, locale) => {
  const { oktaAuth } = useOktaAuth();
  const [block, setBlock] = useState([]);
  const [inline, setInline] = useState([]);
  const [assets, setAssets] = useState({});

  const getParagraphEntry = async () => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: GET_PARAGRAPH_ENTRY(contentfulId),
        variables: { contentfulId : contentfulId, locale: locale},
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setBlock(data?.paragraph?.content?.links?.entries?.block);
        setInline(data?.paragraph?.content?.links?.entries?.inline);
        setAssets(data?.paragraph?.content?.links?.assets);

      });
  };

  useEffect(() => {
    getParagraphEntry();
  }, [contentfulId, locale]);

  return { block, inline, assets};
};
