import React from "react";
import { Link } from "react-router-dom";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { replaceRedirectUrl } from "../../utils/replaceRedirectUrl";
import { ContentfulContentEntry } from "../ContentfulContentEntry";
import { ImageWrapper } from "../ImageWrapper";
import styles from "./TeamMemberDetail.module.scss";
import { useTeamMember } from "../../hooks/useTeamMember";

const TeamMemberDetailConnector = ({ data, userInfo, locale }) => {
  const contentfulId = data.sys.id;
  const { block, inline, assets } = useTeamMember(contentfulId, locale);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { id } = node?.data?.target?.sys;
        const entry = block.find((b) => b.sys.id === id);
        return (
          <ContentfulContentEntry
            entry={entry}
            userInfo={userInfo}
            locale={locale}
          />
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const { id } = node?.data?.target?.sys;
        const entry = inline.find((b) => b.sys.id === id);
        return (
          <ContentfulContentEntry
            entry={entry}
            userInfo={userInfo}
            locale={locale}
          />
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        const url = replaceRedirectUrl(node.data.uri);
        const isInternal = /^(\/|#)(?!\/)/.test(url);

        if (isInternal && url.indexOf("#") !== 0) {
          return (
            <Link className={styles.link} to={node.data.uri}>
              {node.content[0].value}
            </Link>
          );
        } else {
          return (
            <a
              className={styles.link}
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const { id } = node?.data?.target?.sys;
        const image = assets?.block?.find((b) => b.sys.id === id);

        return <ImageWrapper src={image.url} alt={image.title} />;
      },
    },
    renderText: (text) =>
     text.split("\n")
         .flatMap((_text, i) => [i > 0 && <br />, _text]),
  };

  return (
    <>      
      {data.details && (
        <div>
          {documentToReactComponents(data.details.json, options)}
        </div>
      )}
    </>
  );
};

export {TeamMemberDetailConnector};

