/* eslint-disable no-nested-ternary */
import React, { useContext } from "react";
import { object, arrayOf } from "prop-types";
import { RCLContext } from "../../RCL/RCLProvider";
import { CardsContainerGrid } from "../../CardsContainer";
import { HotelCard } from "@sunwing/components";
import { RCL as useTranslation } from "../../RCL";
import {getHotelURL} from "../../../helpers/helper";
import marked from "marked";
import parse from "html-react-parser";
import cx from "classnames";
import config from "../../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./HotelTilesConnectorVwq.module.scss");
  } else {
    styles = await import("./HotelTilesConnector.module.scss");
  }
})();

const HotelTilesConnector = ({ hotels }) => {
	const exploreMoreLabel = useTranslation({ searchKey: "view-details" });
    const { locale } = useContext(RCLContext);
	const loadAllLabel = useTranslation({ searchKey: "load-all" });
	const loadMoreLabel = useTranslation({ searchKey: "load-more" });	

	return (
		<>
			{styles && 
				(<>
				<CardsContainerGrid
					numberToShow={6}
					moreToShow={6}
					labels={{
						loadAll: loadAllLabel,
						loadMore: loadMoreLabel,
					}}>
					{hotels && hotels?.map(hotel => {
						return (
							<HotelCard className={cx(styles.hotelCard)}
								id={hotel.HotelCode}
								key={hotel.HotelCode}						
								header={
									hotel?.DestinationName && hotel?.RegionName
											? `${hotel?.DestinationName}, ${hotel?.RegionName}`
											:hotel?.DestinationName? hotel?.DestinationName
										: hotel?.RegionName
								}
								subheader={hotel?.HotelName}
								image={
									hotel?.HotelCode
										? {
												alt: hotel?.HotelName,
												src: `https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto/sunwing-prod/HotelImages/${hotel?.HotelCode}/16x9/001.jpg`,
										}
										: null
								} 
								stars={hotel?.StarRating}			                      
								days=' '			
								labels={{
									allInc: parse(marked(hotel?.MealPlan.map(mp=>mp?.Name).join('<br>'))),
								}}                    
								button={{}}
								link={{
									target: '_blank',
									to:
										hotel?.HotelName &&
										getHotelURL(
											locale?.slice(0, 2),
											hotel?.RegionName,
											hotel?.DestinationName,
											hotel?.HotelName
										),
									text: exploreMoreLabel,	
									icon:"arrow-right"						
								}}                       
							/>                    
						);
					})}
				</CardsContainerGrid>
			</>)
			}
    </>
	);
};

HotelTilesConnector.propTypes = {
	hotels: arrayOf(object),
};

HotelTilesConnector.defaultProps = {
	hotels: [],
};

export default HotelTilesConnector;
export { HotelTilesConnector };
