const CLOUDINARY_ASSET_FRAGMENT = `
  fragment CloudinaryAssetFields on CloudinaryAsset {
    typename
    sys {
      id
    }
    asset
  }
`;

export { CLOUDINARY_ASSET_FRAGMENT };
