import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Section, Icon, Tabs } from "@sunwing/components";
import { HotelTilesConnector } from "./HotelTiles/HotelTilesConnector";
import { Loader } from "../../components/Loader";
import config from "../../app/config";
import { VWQIcon } from "@sunwing/icons";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./GroupHotelsSectionVwq.module.scss");
  } else {
    styles = await import("./GroupHotelsSection.module.scss");
  }
})();


const GroupHotelsSection = ({ locale, type, groupName }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [allAgentGroupHotelInfo, setAllAgentGroupHotelInfo] = useState(null);

  const getHotelsHavingAgentGroupInfo = async () => {
    try {
      const accessToken = oktaAuth.getAccessToken();
      const response = await fetch(`${config.resourceServer.hisApiUrl}/${locale?.slice(0, 2)}/hotelsbycollectiontype/${type}/${groupName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        const error = new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
        throw error;
      }
  
      const data = await response.json();
      setAllAgentGroupHotelInfo(data); 
    } catch (error) {
      console.error("Failed to fetch hotel information:", error);
      setAllAgentGroupHotelInfo({});
    }
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      getHotelsHavingAgentGroupInfo();
    }
  }, [authState, locale, groupName]);

  const countries =
    allAgentGroupHotelInfo && !!allAgentGroupHotelInfo.length &&
    allAgentGroupHotelInfo
      .map((hotel) => hotel.RegionName)
      .filter((value, index, self) => self.indexOf(value) === index);

  const hotelCountryTabsData =
    countries &&
    countries?.map((country, index) => {
      return {
        key: `tabCountry-${index}`,
        panel: (
          <>
            <HotelTilesConnector hotels={allAgentGroupHotelInfo.filter(hotel => hotel?.RegionName === country)} />
          </>
        ),
        toggle: {
          iconComponent: <Icon className="custom-icon" name="children" />,
          label: {
            heading:
              country,
          },
        },
      };
    });

  return (
    <>
     {styles && 
        (<>
          {!allAgentGroupHotelInfo && !countries && <Loader />}
          {allAgentGroupHotelInfo && countries && (
            <Section titleId="hotels">
              <Tabs
                customTabWrapperClass={config.isWestJetMode ? styles.tabsWrapper : "tabsWrapper"}
                customPanelClass={ config.isWestJetMode ? styles.tabsPanel : "tabsPanel"}
                customActiveClass={styles.tabsActive}
                activeKey="tabCountry-0"
                data={hotelCountryTabsData}
                tabIconLeftComponent={config.isWestJetMode ? <VWQIcon name="chevron-left-micro" className={styles.tabsVwqIcon} /> : <Icon name="arrow-left" />}
                tabIconRightComponent={config.isWestJetMode ? <VWQIcon name="chevron-right-micro"  className={styles.tabsVwqIcon}/> : <Icon name="arrow-right" />}
              />
            </Section>
          )
          }
        </>)
      }
    </>
  )

};
export default GroupHotelsSection;


