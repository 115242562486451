import { Link } from "react-router-dom";
import { CardsSlider, Row, Cell } from "@sunwing/components";
import {
  cloudinaryImage,
  DEFAULT_WIDTH_TRANSFORMATION,
} from "../../utils/cloudinary";
import replaceRedirectUrl from "../../utils/replaceRedirectUrl";
import { useApp } from "../../hooks";
import config from "../../app/config";
import { CardConnector } from "../Card";
import { getCardComponent } from "../../utils/ComponentUtil";
import CustomPagination from "../CustomPagination";
import moment from "moment";
import { useState } from "react";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./CardCollectionVwq.module.scss");
  } else {
    styles = await import("./CardCollection.module.scss");
  }
})();

const CardCollectionConnector = ({ data }) => {
  const { MBPRedirectUrl, IGQRedirectUrl } = useApp();

  const [uid] = useState("id_" + Math.random().toString(16).slice(2));
  const [activePage, setActivePage] = useState(1);
  const [leftMoveDisabled, setLeftMoveDisabled] = useState(true);
  const [rightMoveDisabled, setRightMoveDisabled] = useState(false);


  const CardComponent = getCardComponent(data.cardsCollection.items[0].variant);


  const filteredCards = data.cardsCollection.items.filter(card =>
    (card.effectiveDate == null && card?.expiryDate == null)
    || (card?.effectiveDate == null && card?.expiryDate !== null && moment().isBefore(card?.expiryDate))
    || (card?.effectiveDate !== null && card?.expiryDate !== null && moment().isAfter(card?.effectiveDate) && moment().isBefore(card?.expiryDate))
  );

  const handleLeftPaging = (uid) => {
    let leftBtn = document.querySelector(`#${uid} > div.ComponentsSlider_module_container__9df6037e > button.ComponentsSlider_module_buttonBase__9df6037e.ComponentsSlider_module_buttonPrev__9df6037e.ComponentsSlider_module_button__fc8567c5`);
    if (leftBtn) {
      leftBtn.click();
    } else {
      return;
    }
    getActivePage();
    getMoveDisabled();
  }

  const handleRightPaging = (uid) => {
    let rightBtn = document.querySelector(`#${uid} > div.ComponentsSlider_module_container__9df6037e > button.ComponentsSlider_module_buttonBase__9df6037e.ComponentsSlider_module_buttonNext__9df6037e.ComponentsSlider_module_button__fc8567c5`);
    if (rightBtn) {
      rightBtn.click();
    } else {
      return;
    }
    getActivePage();
    getMoveDisabled();
  }

  const getTotalPages = (uid) => {
    const buttons = document.querySelectorAll(`#${uid}  button.ComponentsSliderPagination_module_bullet__d47047ff`);
    if (buttons) {
      return buttons.length;
    } else {
      return 0;
    }
  }

  const getActivePage = () => {
    setTimeout(() => {
      const buttons = document.querySelectorAll(`#${uid} button.ComponentsSliderPagination_module_bullet__d47047ff`);
      const activeButtonIndex = Array.from(buttons)?.findIndex(button =>
        button.classList.contains('ComponentsSliderPagination_module_bulletActive__d47047ff'));
      setActivePage(activeButtonIndex + 1);
      getMoveDisabled();
    }, 200);

  }

  const getMoveDisabled = () => {
    setTimeout(() => {
      const leftBtn = document.querySelector(`#${uid} > div.ComponentsSlider_module_container__9df6037e > button.ComponentsSlider_module_buttonBase__9df6037e.ComponentsSlider_module_buttonPrev__9df6037e.ComponentsSlider_module_button__fc8567c5`);

      setLeftMoveDisabled(leftBtn.classList.contains('swiper-button-disabled'));

      const rightBtn = document.querySelector(`#${uid} > div.ComponentsSlider_module_container__9df6037e > button.ComponentsSlider_module_buttonBase__9df6037e.ComponentsSlider_module_buttonNext__9df6037e.ComponentsSlider_module_button__fc8567c5`);
      setRightMoveDisabled(rightBtn.classList.contains('swiper-button-disabled'));

    }, 200);
  }

  const goToPage = (uid, index = 1) => {
    const buttons = document.querySelectorAll(`#${uid}  button.ComponentsSliderPagination_module_bullet__d47047ff`);
    if (!buttons) {
      return;
    }
    const button = buttons[index - 1];
    if (button) {
      button.click();
    } else {
      return;
    }
    getActivePage();
    getMoveDisabled();
  }

  const cards = filteredCards.map((card, index) => ({
    id: `card${index + 1}`,
    className: config.isWestJetMode ? styles?.cardCollection : styles?.cardCollectionCard,
    header: card.displayTitle,
    headingSize: card.titleSize,
    headingWeight: card.titleWeight,
    uppercase: card.titleIsUppercase,
    image: {
      src: cloudinaryImage(
        card.image?.[0]?.public_id,
        DEFAULT_WIDTH_TRANSFORMATION
      ),
      alt: "",
    },
    link: {
      text: card.link.linkText,
      target: card.link.target,
      to: replaceRedirectUrl(card.link.url, MBPRedirectUrl, IGQRedirectUrl),
    },
    color: card.colour,
    backgroundColor: card.backgroundColour,
    renderLink: (link) => {
      if (link.isInternal && link.to.indexOf("#") !== 0) {
        return (
          <Link to={link.to} target={link.target} className={link.className}>
            {link.children}
          </Link>
        );
      }
      return (
        <a href={link.to} target={link.target} className={link.className}>
          {link.children}
        </a>
      );
    },
  }));

  let CardCollection;
  switch (data.variant) {
    case "grid":
      CardCollection = (
        <Row cols={data.itemsPerRow}>
          {data.cardsCollection.items.map((card) => {
            return (
              <Cell key={card.sys.id}>
                <CardConnector data={card} />
              </Cell>
            );
          })}
        </Row>
      );
      break;
    case "slider":
    default:
      CardCollection = (
        <>
        {styles && (
        <div className={styles.hideDefaultPaginator} id={uid}>
          <CardsSlider
            CardComponent={CardComponent}
            cards={cards}
            classNames={{ slide: styles.slide, swiper: styles.swiper }}
          />
          {config.isWestJetMode && <CustomPagination
            getTotalPages={getTotalPages}
            componentId={uid}
            activePage={activePage}
            handleRightPaging={handleRightPaging}
            handleLeftPaging={handleLeftPaging}
            goToPage={goToPage}
            leftMoveDisabled={leftMoveDisabled}
            rightMoveDisabled={rightMoveDisabled}
          />
          }
        </div>)
        }
        </>
      );
      break;
  }

  return CardCollection;
};

export { CardCollectionConnector };