import { Img } from "@sunwing/shared-components";

const ImageWrapper = ({ src, alt }) => (
  <Img
    autoResponsive={false}
    style={{ maxWidth: "100%", height: "auto" }}
    src={src}
    alt={alt ? alt : ""}
  />
);

export default ImageWrapper;
export { ImageWrapper };
