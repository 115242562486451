import React from "react";
import cx from "classnames";
import { Section } from "@sunwing/components";
import { SectionConnector } from "./components/Section";
import { SplitContentWrapper } from "./components/SplitContentWrapper";
import { ParagraphConnector } from "./components/Paragraph";
import { LinkConnector } from "./components/Link";
import { CardConnector } from "./components/Card";
import { VideoCardConnector } from "./components/VideoCard";
import { CardCollectionConnector } from "./components/CardCollection";
import { ComponentBlockConnector } from "./components/ComponentBlock";
import { CloudinaryAssetConnector } from "./components/CloudinaryAsset";
import { TabsAndAccordionConnector } from "./components/TabsAndAccordion";
import { TeamMembersConnector } from "./components/TeamMembers";
import { GridConnector } from "./components/Grid";
import { ColumnSectionConnector } from "./components/ColumnSection";
import { SlideOutPanelConnector } from "./components/SlideOutPanel";
import styles from "./FluidSection.module.scss";
import { SocialMediaCard } from "./components/SocialMediaCard";

const SectionWrapper = ({
  useWrapper = true,
  isNested = false,
  padding,
  children,
  nestedMargin = true
}) =>
  useWrapper ? (
    isNested ? (
      <div className={cx(isNested && nestedMargin && styles.nested)}>{children}</div>
    ) : (
      <Section padding={padding}>{children}</Section>
    )
  ) : (
    children
  );

const FluidSection = ({
  sections,
  userInfo,
  locale,
  useWrapper = true,
  isNested = false,
  nestedMargin = true
}) =>
  sections.map((section) => {
    const sectionId = section?.sys?.id;
    const sectionComponentName = section?.typename;

    let SectionComponent;
    switch (sectionComponentName) {
      case "SplitContentWrapper":
        SectionComponent = SplitContentWrapper;
        break;
      case "Section":
        SectionComponent = SectionConnector;
        break;
      case "ComponentBlock":
        SectionComponent = ComponentBlockConnector;
        break;
      case "Paragraph":
        SectionComponent = ParagraphConnector;
        break;
      case "Link":
        SectionComponent = LinkConnector;
        break;
      case "Card":
        SectionComponent = CardConnector;
        break;
      case "CardCollection":
        SectionComponent = CardCollectionConnector;
        break;
      case "CloudinaryAsset":
        SectionComponent = CloudinaryAssetConnector;
        break;
      case "TabsAndAccordion":
        SectionComponent = TabsAndAccordionConnector;
        break;
      case "TeamMembers":
        SectionComponent = TeamMembersConnector;
        break;
      case "Grid":
        SectionComponent = GridConnector;
        break;
      case "ColumnSection":
        SectionComponent = ColumnSectionConnector;
        break;
      case "SlideOutPanel":
        SectionComponent = SlideOutPanelConnector;
        break;
      case "VideoCard":
          SectionComponent = VideoCardConnector;
        break;
      case "SocialMediaCard":
        SectionComponent = SocialMediaCard;
        break;
      default:
        break;
    }

    return (
      <SectionWrapper
        key={sectionId}
        useWrapper={
          useWrapper &&
          sectionComponentName !== "WhiteSpaceComponent" &&
          sectionComponentName !== "ComponentBlocks" &&
          sectionComponentName !== "Section"
        }
        isNested={isNested}
        padding={section?.padding ? section?.padding : "default"}
        nestedMargin={nestedMargin}
      >
        {(() => {
          if (SectionComponent) {
            return (
              <SectionComponent
                key={sectionId}
                data={section}
                userInfo={userInfo}
                locale={locale}
              />
            );
          } else {
            return <p>{section.typename} not implemented.</p>;
          }
        })()}
      </SectionWrapper>
    );
  });

export { FluidSection };
