import React, { useEffect, useState,useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";
import cx from "classnames";
import { RCL } from "../../components/RCL";
import { Heading } from "@sunwing/components";
import { Icon } from "@sunwing/components";
import { Loader } from "../../components/Loader";
import styles from "./StarPoints.module.scss";
import { convertNumber, convertCurrency } from "../../helpers/helper";
import { RCLContext } from "../../components/RCL/RCLProvider";

const StarPoints = () => {
  const [starInfo, setStarInfo] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  const { locale } = useContext(RCLContext);

  const getBalanceInfo = async (token) => {
    await fetch(`${config.resourceServer.agentApiUrl}/loyalty/Card/Balance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setStarInfo(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      return null;
    } else {
      getBalanceInfo(oktaAuth.getAccessToken());
    }
  }, []);

  if (!starInfo) {
    return <Loader />;
  }  
  
  return (
    <div className={styles.starPointsSummary}>
      <div className={styles.starPointsSummaryItem}>
        <p>
          <RCL searchKey="totalPointsEarned" />
        </p>
        <Heading size="h3" as="p">
        {convertNumber(starInfo.totalPtnsEarned,locale)}
        </Heading>
      </div>
      <div className={styles.starPointsSummaryItem}>
        <p>
          <RCL searchKey="totalPointsUsed" />
        </p>
        <Heading size="h3" as="p">
          {convertNumber(starInfo.totalPtnsUsed,locale)}
        </Heading>
      </div>
      <div className={styles.starPointsSummaryItem}>
        <p>
          <RCL searchKey="currentPointsBalance" />
        </p>
        <Heading size="h3" as="p">
          {convertNumber((starInfo.totalPtnsEarned - starInfo.totalPtnsUsed),locale)}
        </Heading>
      </div>
      <div className={styles.starPointsSummaryItem}>
        <p>
          <Icon
            name="budget"
            className={cx(styles.highlighted, styles.budget)}
          />
          <RCL searchKey="starCashBalance" />
        </p>
        <Heading size="h3" as="p" className={styles.highlighted}>
          {convertCurrency(starInfo.starCashBal,locale)}
        </Heading>
      </div>
    </div>
  );
};

export default StarPoints;
export { StarPoints };
