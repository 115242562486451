import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
} from "react-share";
import { cloudinaryImage } from "../../utils/cloudinary";
import { Card } from "@sunwing/components";
import styles from "./SocialMediaCard.module.scss";

const SocialMediaCard = ({ data }) => {
  const iconSize = 40;

  const imgSrc = cloudinaryImage(data.image?.[0]?.public_id, {
    width: 747,
  });

  return (
    <Card>
      <a href={imgSrc} download target="_blank">
        <Card.Image
          className={styles.image}
          src={cloudinaryImage(data.image?.[0]?.public_id, {
            width: 747,
          })}
          alt={data.imageAlt}
        />
      </a>
      <Card.Body>
        <div className={styles.body}>
          {data.shareToEmail && (
            <EmailShareButton url={imgSrc} className={styles["social-button"]}>
              <EmailIcon size={iconSize} round />
            </EmailShareButton>
          )}
          {data.shareToFacebook && (
            <FacebookShareButton
              url={imgSrc}
              className={styles["social-button"]}
            >
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
          )}
          {data.shareToTwitter && (
            <TwitterShareButton
              url={imgSrc}
              className={styles["social-button"]}
            >
              <TwitterIcon size={iconSize} round />
            </TwitterShareButton>
          )}
          {data.shareToPinterest && (
            <PinterestShareButton
              url={imgSrc}
              media={imgSrc}
              className={styles["social-button"]}
            >
              <PinterestIcon size={iconSize} round />
            </PinterestShareButton>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export { SocialMediaCard };
