import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react";
import { RCL as useTranslation } from "../../components/RCL";
import { Button as ButtonComponent } from "@sunwing/components/dist/Button";
import { Loader } from "../../components/Loader";
import config from "../../app/config";
import "./ActivateStarCard.scss";


const ActivateStarCard = ({
    AgentGlobalId,
    starCardNo,
    setIsActivated  
}) => {
  const { oktaAuth } = useOktaAuth();
  const [cvv, setCVV] = useState("");
  const [pin, setPIN] = useState(""); 
  const [validation, setValidation] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [isValidPin, setIsValidPin] = useState(null);
  const [isValidCvv, setIsValidCvv] = useState(null);
  

  const dictionary = {
    starCardActivate: useTranslation({
      searchKey: "starCardActivate",
    }),
    starCardIssued : useTranslation({
      searchKey: "starCardIssued",
    }),    
    starCardNotActivated: useTranslation({
      searchKey: "starCardNotActivated",
    }),
    starCardNumber: useTranslation({
        searchKey: "starCardNumber",
    }), 
    starCVV: useTranslation({
        searchKey: "starCVV",
    }),
    starCVVDescription: useTranslation({
        searchKey: "starCVVDescription",
    }),
    starCreatePIN: useTranslation({
        searchKey: "starCreatePIN",
    }),
    cancel: useTranslation({
        searchKey: "cancel",
    }),
    OK_StarCardActivated:useTranslation({
      searchKey: "OK_StarCardActivated",
    }),
    ERR_InvalidCvv: useTranslation({
        searchKey: "ERR_InvalidCvv",
    }),
    ERR_InvalidPin: useTranslation({
        searchKey: "ERR_InvalidPin",
    }), 
    ERR_RequestNotCompleted: useTranslation({
      searchKey: "ERR_RequestNotCompleted",
    }),   
  };

  // Validation for Field function
  const validateField = (input, validation) => {
    let isValid = false;
    let isRequired = true;   
    var numberRegex = /^[0-9]+$/;

    if (input.value!==undefined && isRequired) {
      switch (validation) {
        case "pin":            
              if (
                  input.value.toString().length < 4                  
                ) {
                  isValid = false;
                }
              else {
            isValid =numberRegex.test(input.value);
          }
          break;
        case "cvv":
            if (
                  input.value.toString().length < 3 
                ) {
                  isValid = false;
                }
              else {         
            isValid =numberRegex.test(input.value);
          }
          break;    
        default:
          isValid = true;
      }
    }
    else
    {
      isValid = false;
    } 
        
    return isValid;
  };

  const onChangeInput = (event) => {
    let input = event.target;
    if (input.id === "cvv") setCVV(input.value);
    else if (input.id === "pin") setPIN(input.value);    
  };

  const handleValidation = (event) => {
    let input = event.target;

    if (
      input.id === "pin"     
    ) {
      setValidation("pin");
    }

    if (input.id === "cvv") {
      setValidation("cvv");
    }

    // Validate field
    if (input.getAttribute("required") !== null && input.value !== "") {
      if (
        input.id === "pin" ||
        input.id === "cvv"       
      ) {
            if (input.id === "pin") {
            setIsValidPin(validateField(input, validation));
            input.isValid = isValidPin;
            }
            if (input.id === "cvv") {
                setIsValidCvv(validateField(input, validation));
                input.isValid = isValidCvv;
            }
        }       
    }

    onChangeInput(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setServerMessage("");

    let validForm =
      isValidPin &&
      isValidCvv;

    if (validForm) {
      var ActivationInfo = {       
          AgentGlobalId: AgentGlobalId,
          CVV: cvv,
          PIN: pin,               
      };

      fetch(`${config.resourceServer.agentApiUrl}/Loyalty/Card/Activate`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify(ActivationInfo),
      })        
        .then((response) => {          
          if (response.ok) {
            setServerMessage(dictionary["OK_StarCardActivated"]);
            setIsActivated(true);            
          } else {                      
            setServerMessage(dictionary["ERR_RequestNotCompleted"]);                    
          }
        })
        .catch((err) => {
          setServerMessage(dictionary["ERR_RequestNotCompleted"]);        
        })
        .finally(() => {
          setSubmitting(false);
        });
    }    
  }; 

  return (
    <>
      {starCardNo===null && <Loader />}
      {starCardNo!==null && (
        <div className="activateStarCard">
           <p className="activateStarCardHeading">
           {dictionary["starCardNotActivated"]}
          </p>     
        <div className="starCard">             
          <form action="">
            {serverMessage !== null && serverMessage.length > 0 && serverMessage.length< 40  && (
              <div className="successfulMessage">{serverMessage}</div>
            )}
            {serverMessage !== null && serverMessage.length > 0 && serverMessage.length> 40 && (
              <div className="errorMessage">{serverMessage}</div>
            )}
            {(isValidCvv !== null || submitting) && !isValidCvv ? (
                  <span className="errorMessage">
                    {dictionary["ERR_InvalidCvv"]}
                  </span>
                ) : null}
            {(isValidPin !== null || submitting) && !isValidPin ? (
                  <span className="errorMessage">
                    {dictionary["ERR_InvalidPin"]}
                  </span>
                ) : null}
            <div className="starCardField">
              <div className="starCardFieldItem">
                <label name="starCardNo" className="starCardNo">
                  {dictionary["starCardNumber"]}
                </label>
                <input style={{width:180}}
                  type="text"
                  id="starCardNo"
                  required={true}
                  readOnly={true}
                  value={starCardNo}                  
                />
             </div>
              <div className="starCardFieldItem">
                <label name="cvv">
                 {dictionary["starCVV"]}
                </label>
                <input style={{width:60}}
                  type="text"
                  id="cvv"
                  value={cvv}
                  maxLength={3}
                  required={true}
                  onChange={(event) => onChangeInput(event)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={(event) => handleValidation(event)}
                />               
              </div>
              <div className="starCardFieldItem">
                <label name="pin">
                   {dictionary["starCreatePIN"]}
                </label>
                <input style={{width:140}}
                  type="text"
                  id="pin"
                  value={pin}
                  maxLength={4}
                  required={true}
                  onChange={(event) => onChangeInput(event)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={(event) => handleValidation(event)}
                />               
              </div>
              <div>
              <ButtonComponent size="md" onClick={handleSubmit} disabled={submitting || !isValidCvv || !isValidPin}>
                 {dictionary["starCardActivate"]}
              </ButtonComponent>
              </div>
            </div>
            <div>           
            </div>           
          </form>
         </div>
        </div>
      )}  
    </>  
  );
};

export default ActivateStarCard;
export { ActivateStarCard };
