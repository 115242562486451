import { Link } from "react-router-dom";
import config from "../../app/config";

const RouterLink = ({ to, ...props }) => {
  const brand = config.isWestJetMode ? "vwq" : "swg";
  if (typeof to === "string") {
    return <Link {...props} to={`${to}?brand=${brand}`} />;
  } else {
    return (
      <Link
        {...props}
        to={{
          ...to,
          search: to.search ? `${to.search}&brand=${brand}` : `?brand=${brand}`,
        }}
      />
    );
  }
};

export { RouterLink };
