import { Row, Cell } from "@sunwing/components";
import { FluidSection } from "../../FluidSection";

const GridConnector = ({ data, userInfo, locale }) => {
  return (
    <Row cols={data.itemsPerRow}>
      {data.componentsCollection.items.map((item) => {
        return (
          <Cell key={item.sys.id}>
            <FluidSection
              sections={[item]}
              userInfo={userInfo}
              locale={locale}
              isNested={true}
              nestedMargin={false}
            />
          </Cell>
        );
      })}
    </Row>
  );
};

export { GridConnector };
