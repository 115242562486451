import { useContext } from "react";
import marked from "marked";
import parse from "html-react-parser";
import { RCLContext } from "./RCLProvider";

const RCL = ({ searchKey }) => {
  const { locale, dictionary } = useContext(RCLContext);
  try {
    const item = dictionary[locale][searchKey];
    let value = item.value;
    if (item.parseAsMarkdown) {
      value = marked(item.value);
    }
    return parse(value);
  } catch {
    if (process.env.NODE_ENV === "development") {
      console.warn(`Cannot find key '${searchKey}' in the dictionary`);
    }
    return null;
  }
};

export default RCL;
