import React, { useState,useContext} from "react";
import classNames from "classnames/bind";
import config from '../../app/config';
import { RCLContext } from "../../components/RCL/RCLProvider";
import { Button } from "@sunwing/components/dist/Button";
import { Row, Cell} from "@sunwing/components";
import { RCL } from "../../components/RCL";
import styles from "./AgencyValidation.module.scss";
import { VWQIcon } from "@sunwing/icons";

const cx = classNames.bind(styles);

const AgencyValidation = ({agency,setAgency}) => {    

    
  const { locale } = useContext(RCLContext);
  const [serverMessage, setServerMessage] = useState("");
	const [serverMessageType, setServerMessageType] = useState("error");
	const [submitting, setSubmitting] = useState(false);
	const [agencyPhone,setAgencyPhone]=useState(null);	 	
  const [validAgencyPhone, setValidAgencyPhone] = useState(true);	 
		
    const errorMessage=RCL({
                       searchKey: "ERR_AgencyPhoneNotValid",
                     });

    const handlePhoneValidationSubmit = event => {
        event.preventDefault(); 
        setServerMessage("");
        setSubmitting(true);       
      
      if (validAgencyPhone) {         

            fetch(`${config.resourceServer.agentApiUrl}/opn/agency/${agencyPhone}`, {
                method: "GET",         
            })
            .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
                }           
            return response.json();
            })
            .then(res => {                         
            setAgency(res);  
            if(agency && agency.name!==null)
            {             
            //window.location.href = "/Signin/Register"; 
            setAgencyPhone(agency.cd);
            setServerMessage("");
            setServerMessageType("success");
            setSubmitting(false);
            }
            else
            {
                setAgencyPhone(agencyPhone);
                setServerMessage(errorMessage);
                setServerMessageType("error");
                setSubmitting(false);
            }         
            
                
            })
            .catch(error => { 
                setServerMessage(errorMessage);
                setServerMessageType("error");
                setSubmitting(false);         
            });
        } else {        
            setSubmitting(false); 
        }
    };
    
    const allowNumbers = (event, length) => {
		// Limits the keyboard entry for mobile phone field (restricts to numbers)
		const charCode = event.keyCode.which ? event.keyCode.which : event.keyCode;
		if ((charCode > 31 && (charCode < 48 || charCode > 57)) || length >= 10) {
			event.preventDefault();
		}
	};

    const validatePhoneFormat = () => {		
		let isValidPhone=true;
		if(agencyPhone && (agencyPhone.length<10 || agencyPhone.length>10))
		{
			isValidPhone=false;
		}		
		if(isValidPhone)
		{			
			const phoneRegex = /^[0-9]+$/;
			isValidPhone=phoneRegex.test(agencyPhone);
		}		
		return isValidPhone;
	};

    const onChangePhoneInput = event => {               
		setAgencyPhone( event.target.value);
		setValidAgencyPhone(validatePhoneFormat());	
    };

    const onLoginClick = event => {
        window.location.href = `${window.location.origin}/${locale}/login`;
    };   

  return (
       <div className={cx("agencyContainer")}>
        <div className={styles.agencyRegisterTitle}> <RCL searchKey="register" />   </div>               
        <form
                name="agencyValidation"         
                acceptCharset="UTF-8" noValidate
                onSubmit={handlePhoneValidationSubmit}
        >
          <div className={styles.agencyRegisterHeading}>
            <RCL searchKey="verifyAgency" />     
            <div className={styles.agencyAlertContainer}>     
              <VWQIcon name="exclamation" className={styles.agencyIcon}/>
              <div className={styles.agencyAlertText}>
                <RCL searchKey="verifyAgency_alert" />
              </div>
            </div>
          </div>   
          {serverMessage.length > 0 && (
            <div
              className={cx("errorMessage")}
              role="alert"
            >
              {serverMessage}
            </div>
          )}
          <Row cols={1} className={cx("formGroup")}>
			        <Cell>
                <div className={cx("inputContainer")}>
                    <label htmlFor="ap-agencyNumber">
                        <span className={cx("inputContainer__label", "required")}>
                            <RCL searchKey="agencyPhone" />
                        </span>
                        <input
                            value={agencyPhone}
                            type="text"
                            className="form-control"
                            id="ap-agencyNumber"
                            name="agencyNumber"
                            maxLength="10"
                            onChange={onChangePhoneInput}
                            onBlur={onChangePhoneInput}
                            onKeyPress={event => {
                                allowNumbers(event.nativeEvent, event.target.value.length);
                            }}
                            onKeyUp={event=>{
                                setValidAgencyPhone(validatePhoneFormat());
                            }}
                            disabled={submitting}
                        />
                    </label>           
                    {!validAgencyPhone && (
                            <div className={cx("validationErrorMessage")}>
                                <RCL searchKey="ERR_AgencyPhoneRequired" />
                            </div>
                        )}
                </div>
            </Cell>
          </Row>
          <Row cols={1} className={cx("formGroup")}>              
            <Cell> 
              <Button
                  className={cx("sectionButtons__btn")}
                  type="submit"
                  onClick={null}
                  disabled={(submitting || agencyPhone===null || !validAgencyPhone)}
              >
                  <RCL searchKey="verify" />
              </Button>
            </Cell>             
          </Row>
            <Row cols={2} className={cx("formGroup")}>              
             <Cell> 
                <button 
                  onClick={onLoginClick}
                  className={cx("buttonToLink")}
                >
                  <RCL searchKey="backToSignIn" />
                </button>  
              </Cell>             
            </Row>                  
        </form>
        
      </div>
    );
  };


export default AgencyValidation;
