/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Entry from "./Entry";
import { Icon } from "@sunwing/icons/dist/sunwing";
import { RCL } from "../RCL";
import styles from "./WebPush.module.scss";
import config from "../../app/config";

const InboxBell = ( { lang, inboxMessages }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isVisible) {
        setIsVisible(false);
      }
    }
  }

  return (
    <div id="alert-notification" tabIndex='-1' onBlur={handleBlur}>
        <a onClick={() => { if (inboxMessages?.count && inboxMessages?.count > 0) setIsVisible((prev) => !prev) }}  className={styles.alertNotification}>
        <span className={styles.alertNotificationCount}>{inboxMessages?.count?? 0}</span>
        <span className={styles.alertNotificationCircle}><Icon name="email" className={styles.alertNotificationIcon}/></span>
      </a>
      {isVisible && (
        <div className={styles.notificationHub}>
          <div><RCL searchKey="messages" /></div>
          {inboxMessages &&
            inboxMessages.messages
            ?.sort((a, b) => ((a.startDateUtc !== null) && -a.startDateUtc.localeCompare(b.startDateUtc)))
            .slice(0,3).map((mes, index) => {
              return (
                <Entry
                  key={index}
                  lang={lang}
                  id={index}
                  title={mes.subject}
                  startedAt={mes.startDateUtc}
                />
            )}
          )}
        { (inboxMessages.count > 3) && (<a href={`/${lang}/InboxMessage?messageId=ViewAll&brand=${config.isWestJetMode ? "vwq" : "swg"}`} style={{color:"blue"}}><RCL searchKey="view-all" /></a>)}
        </div>
      )}
    </div>
  );
};

export default InboxBell;
