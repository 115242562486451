import React, { useEffect, useState } from "react";
import { number, node, string, shape } from "prop-types";
import cx from "classnames";
import {
    Icon,
    Row,
    Cell
  } from "@sunwing/components";
import styles from "./CardsContainerGrid.module.scss";

const defaultLabels = {
	loadAll: "Load all",
	loadMore: "Load more",
};

const CardsContainerGrid = ({
	children,
	numberToShow,
	moreToShow,
	cardsPerRow,
	labels: providedLabels,
}) => {
	const [displayedItems, setDisplayedItems] = useState(numberToShow);
	const [isButtonVisible, setButtonVisibility] = useState(true);
	const [remainingItems, setRemainingItems] = useState(false);
	const labels = { ...defaultLabels, ...providedLabels };

	useEffect(() => {
		if (React.Children.count(children) <= 6) {
			setButtonVisibility(false);
		}
	}, [children]);

	useEffect(() => {
		if (React.Children.count(children) - displayedItems <= moreToShow) {
			setRemainingItems(true);
		}
	}, [children, displayedItems, moreToShow]);

	const showMore = () => {
		const totalItems = React.Children.count(children);
		const itemsShowing = displayedItems + moreToShow;

		if (totalItems >= itemsShowing) {
			setDisplayedItems(itemsShowing);
		} else if (itemsShowing > totalItems) {
			setDisplayedItems(totalItems);
			setButtonVisibility(false);
		}
		if (totalItems === itemsShowing) {
			setButtonVisibility(false);
		}
		if (totalItems - itemsShowing <= moreToShow) {
			setRemainingItems(true);
		}
	};

	const handleOnClick = () => {
		showMore();
	};

	return (
		<div className={cx(styles.container)}>
			<Row cols={cardsPerRow === 3 ? 3 : 4}>
				{React.Children.map(children, (component, index) => {
					if (index < displayedItems) {
						return <Cell>{component}</Cell>;
					}
					return null;
				})}
			</Row>
			{isButtonVisible && children.length > 3 && (
				<button type="button" className={styles.loadMore} onClick={handleOnClick}>
					{remainingItems ? <span>{labels.loadAll}</span> : <span>{labels.loadMore}</span>}
					<Icon name="arrow-down" className={`swg-text-xs ${styles.loadMoreIcon}`} />
				</button>
			)}
		</div>
	);
};

CardsContainerGrid.propTypes = {
	numberToShow: number,
	moreToShow: number,
	cardsPerRow: number,
	children: node.isRequired,
	labels: shape({ loadAll: string }, { loadMore: string }),
};
CardsContainerGrid.defaultProps = {
	numberToShow: 6,
	moreToShow: 6,
	cardsPerRow: 3,
	labels: defaultLabels,
};

export default CardsContainerGrid;
export { CardsContainerGrid };
