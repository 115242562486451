import { useState } from "react";
import { SlideOutPanel } from "@sunwing/components";
import { FluidSection } from "../../FluidSection";
import config from '../../app/config';

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./SlideOutPanelVwq.module.scss");
  } else {
    styles = await import("./SlideOutPanel.module.scss");
  }
})();

const SlideOutPanelConnector = ({ data, userInfo, locale }) => {
  const [panelOpen, setPanelOpen] = useState(false);

  const panelOpenHandler = () => {
    setPanelOpen(true);
  };

  const panelCloseHandler = () => {
    setPanelOpen(false);
  };

  const SlideOutPanelLink = (
    <>
      {styles && 
        (<>
          <button
            type="button"
            className={styles.button}
            onClick={panelOpenHandler}
          >
            {data.displayTitle}
          </button>
          <SlideOutPanel
            isOpen={panelOpen}
            handleClose={panelCloseHandler}
            labels={{ title: data.displayTitle }}
            classNames={{ menu: styles.menu, title: styles.title }}
          >
            <FluidSection
              sections={data.componentsCollection.items}
              userInfo={userInfo}
              locale={locale}
              isNested={true}
            />
          </SlideOutPanel>
      </>)}
    </>
  );

  return SlideOutPanelLink;
};

export { SlideOutPanelConnector };
