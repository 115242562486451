/* eslint-disable no-param-reassign */
import React, { useEffect,useRef,useContext } from "react";
import ReactDOM from "react-dom";
import config from "../../app/config";
import { RCL as useTranslation } from "../../components/RCL";
import { RCLContext } from "../../components/RCL/RCLProvider";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import PreferredRegions from "./PreferredRegions.js";
import "./theme.scss";

let observer = null;

const cleanQueryURL = _queries => {
	const siteUrl = new URL(window.location.href);
	const queries = siteUrl.searchParams;

	_queries.forEach(query => {
		queries.set(query, "");
	});

	siteUrl.search = queries.toString();

	const obj = { Title: document.title, Url: siteUrl.toString() };
	window.history.pushState(obj, obj.Title, obj.Url);
};

const { issuer, clientId, redirectUri, scopes} =
config.oidc;
 

const OktaSignUpWidget = ({agency,regions,setCorsErrorModalOpen}) => {
	const widgetRef = useRef(null);
    const { locale } = useContext(RCLContext);
	const redirectUrl= `${redirectUri}/${locale}/login/callback`; 
	const isAction ="register"; 


	const dictionary = {		
		"okta-widget-email-placeholder": useTranslation({
			searchKey: "okta-widget-email-placeholder",
		}),
		"okta-widget-password": useTranslation({
			searchKey: "okta-widget-password",
		}),
		"okta-widget-confirm-password": useTranslation({
			searchKey: "okta-widget-confirm-password",
		}),
		"okta-widget-firstName": useTranslation({
			searchKey: "firstName",
		}),
		"okta-widget-lastName": useTranslation({
			searchKey: "lastName",
		}),
		"okta-widget-languagePreference": useTranslation({
			searchKey: "languagePreference",
		}),		
		"ERR_PasswordMismatch": useTranslation({
			searchKey: "ERR_PasswordMismatch",
		}),		
		"okta-widget-error-E0000004": useTranslation({
			searchKey: "okta-widget-error-E0000004",
		}),
		"okta-widget-error-E0000006": useTranslation({
			searchKey: "okta-widget-error-E0000006",
		}),
		"okta-widget-error-E0000119": useTranslation({
			searchKey: "okta-widget-error-E0000119",
		}),
		"okta-widget-chars": useTranslation({ searchKey: "okta-widget-chars" }),
		"okta-widget-numbers": useTranslation({ searchKey: "okta-widget-numbers" }),
		"okta-widget-lowercase": useTranslation({
			searchKey: "okta-widget-lowercase",
		}),
		"okta-widget-uppercase": useTranslation({
			searchKey: "okta-widget-uppercase",
		}),
		"okta-widget-symbols": useTranslation({ searchKey: "okta-widget-symbols" }),
		"okta-widget-username": useTranslation({
			searchKey: "okta-widget-username",
		}),
		"OptinMessage": useTranslation({
			searchKey: "OptinMessage",
		}),
		"okta-widget-social-text": useTranslation({
			searchKey: "okta-widget-social-text",
		}),
		"okta-widget-sign-up-text": useTranslation({
			searchKey: "okta-widget-sign-up-text",
		}),
		"okta-widget-create-an-account": useTranslation({
			searchKey: "okta-widget-create-an-account",
		}),
		"okta-widget-new-account-heading": useTranslation({
			searchKey: "okta-widget-new-account-heading",
		}),
		"okta-widget-new-account-message": useTranslation({
			searchKey: "okta-widget-new-account-message",
		}),
        "emailPersonal":useTranslation({
			searchKey: "emailPersonal",
		}),
        "register":useTranslation({
			searchKey: "register",
		}),
        "PrivacyPolicy":useTranslation({
			searchKey: "PrivacyPolicy",
		}),
        "PrivacyPolicylinktext":useTranslation({
			searchKey: "PrivacyPolicylinktext",
		}),
        "Contactuslink":useTranslation({
			searchKey: "Contactuslink",
		}),
        "Contactuslinktext":useTranslation({
			searchKey: "Contactuslinktext",
		}),
        "preferredRegionsLabel":useTranslation({
            searchKey:"PreferredRegion"
        }),
        "backToSignIn":useTranslation({
            searchKey:"backToSignIn"
        }),
		"regionerror":useTranslation({
            searchKey:"regionerror"
        }),               
	};

	const selectedRegionArr= (regionArr, selectedregions) => {		
		
		if(selectedregions!==undefined && selectedregions!==null){

		let matchingItems = [];
		for(var i=0; i < selectedregions.length; i++)
		{
		  matchingItems.push(selectedregions[i].innerText);			
		}				
		const filteredSelectedRegions=regionArr.filter(item=>matchingItems.indexOf(item.cProvinceName)>=0);
		const selectedRegionCodes= filteredSelectedRegions.map(({cProvinceCode})=>cProvinceCode);
		return selectedRegionCodes;
	    }
		else
		return ["CA"];
		 
	 }
	
	const injectAdditionalElement = _ref => {
		const preferredRegionsElement = _ref.querySelector(".custom-multiple-dropdown");
		if (preferredRegionsElement) {
			return;
		}

		const formContainerElement = document.querySelector(".o-form-content"); 
        /* Injects Agency phone and name*/
		const firstElement = formContainerElement.querySelector(".o-form-head");
       
        //To add agency phone
		const agencyPhoneWrapperElement = document.createElement("div");
		agencyPhoneWrapperElement.className = "o-form-fieldset o-form-label-top margin-btm-0";
        agencyPhoneWrapperElement.id="divAgencyPhone";       
        agencyPhoneWrapperElement.innerText=agency.cd; 
		formContainerElement.insertBefore(agencyPhoneWrapperElement,firstElement.nextSibling);

        //To add agency name
		const agencyNameWrapperElement = document.createElement("div");
		agencyNameWrapperElement.className = "o-form-fieldset o-form-label-top margin-btm-0";
        agencyNameWrapperElement.id="divAgencyName";       
        agencyNameWrapperElement.innerText=agency.name; 
		formContainerElement.insertBefore(agencyNameWrapperElement,firstElement.nextSibling);

		/* Injects Region into the widget in order to simplify the registration process */
        const additionalContainerElement = document.querySelector(".o-form-fieldset-container"); 
		const lastElement = additionalContainerElement.querySelector(".required-fields-label");
      
       //To add regions 
        const regionWrapperElement = document.createElement("div"); 
        regionWrapperElement.className = "o-form-fieldset o-form-label-top margin-btm-0";
    
        const drpWrapperElement = document.createElement("div");
        drpWrapperElement.id = "custom-multiple-dropdown";
        drpWrapperElement.className = "custom-multiple-dropdown";
        regionWrapperElement.appendChild(drpWrapperElement);
        
        additionalContainerElement.insertBefore(regionWrapperElement,lastElement);     
        ReactDOM.render(<PreferredRegions regions={regions} label={dictionary["preferredRegionsLabel"]}/>,document.getElementById("custom-multiple-dropdown"));		
	};
	
	// _ref = widget reference,
	// _form = widget forms: register 
	const updateWidget = (_ref, _form) => {       
		const widgetLoadInterval = setInterval(() => {
			// Form screens
			const isRegistrationForm = _ref.querySelector(".registration");			
			const isRegistrationComplete = _ref.querySelector(".registration-complete");
			const isLoginForm = _ref.querySelector(".primary-auth");		

			// Form element target for legal
			const contentDIV = _ref.getElementsByClassName("auth-content")[0];

			// Back links
            const signUpElement = _ref.getElementsByClassName("registration-link")[0];			
			const registerElement = _ref.getElementsByClassName("link help")[0];
			const registerBackLinkElement = _ref.getElementsByClassName("back-btn")[0];		

			// Register form
			const createAccountElement = _ref.getElementsByClassName("button button-primary")[0];
           
			if (_form === "register") {
				if (isRegistrationForm) {
					clearInterval(widgetLoadInterval);                   
					// Check if backToSignIn copy exists in widget
					const backToSignInElementDIV = _ref.querySelector(".backToSignIn");
					if (!backToSignInElementDIV) {
                        // Inject sign in back button
                        const backToSignInWrapperElement = document.createElement("div");   
                        backToSignInWrapperElement.className="backToSignIn";
                        const anchorbackToSignInElement = document.createElement("a");
                        anchorbackToSignInElement.className = "linkStyle";
                        anchorbackToSignInElement.innerText=dictionary["backToSignIn"];                        
                        anchorbackToSignInElement.href = `${window.location.origin}/${locale}/login`;
                        anchorbackToSignInElement.target="_self";
                        anchorbackToSignInElement.id="backToSignInLink";
                        backToSignInWrapperElement.appendChild(anchorbackToSignInElement);						
						contentDIV.appendChild(backToSignInWrapperElement);
					}

					if (registerElement) {
						const registerMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState = registerElement.getAttribute("data-click") === "true";

						if (!clickState) {
							registerElement.setAttribute("data-click", true);
							registerElement.addEventListener("click", registerMethod, true);
						}

						const resetMethod = () => {
							setTimeout(() => {
								updateWidget(_ref, "registration-complete");
							}, 1000);
						};

						const accountClickState = createAccountElement.getAttribute("data-click") === "true";

						if (!accountClickState) {
							createAccountElement.setAttribute("data-click", true);
							createAccountElement.addEventListener("click", resetMethod, true);
						}

						injectAdditionalElement(_ref);
					}
				}
			} else if (_form === "registration-complete") {
				if (isRegistrationComplete) {
					clearInterval(widgetLoadInterval);

					setTimeout(() => {
						// Remove all legal
						const legalDIV = _ref.querySelectorAll(".legal");

						if (legalDIV.length > 0) {
							legalDIV.forEach(item => {
								item.parentNode.removeChild(item);
							});
						}
					}, 200);

					if (registerBackLinkElement) {
						const registerBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState = registerBackLinkElement.getAttribute("data-click") === "true";

						if (!clickState) {
							registerBackLinkElement.setAttribute("data-click", true);
							registerBackLinkElement.addEventListener("click", registerBackMethod, true);
						}
					}
				}
			}else if (_form === "sign-in") {

				if (isLoginForm) {
					clearInterval(widgetLoadInterval);

					// Remove legal copy and help link from widget
					const legalDIV = _ref.querySelector(".legal");
					if (legalDIV) {
						legalDIV.parentNode.removeChild(legalDIV);
					}

					// Remove help link
					const helpElement = _ref.getElementsByClassName("js-help-link")[0];
					if (helpElement) {
						helpElement.parentNode.removeChild(helpElement);
					}
						

					if (signUpElement) {
						const signUpMethod = () => {
							updateWidget(_ref, "register");
						};

						const clickState = signUpElement.getAttribute("data-click") === "true";

						if (!clickState) {
							signUpElement.setAttribute("data-click", true);
							signUpElement.addEventListener("click", signUpMethod, true);
						}
					}									
				}
			} 			 
		}, 150);
	};

	const setupUnlockAccountObserver = _ref => {
		// Select the target node
		const target = _ref.getElementsByClassName("auth-content-inner")[0];

		// Create an observer instance
		observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.type === "childList") {
					// Handle automatic unlock behaviour
					if (mutation.nextSibling?.className === "account-unlock") {
						updateWidget(_ref, "unlock");
					} else if (mutation.nextSibling?.className === "registration") {
						updateWidget(_ref, "sign-in");
					}
				}
			});
		});

		// Configuration of the observer
		const observerConfig = { attributes: false, childList: true };

		if (target) {
			// Tell user agent to start observing
			observer.observe(target, observerConfig);
		}
	};
 

	const widgetConfig = {
        clientId,
        redirectUri:redirectUrl,
		baseUrl: issuer.split("/oauth2")[0],		
        authParams: {
            // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
            issuer,
            scopes,
        },
		language: locale,
		features: {
			registration: true,
			router:true	
		},		
		i18n: {
			[locale]: {
				// COMMON LABELS
				goback: " ",				
				"primaryauth.username.placeholder": dictionary["okta-widget-email-placeholder"],
				"primaryauth.username.tooltip": dictionary["okta-widget-email-placeholder"],			

				// ERRORS
				"errors.E0000004": dictionary["okta-widget-error-E0000004"],
				"errors.E0000006": dictionary["okta-widget-error-E0000006"],
				"errors.E0000119": dictionary["okta-widget-error-E0000119"],

				/* NOTE: Currently Okta does not pickup these password strength translations */
				"registration.passwordComplexity.minLength": dictionary["okta-widget-chars"],
				"registration.passwordComplexity.minLower": dictionary["okta-widget-lowercase"],
				"registration.passwordComplexity.minUpper": dictionary["okta-widget-uppercase"],
				"registration.passwordComplexity.minNumber": dictionary["okta-widget-numbers"],
				"registration.passwordComplexity.minSymbol": dictionary["okta-widget-symbols"],
				"registration.passwordComplexity.excludeUsername": dictionary["okta-widget-username"],

				// REGISTRATION LABELS
				"registration.signup.text": dictionary["okta-widget-sign-up-text"],
				"registration.form.title": dictionary["okta-widget-create-an-account"],
				"registration.form.submit": dictionary["register"],
			},
		},		
		

		registration: {
			parseSchema: (schema, onSuccess) => {
				schema.profileSchema.properties.email.title = dictionary["emailPersonal"];
				schema.profileSchema.properties.password.title = dictionary["okta-widget-password"];
				schema.profileSchema.properties.firstName.title = dictionary["okta-widget-firstName"];
				schema.profileSchema.properties.lastName.title = dictionary["okta-widget-lastName"];
				schema.profileSchema.properties.language.title = dictionary["okta-widget-languagePreference"];				

				schema.profileSchema.properties.confirmPassword = {
					type: "password",
					description: dictionary["okta-widget-confirm-password"],
					default: dictionary["okta-widget-password"],
					title: dictionary["okta-widget-confirm-password"],
				};
				schema.profileSchema.fieldOrder.push("confirmPassword");

				/* TODO: Temporary solution to fix localization bug */
				// Setup localizations for passwordComplexity

				if (locale === "fr") {
					const myPasswordRuleDescriptionOverride = {
						minLength: dictionary["okta-widget-chars"],
						"/[\\d]+/": dictionary["okta-widget-numbers"],
						"/[-!$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\\\\\/@#]+/": dictionary["okta-widget-symbols"],
						"/[a-z]+/": dictionary["okta-widget-lowercase"],
						"/[A-Z]+/": dictionary["okta-widget-uppercase"],
						"/^[#/userName]/": dictionary["okta-widget-username"],
					};

					const passwordRules = schema.profileSchema.properties.password.allOf;

					passwordRules.forEach(rule => {
						if (typeof rule.minLength === "number" && myPasswordRuleDescriptionOverride.minLength) {
							rule.description = dictionary["okta-widget-chars"].replace("{0}", rule.minLength);
						} else if (
							rule.format === "/^[#/userName]/" &&
							myPasswordRuleDescriptionOverride[rule.format]
						) {
							rule.description = myPasswordRuleDescriptionOverride[rule.format];
						} else if (rule.format && myPasswordRuleDescriptionOverride[rule.format]) {
							rule.description = myPasswordRuleDescriptionOverride[rule.format].replace("{0}", 1);
						}
					});
				}
                schema.profileSchema.fieldOrder = ["email", "password", "confirmPassword", "firstName", "lastName", "language"]; 
				onSuccess(schema);
			},
			preSubmit: (postData, onSuccess, onFailure) => {
				const selectedRegions=selectedRegionArr(regions,document.getElementsByClassName("ant-select-selection-item-content")); 
				if (postData.password === postData.confirmPassword && selectedRegions !== 'undefined' && selectedRegions!==null && selectedRegions.length>0) {
					postData.preferredLanguage = locale;
					postData.locale = locale.concat("_CA");
					postData.agencyPhoneNumber=document.getElementById("divAgencyPhone").innerText;					
					postData.agencyName=document.getElementById("divAgencyName").innerText;
					postData.preferredProvinces=selectedRegions;
					postData.subscribeUpdateDate = new Date().toISOString();									
					onSuccess(postData);
				} else if (postData.password !== postData.confirmPassword) {
					const error = {
						errorSummary: "API Error",
						errorCauses: [
							{
								errorSummary: dictionary["ERR_PasswordMismatch"],
								reason: "registration.error.confirmPassword",
								resource: "User",
								property: "confirmPassword",
								arguments: [],
							},
						],
					};
					onFailure(error);					
				}
				else {
					var error = {
					   "errorSummary": "API Error",
					   "errorCauses": [
						   {
							   "errorSummary":  dictionary["regionerror"],
							   "resource": "User",                               
							   "arguments": []
						   }
					   ]
				   };
				   onFailure(error);
			   }
			},
			// eslint-disable-next-line no-unused-vars
			postSubmit: (response, onSuccess) => {
				//updateWidget(widgetRef.current, "register");				
				onSuccess(response);
			},
		},
	};
   

	useEffect(() => {       
		if (!widgetRef.current) return false;
		let widgetInstance;

		import("@okta/okta-signin-widget")
			.then(({ default: OktaSignIn }) => {
				if (widgetRef.current) {                   
					widgetInstance = new OktaSignIn(widgetConfig);
					widgetInstance.showSignInAndRedirect({ el: widgetRef.current });                  
                    widgetInstance.on('afterRender', function (context) {
                        if (context.controller === 'registration') {
                            updateWidget(widgetRef.current, "register");                            
                            return;
                        }
                    });   
					// Initialize when widget loads on Login form
					const widgetInitInterval = setInterval(() => {
						const signUpElement = widgetRef.current.getElementsByClassName("registration-link")[0];

						if (signUpElement) {
							clearInterval(widgetInitInterval);
							
                            const unlockElement = widgetRef.current.getElementsByClassName("js-unlock")[0];
							if (unlockElement) {
								unlockElement.addEventListener("click", () => {
									updateWidget(widgetRef.current, "unlock");
								});
								unlockElement.setAttribute("data-click", true);

								setupUnlockAccountObserver(widgetRef.current);
							}

							signUpElement.addEventListener("click", () => {
								updateWidget(widgetRef.current, "register");
							});
							signUpElement.setAttribute("data-click", true);							
							
							// Detection for account actions and defaults to screen based on querystring
							if (isAction === "register") {
								signUpElement.click();
							} 
							if (isAction) {
								// Modify action query string so if user cancels flow it will not default again
								cleanQueryURL(["action"]);
							}							
						}

						// Set cookie expiry
						const expiration = new Date();
						expiration.setHours(expiration.getHours() + 2);						
					}, 150);
				}
			})
			.catch(e => console.warn(e));

		return () => {            
			if (widgetInstance) {
				if (observer !== null) {
					// Stop observing unlock view
					observer.disconnect();
				}

				widgetInstance.remove();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dictionary,locale]);
    
	return <div className="sso-widget" ref={widgetRef} />;
};

export default OktaSignUpWidget;
