const BASE_CARD_FRAGMENT = `
  fragment BaseCardMeta on Card {
    typename
    sys {
      id
    }
  }
`;

const BASE_CARD_COLLECTION_FRAGMENT = `
  fragment BaseCardCollectionMeta on CardCollection {
    typename
    sys {
      id
    }
  }
`;

const BASE_CLOUDINARY_ASSET_FRAGMENT = `
  fragment BaseCloudinaryAssetMeta on CloudinaryAsset {
    typename
    sys {
      id
    }
  }
`;

const BASE_COMPONENT_BLOCK_FRAGMENT = `
  fragment BaseComponentBlockMeta on ComponentBlock {
    typename
    sys {
      id
    }
  }
`;

const BASE_LINK_FRAGMENT = `
  fragment BaseLinkMeta on Link {
    typename
    sys {
      id
    }
  }
`;

const BASE_PARAGRAPH_FRAGMENT = `
  fragment BaseParagraphMeta on Paragraph {
    typename
    sys {
      id
    }
  }
`;

const BASE_TABS_AND_ACCORDION_FRAGMENT = `
  fragment BaseTabsAndAccordionMeta on TabsAndAccordion {
    typename
    sys {
      id
    }
  }
`;

const BASE_WHITE_SPACE_COMPONENT_FRAGMENT = `
  fragment BaseWhiteSpaceComponentMeta on WhiteSpaceComponent {
    typename
    sys {
      id
    }
  }
`;
const BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT=`
fragment BaseSplitContentWrapperMeta on SplitContentWrapper {
  typename
  sys {
    id
  }
}
`;
const BASE_SECTION_FRAGMENT=`
fragment BaseSectionMeta on Section {
  typename
  sys {
    id
  }
}
`;

export {
  BASE_CARD_FRAGMENT,
  BASE_CARD_COLLECTION_FRAGMENT,
  BASE_CLOUDINARY_ASSET_FRAGMENT,
  BASE_COMPONENT_BLOCK_FRAGMENT,
  BASE_LINK_FRAGMENT,
  BASE_PARAGRAPH_FRAGMENT,
  BASE_TABS_AND_ACCORDION_FRAGMENT,
  BASE_WHITE_SPACE_COMPONENT_FRAGMENT,
  BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT,
  BASE_SECTION_FRAGMENT,
};
