import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";
import { RCL } from "../../components/RCL";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { useRouting } from "../../hooks";
import { Section } from "@sunwing/components";
import { SortTable } from "./SortTable";
import { StarPoints } from "./StarPoints";
import { Loader } from "../../components/Loader";

const StarsTransaction = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const [transaction, setTransaction] = useState(null);

  //`https://vouchersapi.sunwingtravelgroup.com/v1/welcome`

  const getTransactionInfo = async (token) => {
    console.log(token);
    await fetch(`${config.resourceServer.agentApiUrl}/loyalty/transaction`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTransaction(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        getTransactionInfo(oktaAuth.getAccessToken());
      });
    }
  }, [authState, oktaAuth, locale]);

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/transactions" },
        fr: { path: "/fr/transactions" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userInfo) {
    return <Loader />;
  }

  if (!transaction) {
    return <Loader />;
  }

  const bookingSearchLabel = RCL({ searchKey: "searchBookingNumber" });

  const columns = [
    {
      title: <RCL searchKey="bookingNumber" />,
      dataIndex: "bookingNo",
    },
    {
      title: <RCL searchKey="issueDate" />,
      dataIndex: "issueDate",
      defaultSortOrder: "descend",
      sort: "date",
      render: {
        type: "Date",
        format: "D MMM, YYYY",
      },
    },
    {
      title: <RCL searchKey="transactionType" />,
      dataIndex: "transactionType",
    },
    { title: <RCL searchKey="points" />, dataIndex: "points" },
    { title: <RCL searchKey="description" />, dataIndex: "description" },
    {
      title: <RCL searchKey="bookingNumber" />,
      dataIndex: "bookingNo",
      external: true,
      expandable: true,
      expandableOrder: 1,
    },
    {
      title: <RCL searchKey="bookingType" />,
      dataIndex: "bookingType",
      external: true,
      expandable: true,
      expandableOrder: 2,
    },
    {
      title: <RCL searchKey="departureDate" />,
      dataIndex: "deptDate",
      external: true,
      expandable: true,
      expandableOrder: 3,
    },
    {
      title: <RCL searchKey="gateway" />,
      dataIndex: "gateway",
      external: true,
      expandable: true,
      expandableOrder: 4,
    },
    {
      title: <RCL searchKey="destination" />,
      dataIndex: "destination",
      external: true,
      expandable: true,
      expandableOrder: 5,
    },
    {
      title: <RCL searchKey="quantity" />,
      dataIndex: "qty",
      external: true,
      expandable: true,
      expandableOrder: 6,
    },
    {
      title: <RCL searchKey="adult" />,
      dataIndex: "adult",
      external: true,
      expandable: true,
      expandableOrder: 7,
    },
    {
      title: <RCL searchKey="senior" />,
      dataIndex: "senior",
      external: true,
      expandable: true,
      expandableOrder: 8,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {`Agent Portal - ${RCL({ searchKey: "pageStarPointTransaction" })}`}         
        </title>
      </Helmet>
      <Section title={RCL({ searchKey: "pageStarPointTransaction" })} titleAlignment="left" titleSize="h1">
        <div>
          <SortTable
            initialData={transaction}
            config={{
              columns,
              header: {
                left: {
                  component: "component",
                  render: <StarPoints />,
                },
                right: {
                  component: "search",
                  fields: ["bookingNo"],
                  placeholder: bookingSearchLabel,
                },
              },
              externalSource: {
                url: `${config.resourceServer.agentApiUrl}/booking/`,
                key: "bookingId",
                token: oktaAuth.getAccessToken(),
              },
              paginationSize: 15,
            }}
          />
        </div>
      </Section>
    </>
  );
};

export default StarsTransaction;
export { StarsTransaction };
