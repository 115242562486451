import React from 'react'; 
import { RCL } from "../RCL";
import styles from"./FooterVwq.module.scss";

const LogoutFooter = () => {
    return (
        <>
            <div className={styles.logoutFoot}>
                <div className={styles.logoutFootContent}>
                    {RCL({ searchKey: "copyrightFooter"})?.toString().replace("{0}", (new Date()).getFullYear().toString())}
                </div>
                <div className={styles.logoutFootIconContainer}>
                    <img src={"/Icon-feather-globe.png"} alt="logo" />
                </div>
            </div>
        </>
    )
};

export default LogoutFooter;