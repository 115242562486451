import React from "react";
import { Link } from "react-router-dom";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Heading } from "@sunwing/components";
import { replaceRedirectUrl } from "../../utils/replaceRedirectUrl";
import { ContentfulContentEntry } from "../ContentfulContentEntry";
import { ImageWrapper } from "../ImageWrapper";
import { useParagraphEntry } from "../../hooks/contentful/useParagraphEntry";
import config from "../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./ParagraphVwq.module.scss");
  } else {
    styles = await import("./Paragraph.module.scss");
  }
})();

const replaceVariables = (text, userInfo) => {
  let replacedText = text;

  const dictionary = [
    {
      searchTerm: "{{firstName}}",
      replacement: userInfo?.given_name ?? "",
    },
  ];

  dictionary.forEach((item) => {
    replacedText = replacedText.replace(item.searchTerm, item.replacement);
  });

  return replacedText;
};

const ParagraphConnector = ({ data, userInfo, locale }) => {
   const contentfulId = data.sys.id;
   const { block, inline, assets} = useParagraphEntry(contentfulId, locale);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { id } = node?.data?.target?.sys;
        const entry = block?.find((b) => b.sys.id === id);
        return (
          <ContentfulContentEntry
            entry={entry}
            userInfo={userInfo}
            locale={locale}
          />
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const { id } = node?.data?.target?.sys;
        const entry = inline?.find((b) => b.sys.id === id);
        return (
          <ContentfulContentEntry
            entry={entry}
            userInfo={userInfo}
            locale={locale}
          />
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        const url = replaceRedirectUrl(node.data.uri);
        const isInternal = /^(\/|#)(?!\/)/.test(url);

        if (isInternal && url.indexOf("#") !== 0) {
          return (
            <Link className={styles.link} to={node.data.uri}>
              {node.content[0].value}
            </Link>
          );
        } else {
          return (
            <a
              className={styles.link}
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const { id } = node?.data?.target?.sys;
        const image = assets?.block?.find((b) => b.sys.id === id);

        return (image && <ImageWrapper src={image?.url} alt={image?.title} />);
      },
    },
    renderText: (text) =>
      replaceVariables(text, userInfo)
        .split("\n")
        .flatMap((_text, i) => [i > 0 && <br />, _text]),
  };

  return (
    <>
      {styles && (
        <>
          {data.heading && (
            <Heading className={ styles.heading } 
              style={{ textAlign: data.paragraphTextAlignment, color: data?.textColor?? 'black' }}>
              {replaceVariables(data.heading, userInfo)}
            </Heading>
            )}
            {data.subHeading && <p className={ styles.subheading }
                                  style={{ textAlign: data.paragraphTextAlignment, color: data?.textColor?? 'black' }}>
                                    {replaceVariables(data.subHeading, userInfo)}
                                </p>}  
          {data.content && (
            <div style={{ textAlign: data.paragraphTextAlignment, color: data?.textColor?? 'black' }} 
            className={`${styles.richtext} ${ styles.content }`}>
              {documentToReactComponents(data.content.json, options)}
            </div>
          )}
        </>
      )}
    </>
  );
};

export { ParagraphConnector };
