import { CARD_FRAGMENT } from "../fragments/Card.fragment";
import { CARD_COLLECTION_FRAGMENT } from "../fragments/CardCollection.fragment";
import { CLOUDINARY_ASSET_FRAGMENT } from "../fragments/CloudinaryAsset.fragment";
import { COMPONENT_BLOCK_FRAGMENT } from "../fragments/ComponentBlock.fragment";
import { LINK_FRAGMENT } from "../fragments/Link.fragment";
import { PARAGRAPH_FRAGMENT } from "../fragments/Paragraph.fragment";
import { SECTION_FRAGMENT } from "../fragments/Section.fragment";
import { SPLIT_CONTENT_WRAPPER_FRAGMENT } from "../fragments/SplitContentWrapper.fragment";
import { TABS_AND_ACCORDION_FRAGMENT } from "../fragments/TabsAndAccordion.fragment";
import { TEAM_MEMBERS_FRAGMENT } from "../fragments/TeamMembers.fragment";
import { GRID_FRAGMENT } from "../fragments/Grid.fragment";
import { VIDEOCARD_FRAGMENT } from "../fragments/VideoCard.fragment";
import {
  BASE_CARD_FRAGMENT,
  BASE_CARD_COLLECTION_FRAGMENT,
  BASE_COMPONENT_BLOCK_FRAGMENT,
  BASE_LINK_FRAGMENT,
  BASE_TABS_AND_ACCORDION_FRAGMENT,
  BASE_WHITE_SPACE_COMPONENT_FRAGMENT,
  BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT,
  BASE_SECTION_FRAGMENT,
  BASE_CLOUDINARY_ASSET_FRAGMENT,
  BASE_PARAGRAPH_FRAGMENT, 
  
} from "../fragments/BaseContentModelMeta.fragment";

const GET_CONTENT_ENTRY = (id, type) => {
  let TYPE;
  let FRAGMENT;
  let FIELDS;
  let EXTRA_FRAGMENTS = '';
  
  switch (type.toUpperCase()) {
    case "CARD":
      TYPE = "card";
      FRAGMENT = CARD_FRAGMENT;
      FIELDS = "CardFields";
      break;
    case "CARDCOLLECTION":
      TYPE = "cardCollection";
      FRAGMENT = CARD_COLLECTION_FRAGMENT;
      FIELDS = "CardCollectionFields";
      break;
    case "CLOUDINARYASSET":
      TYPE = "cloudinaryAsset";
      FRAGMENT = CLOUDINARY_ASSET_FRAGMENT;
      FIELDS = "CloudinaryAssetFields";
      break;
    case "COMPONENTBLOCK":
      TYPE = "componentBlock";
      FRAGMENT = COMPONENT_BLOCK_FRAGMENT;
      FIELDS = "ComponentBlockFields";
      break;
    case "LINK":
      TYPE = "link";
      FRAGMENT = LINK_FRAGMENT;
      FIELDS = "LinkFields";
      break;
    case "PARAGRAPH":
      TYPE = "paragraph";
      FRAGMENT = PARAGRAPH_FRAGMENT;
      FIELDS = "ParagraphFields";
      break;
    case "SECTION":
      TYPE = "section";
      FRAGMENT = SECTION_FRAGMENT;
      FIELDS = "SectionFields";
      EXTRA_FRAGMENTS = LINK_FRAGMENT + ' ' + 
        COMPONENT_BLOCK_FRAGMENT + ' ' + 
        PARAGRAPH_FRAGMENT + ' ' + 
        SPLIT_CONTENT_WRAPPER_FRAGMENT + ' ' +
        CARD_COLLECTION_FRAGMENT + ' ' +
        TABS_AND_ACCORDION_FRAGMENT + ' ' +
        CARD_FRAGMENT + ' ' + 
        VIDEOCARD_FRAGMENT + ' ' + 
        GRID_FRAGMENT + ' ' + 
        BASE_SECTION_FRAGMENT + ' ' + 
        BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT + ' ' + 
        BASE_WHITE_SPACE_COMPONENT_FRAGMENT + ' ' + 
        BASE_TABS_AND_ACCORDION_FRAGMENT + ' ' +
        BASE_PARAGRAPH_FRAGMENT + ' ' +
        BASE_LINK_FRAGMENT + ' ' +
        BASE_COMPONENT_BLOCK_FRAGMENT + ' ' +
        BASE_CLOUDINARY_ASSET_FRAGMENT + ' ' +
        BASE_CARD_COLLECTION_FRAGMENT + ' ' +
        BASE_CARD_FRAGMENT + ' ' + 
        CLOUDINARY_ASSET_FRAGMENT;
      break;
    case "SPLITCONTENTWRAPPER":
      TYPE = "splitContentWrapper";
      FRAGMENT = SPLIT_CONTENT_WRAPPER_FRAGMENT;
      FIELDS = "SplitContentWrapperFields";
      EXTRA_FRAGMENTS = PARAGRAPH_FRAGMENT + ' ' + 
        CARD_FRAGMENT + ' ' + 
        VIDEOCARD_FRAGMENT + ' ' + 
        CARD_COLLECTION_FRAGMENT + ' ' +
        LINK_FRAGMENT;
      break;
    case "TABSANDACCORDION":
      TYPE = "tabsAndAccordion";
      FRAGMENT = TABS_AND_ACCORDION_FRAGMENT;
      FIELDS = "TabsAndAccordionFields";
      EXTRA_FRAGMENTS = BASE_SECTION_FRAGMENT + ' ' + 
        BASE_SPLIT_CONTENT_WRAPPER_FRAGMENT + ' ' + 
        BASE_WHITE_SPACE_COMPONENT_FRAGMENT + ' ' + 
        BASE_TABS_AND_ACCORDION_FRAGMENT + ' ' +
        BASE_PARAGRAPH_FRAGMENT + ' ' +
        BASE_LINK_FRAGMENT + ' ' +
        BASE_COMPONENT_BLOCK_FRAGMENT + ' ' +
        BASE_CLOUDINARY_ASSET_FRAGMENT + ' ' +
        BASE_CARD_COLLECTION_FRAGMENT + ' ' +
        BASE_CARD_FRAGMENT;
      break;
    case "TEAMMEMBERS":
      TYPE = "teamMembers";
      FRAGMENT = TEAM_MEMBERS_FRAGMENT;
      FIELDS = "TeamMembersFields";
      break;
    case "GRID":
      TYPE = "grid";
      FRAGMENT = GRID_FRAGMENT;
      EXTRA_FRAGMENTS = BASE_SECTION_FRAGMENT ;
      FIELDS = "GridFields";
      break;
    default:
      break;
  }

  if (!TYPE) return;

  return `
  ${EXTRA_FRAGMENTS}
  ${FRAGMENT}
  query ${TYPE}_${id} ($contentfulId: String!, $locale: String!) {
    ${TYPE}(id: $contentfulId, locale: $locale) {
      sys {
        id
      }
      ...${FIELDS}
    }
  }
`;
};

export { GET_CONTENT_ENTRY };
