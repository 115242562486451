import React, { useState } from "react";
import { Button,Modal } from "@sunwing/components";
import { useApp } from "../../hooks";
import config from "../../app/config";
import replaceRedirectUrl from "../../utils/replaceRedirectUrl";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./LinkVwq.module.scss");
  } else {
    styles = await import("./Link.module.scss");
  }
})();

const LinkConnector = ({ data, userInfo, locale }) => {
  const { MBPRedirectUrl, IGQRedirectUrl } = useApp();

  const ButtonProps = {
    theme:config.isWestJetMode ? "primary" : (data.variant === "default" ? null : "primary"),
    outline: data.variant.includes("outline"),
  };

  const LinkModal = ({
    children,
    externalModalMessage,   
    className,
    onOpenModal,   
  }) => {
    const [isModalOpen, setModalIsOpen] = useState(false);
    return (
      <>
        {styles && 
          (<>
            <button
              type="button"
              className={className}          
              onClick={() => {
                setModalIsOpen(true);
                if (onOpenModal) onOpenModal();
              }}>
              {children}
            </button>
            <Modal
              contentLabel="content details"
              container="fluid"
              className={styles.modal}
              isOpen={isModalOpen}
              labels={{ close: "X" }}
              simple={true}
              top={false}
              onClose={() => {
                setModalIsOpen(false);
              }}>
              <Modal.Content className={styles.modalContent}>
                <div className={styles.modalContentInner}>
                  <p className={styles.modalContentMessage}>{externalModalMessage}</p>              
                </div>
              </Modal.Content>
            </Modal>
          </>)}
      </>
    );
  };

  return (
    <>
        {styles && 
          <Button
            className={styles.button}
            label={data.linkText}
            target={data.target}
            to={replaceRedirectUrl(data.url, MBPRedirectUrl, IGQRedirectUrl)}
            size={data.size}
            icon="arrow-right"
            iconPlacement="right"
            
            {...ButtonProps}
            linkComponentProps={{
              renderLink: (link) => {
                if (link.isInternal && link.to.indexOf("#") !== 0) {
                  return (
                    <a href={link.to} target={link.target} className={link.className}>
                    {link.children}
                  </a>
                  );
                }
                else if(link.target === "EXTERNAL_MODAL")
                {            
                  return (
                    <LinkModal
                      externalModalMessage={data.externalModalMessage}                
                      className={link.className}
                      onOpenModal={false}               
                      >
                      {link.children}
                    </LinkModal>
                  );            
                }
                return (
                  <a href={link.to} target={link.target} className={link.className}>
                    {link.children}
                  </a>
                );
              },
            }}
          />
        }
    </>
  );
};

export { LinkConnector };
