import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Cell, SecondaryCard, Section } from "@sunwing/components";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL } from "../../components/RCL";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useRouting } from "../../hooks";
import {
  cloudinaryImage,
  DEFAULT_WIDTH_TRANSFORMATION,
} from "../../utils/cloudinary";
import { Loader } from "../../components/Loader";
import { HorizontalCard } from "../../components/HorizontalCard";
import styles from "./agentTools.module.scss";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";

const AgentTools = () => {
  const { oktaAuth } = useOktaAuth();
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const [agentTools, setAgentTools]  = useState([]);
  
  const getAgentTools = async () => {
    let query = `
      query agentToolsData($locale: String!) {
        agentToolsCollection(locale: $locale) {
          items {
            sys {
              id
            }
            agentToolTitle
            agentToolHeader
            agentToolDescription
            agentToolUrl
            agentToolLinkText
            agentToolImageAsset
            priorityOrder
            isHorizontalCard
          }
        }
      }
    `;

    await fetch(
      config.resourceServer.contentfulApimAgentGqlUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify({
          query: query,
          variables: { locale: locale },
        }),
      }
    )
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setAgentTools(data?.agentToolsCollection?.items);
    });   
  }

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/agentTools" },
        fr: { path: "/fr/agentTools" },
      });
      getAgentTools();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <div>
      <Helmet>
        <title>
          {`Agent Portal - ${RCL({ searchKey: "AgentTools_Header" })}`}
        </title>
      </Helmet>
      {(
       <Breadcrumb pageSlug={"all-agent-tools"} locale={locale} />
      )}
      <Section
        title={<RCL searchKey="AgentTools_Header" />}
        titleAlignment="left"
        padding="bottom"
        titleUppercase={false}
      >
        <RCL searchKey="AgentTools_Desc" />
      </Section>
      <>
        {!agentTools && <Loader />}
        {!!agentTools?.length && (
          <>
            <Section padding="bottom">
              {agentTools
                .slice()
                .sort(function (a, b) {
                  return a.priorityOrder - b.priorityOrder;
                })
                .filter((agttools1) => agttools1.isHorizontalCard === true)
                .map((agenttool1) => (
                  <HorizontalCard
                    className={styles.agentToolsHorizontalCard}
                    header={agenttool1.agentToolHeader}
                    content={agenttool1.agentToolDescription}
                    image={{
                      alt: "placeholder alt",
                      src: cloudinaryImage(
                        agenttool1?.agentToolImageAsset?.[0]?.public_id,
                        DEFAULT_WIDTH_TRANSFORMATION
                      ),
                    }}
                    button={{
                      label: agenttool1.agentToolLinkText,
                      target: "_self",
                      size: "md",
                      text: agenttool1.agentToolLinkText,
                      to: agenttool1.agentToolUrl,
                    }}
                  />
                ))}
            </Section>
            <Section>
              <Row cols={3}>
                {agentTools
                  .slice()
                  .sort(function (a, b) {
                    return a.priorityOrder - b.priorityOrder;
                  })
                  .filter((agttools) => agttools.isHorizontalCard !== true)
                  .map((agenttool) => (
                    <Cell key={agenttool.agentToolTitle}>
                      <SecondaryCard
                        className={styles.agentToolCards}
                        header={agenttool.agentToolHeader}
                        image={{
                          alt: "placeholder alt",
                          src: cloudinaryImage(
                            agenttool?.agentToolImageAsset?.[0]?.public_id,
                            DEFAULT_WIDTH_TRANSFORMATION
                          ),
                        }}
                        link={{
                          target: "_self",
                          text: agenttool.agentToolDescription,
                          to: agenttool.agentToolUrl,
                        }}
                      />
                    </Cell>
                  ))}
              </Row>
            </Section>
          </>
        )}
      </>
      </div>
    </>
  );
};

export default AgentTools;
