
import React, {useState,useEffect,useContext} from "react";
import { Helmet } from 'react-helmet';
import { Section, HeroCard } from "@sunwing/components";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import OktaSignUpWidget from "./OktaSignUpWidget";
import AgencyValidation from "./AgencyValidation";
import styles from "./signup.module.scss";
import { RCLContext } from "../../components/RCL/RCLProvider";
import config from "../../app/config";

// eslint-disable-next-line
const SignUp = ({ setCorsErrorModalOpen }) => {
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();

  const featureHighlights = [
    "Homepage_highlight1",
    "Homepage_highlight3",
    "Homepage_highlight4",
  ];

  const [agency,setAgency]=useState(null); 
  const [regions,setRegions]=useState(null);
  const getRegions =(lang) => {
    fetch(`${config.resourceServer.agentApiUrl}/opn/content/region?lang=${lang}`, {
      method: "GET",         
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setRegions(data);        			
      });
  };

  useEffect(() => {        
  getRegions(locale);         
  }, [locale]); 


  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/signup" },
        fr: { path: "/fr/signup" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
    <Helmet>
      <title>
        {`Agent Portal - Signup`}         
      </title>
    </Helmet>
    <div  className={styles.loginContainer}>
      <Section className={styles.loginSection}>
        <div className={styles.login}>
          <div className={styles.loginInfo}>
            <HeroCard
              header={RCL({
                searchKey: "Homepage_Title",
              })}
              backgroundColor={null}
              color="#FFFFFF"
              uppercase={false}
            />
            <div className={styles.loginInfoFeatures}>
              {featureHighlights.map((highlight) => (
                <div className={styles.loginInfoFeatureItem}>
                  <div>
                  <div className={styles.loginInfoFeaturestitle}>
                      {RCL({
                        searchKey: highlight + "_title"
                      })}
                    </div>
                    <div className={styles.featureBullet}>
                      {RCL({
                        searchKey: highlight,
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {
            (agency===null ||(agency && agency.name===null)) && <div>
            <AgencyValidation agency={agency} setAgency={setAgency}/>         
          </div>}    
          {(agency && agency.name!==null) && <div className={styles.oktaWidget}>
            <OktaSignUpWidget agency={agency} regions={regions} setCorsErrorModalOpen={setCorsErrorModalOpen} />              
          </div>}
        </div>
      </Section>
    </div>
    </>
  );
};
export default SignUp;
