import { FluidSection } from "../../FluidSection";
import { Heading } from "@sunwing/components";
import styles from "./ColumnSection.module.scss";
import config from '../../app/config';

const ColumnSectionConnector = ({ data, userInfo, locale }) => (
  <>
    <Heading as="h4" className= {config.isWestJetMode ? styles.heading : "" } >{data.displayTitle}</Heading>
    <FluidSection
      sections={data.entriesCollection.items}
      userInfo={userInfo}
      locale={locale}
      isNested={true}
      nestedMargin={false}
    />
  </>
);

export { ColumnSectionConnector };
