import React, { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import config from "../../app/config";
const RCLContext = createContext("en");

const RCLProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["nf_lang"]);
  const [dictionary, setDictionary] = useState({});
  const [locale, setLocale] = useState(cookies.nf_lang ?? "en");
  const [lang, setLang] = useState(cookies.nf_lang ?? "en");

  const handleSetLocale = (locale) => {
    let _lang = "en";

    if (locale.substring(0, 2) === "fr") {
      _lang = "fr";
    }

    setLocale(_lang);
    setLang(_lang);
    setCookie("nf_lang", _lang, { path: "/" });
  };

  const getRCLData = async (rclURL) => {
    fetch(rclURL)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {

        let parsedDictionary = { ...dictionary };
        data.items.forEach((item) => {
          const entryLang = item?.sys?.locale.substring(0, 2);

          if (!parsedDictionary[entryLang]) {
            parsedDictionary[entryLang] = {};
          }

          parsedDictionary[entryLang][item?.fields?.key] = {
            parseAsMarkdown: item?.fields?.parseAsMarkdown,
            value: item?.fields?.value,
          };
        });
        setDictionary(parsedDictionary);
      })
      .catch((err) => {
        console.error("Error on Fetch RCL Data:", err);
      });
  };

  // fetch messages
  useEffect(() => {
    if (!dictionary[locale]) {
      const rclUrl =
        `${config.resourceServer.contentfulApimOpenRclUrl}` +
        "&locale=" +
        `${locale.substring(0, 2) === "en" ? "en-US" : locale.substring(0, 2)}`;
      getRCLData(rclUrl);
    }
  }, [locale, dictionary]);

  return (
    <RCLContext.Provider
      value={{
        locale: locale.substring(0, 2),
        lang,
        setLocale: handleSetLocale,
        dictionary,
      }}
    >
      {children}
    </RCLContext.Provider>
  );
};

export default RCLProvider;
export { RCLProvider, RCLContext };
