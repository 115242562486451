import { useEffect, useState } from "react";
import config from "../app/config";
import { useOktaAuth } from "@okta/okta-react";
import { GET_TEAMMEMBER_ENTRY } from "../query/teamMember.query";

export const useTeamMember = (contentfulId, locale) => {
  const { oktaAuth } = useOktaAuth();
  const [blockEntry, setBlock] = useState([]);
  const [inlineEntry, setInline] = useState([]);
  const [assetsEntry, setAssets] = useState({});

  const getTeamMember = async () => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: GET_TEAMMEMBER_ENTRY(contentfulId),
        variables: { contentfulId : contentfulId, locale: locale},
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
          setBlock(data?.teamMember?.details?.links?.entries.block);
          setInline(data?.teamMember?.details?.links?.entries.inline);
          setAssets(data?.teamMember?.details?.links?.assets);

      });
  };

  useEffect(() => {
    getTeamMember();
  }, [contentfulId, locale]);

  return { blockEntry, inlineEntry, assetsEntry};
};
