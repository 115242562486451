const LINK_FRAGMENT = `
  fragment LinkFields on Link {
    typename
    sys {
      id
    }
    variant
    size
    linkText
    url
    target
    image
    imageAltText
    iconName
    externalModalMessage
  }
`;

export { LINK_FRAGMENT };
