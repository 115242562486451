import { RCL } from "../../components/RCL";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { useState, useEffect, useContext } from "react";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";
import { Button } from "@sunwing/components/dist/Button";
import VoucherDetails from "./VoucherDetails";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./PlatinumClubVwq.module.scss");
  } else {
    styles = await import("./PlatinumClub.module.scss");
  }
})();

const PlatinumClub = ({ platinumClubInfo,toggleFAQModal,isFAQModalOpen }) => {
    const [showVoucherInfo, setShowVoucherInfo] = useState(false);
    const [voucherInfo, setVoucherInfo] = useState(null);
    const [agentVoucherList, setAgentVoucherList] = useState(null);
    const { authState, oktaAuth } = useOktaAuth();
    const [tierData] = useState(RCL({ searchKey: "platinum-club-tier-data" }));
    const { locale } = useContext(RCLContext);
   

    const Group_Sales_Platinium_Club_Notes_Link_Terms_And_Conditions  = 
    locale === "fr"
    ? "https://assets.ctfassets.net/bjgonf6fglpm/7lU9uWN4nl0B0LXjqpD3qb/e9635eb49d667ce1f4213857f70597af/Platinum_Club_T_C__French_.pdf"
    : "https://assets.ctfassets.net/bjgonf6fglpm/7lU9uWN4nl0B0LXjqpD3qb/837b80f30280922045f9759ce886edd3/Platinum_Club_T_C__English_.pdf";
    

    const Group_Sales_Platinium_Club_Notes_Link_FAQs = "#";
 
    const getVoucherInfo = async (token, voucherCode) => {
        await fetch(`${config.resourceServer.voucherApiUrl}/v1/GetVoucher/${voucherCode}/true`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {       
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
        })
        .then((data) => {
            setVoucherInfo(data);
            setShowVoucherInfo(true);
        });
    };

    const getAgentVoucherList = async (token, globalId) => {
        await fetch(`${config.resourceServer.voucherApiUrl}/v1/GetVouchers?GlobalID=${globalId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {       
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
        })
        .then((data) => {
            setAgentVoucherList(data);
        });
    };

    const handleOnClick = (voucherCode) => {
        getVoucherInfo(oktaAuth.getAccessToken(), voucherCode);
    };
      
    const closePopupHandler = async () => {
    setShowVoucherInfo(false);
    }

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setAgentVoucherList(null);
        } else {
          oktaAuth.getUser().then((info) => {
            getAgentVoucherList(oktaAuth.getAccessToken(), platinumClubInfo.globalId);
          });
        }
      }, [authState, oktaAuth, platinumClubInfo]);

    return (
        <>
         {styles && (
            <>
                {platinumClubInfo?.agentVouchers.length === 0 &&
                    <div>
                        <div>
                            <h2><RCL searchKey="join-our-sunwing-platinum-club" /></h2>
                        </div>
                        <div>
                            <RCL searchKey="join-our-sunwing-platinum-club-description" />
                        </div>
                    </div>
                }
                {platinumClubInfo?.agentVouchers.length > 0 && (
                <>
                <div className={styles.platinumClubContainer}>
                    <div>
                        <b>{RCL({ searchKey: "tier-reward-details"})?.replace("{0}", platinumClubInfo?.tier)} </b>
                    </div>
                    <div>
                        <table className={styles.platinumClubRewardDetails}>
                            <thead>
                                <tr>
                                    <td><RCL searchKey="total-group-bookings" /></td>
                                    <td><RCL searchKey="travel-vouchers" /></td>
                                    <td><RCL searchKey="group-booking-vouchers" /></td>
                                    <td><RCL searchKey="elite-plus-upgrade" /></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ platinumClubInfo?.tier === 1? tierData.split('/')[0].split(':')[0] + '+'
                                        : platinumClubInfo?.tier === 2?tierData.split('/')[1].split(':')[0] + '+' 
                                        :tierData.split('/')[2].split(':')[0] + '+' }</td>
                                    <td>{ platinumClubInfo?.tier === 1? tierData.split('/')[0].split(':')[1]
                                        : platinumClubInfo?.tier === 2?tierData.split('/')[1].split(':')[1] 
                                        :tierData.split('/')[2].split(':')[1] }</td>
                                    <td>{ platinumClubInfo?.tier === 1? tierData.split('/')[0].split(':')[2]
                                        : platinumClubInfo?.tier === 2?tierData.split('/')[1].split(':')[2] 
                                        :tierData.split('/')[2].split(':')[2] }</td>
                                    <td>{ platinumClubInfo?.tier === 1? tierData.split('/')[0].split(':')[3]
                                        : platinumClubInfo?.tier === 2?tierData.split('/')[1].split(':')[3] 
                                        :tierData.split('/')[2].split(':')[3] }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.notesP}>
                        <p>
                        <RCL searchKey="Group_Sales_Platinium_Club_Notes_Part_1"/>&nbsp;
                        <a href={Group_Sales_Platinium_Club_Notes_Link_Terms_And_Conditions} className={styles.notesLink} target="_blank" rel="noreferrer"><RCL searchKey="Group_Sales_Platinium_Club_Notes_Part_2"/></a>&nbsp;
                        <RCL searchKey="Group_Sales_Platinium_Club_Notes_Part_3"/>&nbsp;
                        <a href={Group_Sales_Platinium_Club_Notes_Link_FAQs} className={styles.notesLink} onClick={toggleFAQModal}><RCL searchKey="Group_Sales_Platinium_Club_Notes_Part_4"/></a>.&nbsp;
                        <RCL searchKey="Group_Sales_Platinium_Club_Notes_Part_5"/>
                        </p>
                        {/* <RCL searchKey="travel-agent-rewards-notes" /> */}
                    </div>

                    <div>
                        {["TravelVoucher", "GroupBookingVoucher"].map(voucherType => {
                            return platinumClubInfo?.agentVouchers.some(e => e.voucherType === voucherType) && (
                            <>
                            <br></br>
                            <div>
                                {voucherType === "TravelVoucher" ? <RCL searchKey="travel-vouchers" /> : <RCL searchKey="group-booking-vouchers" />}
                            </div>
                            <table className={styles.platinumClubTable}>
                                <thead>
                                    <tr>
                                    <td>{voucherType === "TravelVoucher" ? <RCL searchKey="travel-vouchers" /> : <RCL searchKey="group-booking-vouchers" />}</td>
                                    <td><RCL searchKey="voucher-amount" /></td>
                                    <td>{voucherType === "TravelVoucher" ? <RCL searchKey="voucher-code" />: <RCL searchKey="credit-code" />}</td>
                                    <td><RCL searchKey="issued-on" /></td>
                                    <td><RCL searchKey="expiry" /></td>
                                    <td><RCL searchKey="balance-amount" /></td>
                                    <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {platinumClubInfo?.agentVouchers.filter(voucher => voucher.voucherType === voucherType)
                                    .map((voucher, index) => (                      
                                        <tr key={index}>
                                            <td>{voucher.voucherType === "TravelVoucher" ? RCL({ searchKey:"travel-voucher"}) + ' ' + (index + 1): RCL({ searchKey:"group-booking-voucher"}) + ' ' + (index + 1)} </td>
                                            <td>{'$'}{voucher.amount}</td>
                                            <td>{voucher.voucherCode}</td>
                                            <td>{voucher.created.split('T')[0]}</td>
                                            <td>{voucher.expiryDate.split('T')[0]}</td>
                                            <td>{'$'}{agentVoucherList?.vouchers?.find(v => v.voucherCode === voucher.voucherCode).remainingAmount?agentVoucherList?.vouchers?.find(v => v.voucherCode === voucher.voucherCode).remainingAmount : '0'}</td>
                                            <td><Button onClick={(event) => handleOnClick(voucher.voucherCode)} ><RCL searchKey="view-agent" /></Button></td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                            </>
                            )
                        })
                        }
                    </div>
                </div>
                </>
                )}
                {showVoucherInfo && (<VoucherDetails voucherInfo={voucherInfo} closePopupHandler={closePopupHandler}/>)}
            </>)}
        </>
      )
}

export default PlatinumClub;
export { PlatinumClub };