import React, { useEffect } from "react";
import { Section } from "@sunwing/components";
import { useRouting } from "../../hooks";
import OktaSignInWidget from "./OktaSignInWidget";
import styles from "./login.module.scss";

// eslint-disable-next-line
const GenericLogin = ({ setCorsErrorModalOpen }) => {
  const { setCurrentRoute } = useRouting();

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/login" },
        fr: { path: "/fr/login" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
       {/*<div className={styles.login}>
        <div className={styles.loginInfo}>
          <HeroCard
            image={{
              src: RCL({
                searchKey: "Homepage_image",
              }),
              alt: "",
            }}
            header={RCL({
              searchKey: "Homepage_Title",
            })}
            backgroundColor="#00A887"
            color="#FFFFFF"
          />
          <div className={styles.loginInfoFeatures}>
            {featureHighlights.map((highlight) => (
              <div className={styles.loginInfoFeatureItem}>
                <div className={styles.featureIcon}>
                  <Icon
                    className={styles.svgIcon}
                    name="star"
                    aria-hidden="true"
                  />
                </div>
                <div className={styles.featureBullet}>
                  <div>
                    {RCL({
                      searchKey: highlight,
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.oktaWidget}>
          <OktaSignInWidget setCorsErrorModalOpen={setCorsErrorModalOpen} />
        </div>
      </div>*/}
       <div className={styles.oktaWidget}>
          <OktaSignInWidget setCorsErrorModalOpen={setCorsErrorModalOpen} />
        </div>
    </Section>
  );
};
export default GenericLogin;
