/* eslint-disable no-lone-blocks */
import styles from "./WebPush.module.scss";
import { Image } from "antd";
import { Icon } from "@sunwing/components";

const getOS = () => {
  const userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

const WebPushPopup = ({ notification, handleWebPushClose }) => {
  let os = getOS();

  return (
    <>
        {notification.redirectUrl && notification.redirectUrl !== '' ? 
          (<>
            <a href={notification.redirectUrl } target="_blank" rel="noreferrer">
              <div className={`${styles.popupMainContainer} ${os === 'Windows'?styles.windowPosition:styles.otherOsPosition}`}>
                <div className={styles.logoContainer}>
                  <Image
                      src={'https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg'}
                      alt=""
                      className={styles.logoImage}>
                    </Image>
                  </div>
                <div className={styles.contentContainer}>
                  <div>
                    <span  className={styles.contentHeader}>
                          {notification.title.length > 20 ? 
                              notification.title.substring(0, 20) + "..." : 
                              notification.title
                          }
                        </span>
                      <div className={styles.closeIcon}>
                        <button onClick={() => handleWebPushClose()} className={styles.closeButton}>
                          <Icon name="close--n"/>
                        </button>
                      </div>
                  </div>
                  <span className={styles.contentBody}
                    dangerouslySetInnerHTML={{__html: 
                    `${notification.body.length > 50 ? 
                          notification.body.substring(0, 50) + "..." : 
                          notification.body
                    }`}}>
                  </span>
                </div>
              </div>
            </a>
          </>):
          (<> 
            <div className={`${styles.popupMainContainer} ${os === 'Windows'?styles.windowPosition:styles.otherOsPosition}`}>
              <div className={styles.logoContainer}>
                <Image
                    src={'https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg'}
                    alt=""
                    className={styles.logoImage}>
                  </Image>
              </div>
              <div className={styles.contentContainer}>
                <div>
                  <span  className={styles.contentHeader}>
                        {notification.title.length > 20 ? 
                            notification.title.substring(0, 20) + "..." : 
                            notification.title
                        }
                      </span>
                    <div className={styles.closeIcon}>
                      <button onClick={() => handleWebPushClose()} className={styles.closeButton}>
                        <Icon name="close--n"/>
                      </button>
                    </div>
                </div>
                <span className={styles.contentBody}
                  dangerouslySetInnerHTML={{__html: 
                  `${notification.body.length > 50 ? 
                        notification.body.substring(0, 50) + "..." : 
                        notification.body
                  }`}}>
                </span>
              </div>
            </div>
          </>)}
      </>
  );
};

export default WebPushPopup;