
import { useEffect, useState } from "react";
import styles from "./CustomPagination.module.scss";
import { RCL } from "../../components/RCL";


const CustomPagination = ({ componentId, getTotalPages, activePage, handleRightPaging, handleLeftPaging, goToPage, leftMoveDisabled, rightMoveDisabled }) => {

    const [totalPages, setTotalPages] = useState(0);


    useEffect(() => {
        setTimeout(() => {
            setTotalPages(getTotalPages(componentId));
        }, 1000);
    }, [])
    return (
        <div className={styles.paginationContainer}>
            {totalPages > 0 && <div className={styles.pagination}>
                <button disabled ={leftMoveDisabled} onClick={() => handleLeftPaging(componentId)} className={`${styles.paginationElement} ${styles.move}`}> &lt; <RCL searchKey="back" /></button>
                {Array.from({ length: totalPages }).map((_, i) => {
                    return (
                        <>
                            <div onClick={() => { goToPage(componentId, (i + 1)) }} className={`${activePage == (i + 1) && styles.activeElement} ${styles.paginationElement}`}> <span>{i + 1}</span> </div>
                        </>
                    );
                })
                }
                <button disabled ={rightMoveDisabled} onClick={() => handleRightPaging(componentId)} className={`${styles.paginationElement} ${styles.move}`}><RCL searchKey="next" /> &gt; </button>
            </div>}
        </div>
    );
};
export default CustomPagination;
export { CustomPagination };
