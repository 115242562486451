const TABS_AND_ACCORDION_ITEMS_FRAGMENT = `
  fragment TabsAndAccordionItemFields on TabsAndAccordionItem {
    sys {
      id
    }
    key
    heading
    subheading
    icon
    tag
    image
    contentCollection {
      items {
        ...BaseCardMeta
        ...BaseCardCollectionMeta
        ...BaseCloudinaryAssetMeta
        ...BaseComponentBlockMeta
        ...BaseLinkMeta
        ...BaseParagraphMeta
        ...BaseTabsAndAccordionMeta
        ...BaseWhiteSpaceComponentMeta
        ...BaseSplitContentWrapperMeta
        ...BaseSectionMeta
      }
    }
  }
`;

const TABS_AND_ACCORDION_FRAGMENT = `
  ${TABS_AND_ACCORDION_ITEMS_FRAGMENT}
  fragment TabsAndAccordionFields on TabsAndAccordion {
    typename
    sys {
      id
    }
    type
    variantTabs
    variantAccordion
    activeKey
    itemsCollection(limit: 20) {
      items {
        ...TabsAndAccordionItemFields
      }
    }
  }
`;

export { TABS_AND_ACCORDION_FRAGMENT };
