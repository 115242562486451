const SLIDE_OUT_PANEL_FRAGMENT = `
  fragment SlideOutPanelFields on SlideOutPanel {
    typename
    sys {
      id
    }
    displayTitle
    componentsCollection(limit: 5) {
      items {
        ...BaseCardMeta
        ...BaseCardCollectionMeta
        ...BaseComponentBlockMeta
        ...BaseLinkMeta
        ...ParagraphFields
        ...BaseTabsAndAccordionMeta
        ...BaseWhiteSpaceComponentMeta
        ...BaseSplitContentWrapperMeta
        ...BaseSectionMeta
        ...CloudinaryAssetFields
      }
    }
  }
`;

export { SLIDE_OUT_PANEL_FRAGMENT };
