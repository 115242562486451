// To be depreciated and replaced with Sunwing Components table once ready
import React from "react";
import cx from "classnames";
import { Icon } from "@sunwing/icons/dist/sunwing";
import { LinkConnector } from "../Link";
import styles from "./Table.module.scss";

// eslint-disable-next-line react/prop-types
const ListItem = ({ text }) => (
  <div className={styles.listItem}>
    <Icon name="checked--n" className={styles.listItemCheck} />
    {text}
  </div>
);

const Cell = ({ content, config }) => {
  const type = typeof content;
  switch (type) {
    case "boolean":
      return content ? (
        <Icon name="checked--n" />
      ) : (
        <Icon name={config?.boolIcon || "minus--n"} />
      );
    case "object":
      if (content.type === "button") {
        return (
          <LinkConnector
            data={{
              linkText: content.text,
              url: content.to,
              target: content.target,
              variant: "primary--outline",
            }}
          />
        );
      }
      if (content.type === "link") {
        return (
          <LinkConnector
            data={{
              linkText: content.text,
              url: content.to,
              target: content.target,
              variant: "primary--outline",
            }}
          />
        );
      }
      if (content.type === "list") {
        return content.list.map((i) => <ListItem key={i} text={i} />);
      }
      if (content.type === "string") {
        return content.text;
      }
      return content.text;
    default:
      return content;
  }
};
const TableConnector = ({ data }) => {
  const { headers, rows, subtext, headerStyle, tableStyle, className, config } =
    data;

  return (
    <div
      className={cx(styles.tableContainer, className)}
      style={{ overflowX: "auto" }}
    >
      <table className={styles.table} style={tableStyle}>
        <tr>
          {headers?.map((i, index) => (
            <th
              key={i.title}
              className={cx(
                styles.header,
                { [styles.sticky]: index === 0 && config?.stickyIndex },
                {
                  [styles.background]: i.background || headerStyle?.background,
                },
                { [styles.bold]: i.bold || headerStyle?.bold }
              )}
              style={{ ...i, background: i?.columnBackground }}
            >
              {i.title}
              <span>{i.subTitle}</span>
            </th>
          ))}
        </tr>
        {rows?.map((r) => (
          <tr key={r.title}>
            {r.columns?.map((c, index) =>
              c !== null ? (
                <td
                  colSpan={c?.colSpan}
                  rowSpan={c?.rowSpan}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={cx(
                    styles.cell,
                    { [styles.sticky]: index === 0 && config?.stickyIndex },
                    { [styles.background]: r.background || c.background },
                    {
                      [styles.bold]:
                        headers[index]?.bold || r.bold || c.bold === true,
                    },
                    {
                      [styles.small]:
                        headers[index]?.small || r.small || c.small === true,
                    }
                  )}
                  style={{
                    textAlign: headers[index]?.textAlign || c.textAlign,
                    verticalAlign:
                      headers[index]?.verticalAlign || c.verticalAlign,
                    background: headers[index]?.columnBackground,
                  }}
                >
                  <Cell content={c} config={config} />
                </td>
              ) : (
                <></>
              )
            )}
          </tr>
        ))}
      </table>
      {/* eslint-disable-next-line react/no-danger */}
      {subtext && (
        <div
          className={styles.subtext}
          dangerouslySetInnerHTML={{ __html: subtext }}
        />
      )}
    </div>
  );
};

export default TableConnector;
export { TableConnector };
