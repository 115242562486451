import { createContext, useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import CONFIG from "../app/config";
import { RCLContext } from "../components/RCL/RCLProvider";

const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();

  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [IGQRedirectUrl, setIGQRedirectUrl] = useState(null);
  const [MBPRedirectUrl, setMBPRedirectUrl] = useState(null);
  const { locale } = useContext(RCLContext);
  const [inboxMessages, setInboxMessages] = useState(null);

  const getRedirectLinks = (token, type) => {
    fetch(
      `${CONFIG.resourceServer.agentApiUrl}/redirect/link?type=${type}&lang=${locale}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.text();
      })
      .then((text) => {
        if (type === "mbpsso") setMBPRedirectUrl(text);
        if (type === "igq") setIGQRedirectUrl(text);
        if (type === "mbpvwqsso") setMBPRedirectUrl(text);
        if (type === "igqvwq") setIGQRedirectUrl(text);
      });
  };

  const refreshUserInfo = (updatedUserInfo) => {
    setUserInfo({
      ...userInfo,
      ...updatedUserInfo,
    });
  };

  const getInboxMessage = (token) => {
    fetch(`${CONFIG.resourceServer.customerApiUrl}/notifications/inbox/messages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject();
      }else{
        return response.json();
      }
    })
    .then((data) => {
      setInboxMessages(data);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
      setLoading(false);
    } else {
      setLoading(false);
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        const accessToken = oktaAuth.getAccessToken();
        if(CONFIG.isWestJetMode){
          getRedirectLinks(accessToken, "mbpvwqsso");
          getRedirectLinks(accessToken, "igqvwq");
        }else {
          getRedirectLinks(accessToken, "mbpsso");
          getRedirectLinks(accessToken, "igq");
        };

        getInboxMessage(accessToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth, locale]);

  return (
    <AppContext.Provider
      value={{
        loading,
        userInfo,
        refreshUserInfo,
        IGQRedirectUrl,
        MBPRedirectUrl,
        inboxMessages
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = () => {
  const { loading, userInfo, refreshUserInfo, IGQRedirectUrl, MBPRedirectUrl, inboxMessages } =
    useContext(AppContext);

  return {
    loading,
    userInfo,
    refreshUserInfo,
    IGQRedirectUrl,
    MBPRedirectUrl,
    inboxMessages
  };
};

export default useApp;
export { useApp, AppProvider };
