import { useState, useEffect } from "react";
import marked from "marked";
import { NewsFeed as NewsFeedComponent } from "@sunwing/components";
import { RCL as useTranslation } from "../RCL";
import { Loader } from "../Loader";
//import styles from "./NewsFeed.module.scss";
import config from '../../app/config';

let styles;
(async () => {
  if (config.isWestJetMode) {
    styles = await import("./NewsFeed.module.scss");
  }
})();

const NewsFeed = ({ locale }) => {
  const [newsItems, setNewsItems] = useState();

  const headingLabel = useTranslation({ searchKey: "latest-news" });

  useEffect(() => {
    fetch("https://infoservice.sunwingtravelgroup.com/Contentful", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        lang: [`${locale.substring(0, 2)}`],
        spaceid: "pcepxxr6a1mk",
        multivalueconditions: [
          { "brandsarr.brands.keyword": ["Sunwing Agents"] },
        ],
        contenttype: ["newsContainer"],
        sort: [{ postDate: { order: "desc" } }],
        rangeconditions: [
          { postDate: { gte: "1990-01-01" } },
          { postDate: { lte: "2100-12-31" } },
        ],
        size: 3,
        docfrom: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        setNewsItems(
          res.items.map((item) => ({
            id: item.entryid,
            title: item.subjectTitle,
            postDate: item.postDate,
            featuredImage: {
              alt: item.featuredImageref?.title,
              src: item.featuredImageref?.file?.url,
            },
            content: (
              <div dangerouslySetInnerHTML={{ __html: marked(item.body) }} />
            ),
          }))
        )
      )
      .catch((err) => console.error(err));
  }, []);

  if (!newsItems) return <Loader />;

  return (
    <NewsFeedComponent
      lang={locale.substring(0, 2)}
      heading={headingLabel}
      items={newsItems}
      className={config.isWestJetMode ? styles.newfeedVwq:""}
    />
  );
};

export default NewsFeed;
export { NewsFeed };
