import cloudinary from "cloudinary/lib/v2";

cloudinary.config({
  cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
  secure: true,
});

const DEFAULT_WIDTH_TRANSFORMATION = {
  width: 747,
};

const cloudinaryImage = (publicId, transformation) =>
  cloudinary.url(publicId, {
    quality: "auto",
    fetch_format: "auto",
    ...transformation,
  });

export default cloudinaryImage;
export { cloudinaryImage, DEFAULT_WIDTH_TRANSFORMATION };
