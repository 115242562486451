import React, { useEffect } from "react";
import { Section, HeroCard } from "@sunwing/components";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import OktaSignInWidget from "./OktaSignInWidget";
import styles from "./login.module.scss";

// eslint-disable-next-line
const Login = ({ setCorsErrorModalOpen }) => {
  const { setCurrentRoute } = useRouting();

  const featureHighlights = [
    "Homepage_highlight1",
    "Homepage_highlight3",
    "Homepage_highlight4",
  ];

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/login" },
        fr: { path: "/fr/login" },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div  className={styles.loginContainer}>
      <Section className={styles.loginSection} >
        <div className={styles.login} >
          <div className={styles.loginInfo}>
            <HeroCard
              header={RCL({
                searchKey: "Homepage_Title",
              })}
              color="#FFFFFF"
              backgroundColor={null}
              uppercase={false}
            />
            <div className={styles.loginInfoFeature}>
              {featureHighlights.map((highlight) => (
                <div className={styles.loginInfoFeatureItem}>
                  <div>
                    <div className={styles.loginInfoFeatureTitle}>
                      {RCL({
                        searchKey: highlight + "_title"
                      })}
                    </div>
                    <div>
                      <p className={styles.loginInfoFeatureContext}>{RCL({
                        searchKey: highlight,
                      })}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.oktaWidget}>
            <OktaSignInWidget setCorsErrorModalOpen={setCorsErrorModalOpen}/>
          </div>
        </div>
      </Section>
    </div>
  );
};
export default Login;
