/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import config from "../app/config";
import { useApp } from "./useApp";

const RoutingContext = createContext({
  contentfulId: undefined,
  lang: undefined,
});

const RoutingProvider = ({ children }) => {
  const [pageContentfulId, setPageContentfulId] = useState();
  const [allRoutes, setAllRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    if (allRoutes) {
      setCurrentRoute(
        allRoutes[`_${pageContentfulId}`] ?? {
          en: { path: "/en" },
          fr: { path: "/fr" },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContentfulId]);

  return (
    <RoutingContext.Provider
      value={{
        pageContentfulId,
        setPageContentfulId,
        allRoutes,
        setAllRoutes,
        loading,
        setLoading,
        currentRoute,
        setCurrentRoute,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
};

const useRouting = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const {
    setPageContentfulId,
    allRoutes,
    setAllRoutes,
    loading,
    setLoading,
    currentRoute,
    setCurrentRoute,
  } = useContext(RoutingContext);

  const [routesEN, setRoutesEN] = useState(null);
  const [routesFR, setRoutesFR] = useState(null);

  const getRoutes = async (locale, setValue) => {

  const routesQuery = `
      query allPagesRouting($locale: String!) {
          pageCollection(locale: $locale) {
            items {
              typename
              sys {
                id
              }
              slug
            }
          }
        }
      `;

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: routesQuery,
        variables: { locale: locale },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setValue(data?.pageCollection.items);
      });
  };

  useEffect(() => {
    if (routesEN && routesFR) {
      const routing = [
        { lang: "en", locale: "en-CA", routes: routesEN },
        { lang: "fr", locale: "fr", routes: routesEN },
      ].reduce((accumulator, routesByLang) => {
        for (let index = 0; index < routesByLang.routes.length; index++) {
          const route = routesByLang.routes[index];
          const { lang: routeLang, locale: routeLocale } = routesByLang;
          const routeId = `_${route.sys.id}`;

          let exact = false;
          let path = `/${routeLang}/${route.slug}`;
          if (route.slug === "dashboard") {
            path = `/${routeLang}/home`;
            exact = true;
          }

          if (accumulator[routeId]) {
            accumulator[routeId][routeLang] = {
              path,
              exact,
              locale: routeLocale,
            };
          } else {
            accumulator[routeId] = {
              [routeLang]: { path, exact, locale: routeLocale },
            };
          }
        }
        return accumulator;
      }, {});

      setAllRoutes(routing);
      setLoading(false);
    }
  }, [routesEN, routesFR]);

  //Load Data
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      getRoutes('en-CA', setRoutesEN);
      getRoutes('fr', setRoutesFR);
    }
  }, [authState, oktaAuth]);

  return {
    setPageContentfulId,
    allRoutes,
    loading,
    currentRoute,
    setCurrentRoute,
  };
};

export default useRouting;
export { useRouting, RoutingProvider };
