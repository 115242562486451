const SECTION_FRAGMENT = `
  fragment SectionFields on Section {
    typename
    sys {
      id
    }
    displayTitle
    titleAs
    titleSize
    titleAlignment
    titleId
    theme
    padding
    componentsCollection(limit: 4) {
      items {
        ...LinkFields
        ...ComponentBlockFields
        ...ParagraphFields
        ...SplitContentWrapperFields
        ...CardCollectionFields
        ...TabsAndAccordionFields 
        ...GridFields       
      }
    }
  }
`;

export { SECTION_FRAGMENT };
