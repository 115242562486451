import { SLIDE_OUT_PANEL_FRAGMENT } from "./SlideOutPanel.fragment";

const COLUMN_SECTION_FRAGMENT = `
  ${SLIDE_OUT_PANEL_FRAGMENT}
  fragment ColumnSectionFields on ColumnSection {
    typename
    sys {
      id
    }
    displayTitle
    sectionLink
    entriesCollection(limit: 10) {
      items {
        ...LinkFields
        ...SlideOutPanelFields
      }
    }
  }
`;

export { COLUMN_SECTION_FRAGMENT };
