const GET_TEAMMEMBER_ENTRY = (id) => `
  query teamMember_${id} ($contentfulId: String!, $locale: String!) {
    teamMember(id: $contentfulId, locale: $locale) {
      sys {
        id
      }        
      details {
        links {
          entries {
            block {
              sys {
                id
              }
            }
            inline {
              sys {
                id
              }
            }
          }
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              description
            }
          }
        }
      }
    }
  }
`;

export { GET_TEAMMEMBER_ENTRY };
