import { StarCard } from "../StarCard";
import { SocialAndAppsSection } from "../SocialAndAppsSection";
import { NewsFeed } from "../NewsFeed";
import { TableConnector } from "../Table";
import { GroupHotelsSection } from "../GroupHotelsSection";

const ComponentBlockConnector = ({ data, userInfo, locale }) => {
  switch (data.componentType.toLowerCase()) {
    case "latest-news":
      return <NewsFeed locale={locale} />;
    case "star-overview":
      return <StarCard locale={locale} />;
    case "social-and-app-section":
      return <SocialAndAppsSection locale={locale} />;
    case "table":
      return <TableConnector data={data.configuration} locale={locale} />;
    case "group-hotels-section-destination-wedding":
      return <GroupHotelsSection locale={locale} type="group" groupName="Destination Wedding" />; 
    case "group-hotels-section-family-travel":
        return <GroupHotelsSection locale={locale} type="collection" groupName="Top rated family resort" />;
    case "group-hotels-section-adults-resort":
        return <GroupHotelsSection locale={locale} type="collection" groupName="Top rated adults resort" />;
    case "group-hotels-section-luxury-travel":
        return <GroupHotelsSection locale={locale} type="collection" groupName="Top rated luxury resort" />;
    case "group-hotels-section-meetings-special-events":
       return <GroupHotelsSection locale={locale} type="group" groupName="Meetings and Special Events" />;  
    case "group-hotels-section-sports-health-wellness":
       return <GroupHotelsSection locale={locale} type="group" groupName="Sports Health and Wellness" />; 
    //case "group-hotels-section-excursions":
    //case "group-hotels-section-cruising":                           
    default:
      return <p>{data.componentType} not implemented</p>;
  }
};

export { ComponentBlockConnector };
