import { CLOUDINARY_ASSET_FRAGMENT } from "../fragments/CloudinaryAsset.fragment";
import { COMPONENT_BLOCK_FRAGMENT } from "../fragments/ComponentBlock.fragment";
import { PARAGRAPH_FRAGMENT } from "../fragments/Paragraph.fragment";

const GET_PARAGRAPH_ENTRY = (id) => `
${CLOUDINARY_ASSET_FRAGMENT}
${PARAGRAPH_FRAGMENT}
${COMPONENT_BLOCK_FRAGMENT}

  query paragraph_${id} ($contentfulId: String!, $locale: String!) {
    paragraph(id: $contentfulId, locale: $locale) {
      sys {
        id
      }        
      content {
        links {
          entries {
            block {
              sys {
                id
              }
              ...CloudinaryAssetFields
              ...ParagraphFields
              ...ComponentBlockFields
            }
            inline {
              sys {
                id
              }
              ...CloudinaryAssetFields
              ...ParagraphFields
              ...ComponentBlockFields
            }
          }
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              description
            }
          }
        }
      }
    }
  }
`;

export { GET_PARAGRAPH_ENTRY };
