import React, { useState, useEffect } from "react";
import { ContentfulContentEntry } from "../ContentfulContentEntry";
import { VWQIcon } from "@sunwing/icons";
import styles from "./HelpfulInfoGroupVwq.module.scss";

const InfoEntry = ({ active, tab, userInfo, locale }) => {

    const [expanded, setExpanded] = useState(false);

    useEffect (() => {
        setExpanded(active);
    }, [active]);

    return (
        <div className={styles.infoContainer}>
            <div className={styles.infoHeader}>
                {tab.heading}  
                <button className={styles.infoBtn} onClick={() => setExpanded(!expanded)}>
                    {expanded ? <VWQIcon name="chevron-up-micro" /> : <VWQIcon name="chevron-down-micro" />}
                </button>
            </div>  
            {expanded &&
                tab.contentCollection.items.map((item) => (
                    <ContentfulContentEntry
                        key={item.sys.id}
                        entry={item}
                        userInfo={userInfo}
                        locale={locale} 
                    />
                ))
            }
        </div>
    )
};

export default InfoEntry;