import { COLUMN_SECTION_FRAGMENT } from "./ColumnSection.fragment";
import { SOCIAL_MEDIA_CARD_FRAGMENT } from "./SocialMediaCard.fragment";

const GRID_FRAGMENT = `
  ${COLUMN_SECTION_FRAGMENT}
  ${SOCIAL_MEDIA_CARD_FRAGMENT}
  fragment GridFields on Grid {
    typename
    sys {
      id
    }
    itemsPerRow
    componentsCollection(limit: 10) {
      items {
        ...ColumnSectionFields
        ...SocialMediaCardFields
      }
    }
  }
`;

export { GRID_FRAGMENT };
