import React, { Component } from "react";
import { Table, Input } from "antd";
import moment from "moment";
import { DeferredDetail } from "./DeferredDetail";
import "./SortTable.scss";
const Search = Input.Search;

class SortTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasDataSource: !!props.dataSource,
      loadingData: !!props.dataSource ? true : false,
      // Add key to initialData
      data: !props.dataSource
        ? props.initialData.map((item, index) => {
            return Object.assign({}, item, { key: index });
          })
        : [],
      filteredData: !props.dataSource
        ? props.initialData.map((item, index) => {
            return Object.assign({}, item, { key: index });
          })
        : [],
      noDataLabel: this.props.config.noData
        ? this.props.config.noData
        : "No Data",
      searchTerm: "",
      searchFields: [],
    };
  }

  componentDidMount() {
    if (this.state.hasDataSource) {
      this.refreshData();
    }
  }

  // Sanitize Config - Fallback to default
  config = {
    paginationSize: this.props.config.paginationSize
      ? this.props.config.paginationSize
      : 5,
    hideColumns: this.props.config.hideColumns
      ? this.props.config.hideColumns
      : [],
  };

  // Create column data for AntDesign Table
  // Remove "hideColumns" columns from table
  // Ignore expandable items
  columns = this.props.config.columns
    .filter((item) => !item.expandable)
    .map((item) => {
      // Base column setup required for AntDesign Table
      let columnSettings = {
        title: item.title,
        key: item.dataIndex,
        dataIndex: item.dataIndex,
      };

      // Add default sort ordered item
      if (item.defaultSortOrder) {
        columnSettings.defaultSortOrder = item.defaultSortOrder;
      }

      if (item.defaultShowSortOrder) {
        let _defaultSortOrderStyle = "default-sorted-ascend";

        if (item.defaultShowSortOrder !== "ascend") {
          _defaultSortOrderStyle = "default-sorted-descend";
        }
        columnSettings.title = ({ sortOrder }) => {
          if (sortOrder) {
            _defaultSortOrderStyle = "";
          }
          return <span className={_defaultSortOrderStyle}>{item.title}</span>;
        };
      }

      // Add sort functions based on type
      if (item.sort && item.sort.length > 0) {
        switch (item.sort) {
          case "text":
            columnSettings.sorter = (a, b) => {
              if (
                (a[item.dataIndex] ? a[item.dataIndex].toLowerCase() : "") >
                (b[item.dataIndex] ? b[item.dataIndex].toLowerCase() : "")
              ) {
                return -1;
              }
              if (
                (a[item.dataIndex] ? a[item.dataIndex].toLowerCase() : "") <
                (b[item.dataIndex] ? b[item.dataIndex].toLowerCase() : "")
              ) {
                return 1;
              }
              return 0;
            };
            break;
          case "date":
            columnSettings.sorter = (a, b) => {
              if (moment(a[item.dataIndex]).isSame(b[item.dataIndex])) {
                return 0;
              } else {
                if (moment(a[item.dataIndex]).isBefore(b[item.dataIndex])) {
                  return -1;
                } else {
                  return 1;
                }
              }
            };
            break;
          default:
            columnSettings.sorter = (a, b) =>
              b[item.dataIndex] - a[item.dataIndex];
        }
      }

      // Add ability to set width of column
      if (item.render && item.render.width) {
        columnSettings.width = item.render.width;
      }

      // Add ability to add class to column
      columnSettings.className = "";
      if (item.render && item.render.className) {
        columnSettings.className = item.render.className;
      }

      // Add ability to define new key for column
      if (item.render && item.render.key) {
        columnSettings.key = item.render.key;
      }

      // Add alternate renders
      if (item.render && item.render.type && item.render.type.length > 0) {
        switch (item.render.type.toLowerCase()) {
          case "status":
            // Pass text label from Razor
            // True = text[0] , False = text[1]
            columnSettings.render = (text) => {
              if (text) {
                return item.render.text[0];
              } else {
                return item.render.text[1];
              }
            };
            break;
          case "date":
            // Pass date format from Razor
            columnSettings.render = (text) => {
              return moment(text, null, item.render.language, true).format(
                item.render.format
              );
            };
            break;
          case "multi-line":
            columnSettings.className += " multi-line";
            columnSettings.render = (text) => {
              return text;
            };
            break;
          case "link":
            let _link = item.render.url;
            let _urlParam = item.render.urlParam;
            let _target = item.render.target;

            columnSettings.render = (text, record, index) => {
              if (_urlParam) {
                _link = item.render.url + record[_urlParam];
              }
              if (_target) {
                return (
                  <a href={_link} target={_target}>
                    {item.render.label}
                  </a>
                );
              } else {
                return <a href={_link}>{item.render.label}</a>;
              }
            };
            break;
          case "append-data":
            columnSettings.render = (text, record, index) => {
              let _combinedDataText = [];
              _combinedDataText.push(text);
              let _appendDataIndexKeys = item.render.appendDataIndexKeys;
              if (_appendDataIndexKeys) {
                for (
                  let _keyIndex = 0;
                  _keyIndex < _appendDataIndexKeys.length;
                  _keyIndex++
                ) {
                  const _key = _appendDataIndexKeys[_keyIndex];
                  _combinedDataText.push(record[_key]);
                }
                return _combinedDataText.join("\n");
              }
              return text;
            };
            break;
          case "component":
            columnSettings.render = (text, record) => {
              switch (item.render.component) {
                // case "DeactivateUser":
                //   return (
                //     <DeactivateUser
                //       csrf={this.props.csrf}
                //       user={record}
                //       text={item.render.config}
                //       actionDeactivateFromAgency={
                //         item.render.config.actionDeactivateFromAgency
                //       }
                //       actionDeactivateFromChain={
                //         item.render.config.actionDeactivateFromChain
                //       }
                //       removeUser={this.removeUser}
                //     />
                //   );
                // case "EditUser":
                //   return (
                //     <EditUser
                //       csrf={this.props.csrf}
                //       user={record}
                //       text={item.render.text}
                //       action={item.render.action}
                //     />
                //   );
                // case "EditDeactivateUser":
                //   return (
                //     <EditDeactivateUser
                //       csrf={this.props.csrf}
                //       type={item.render.config.editUser.type}
                //       user={record}
                //       config={item.render.config}
                //       updateUserRole={this.updateUserRole}
                //       removeUser={this.removeUser}
                //     />
                //   );
                default:
                  return item.render.text.label;
              }
            };
            break;
          default:
            columnSettings.render = (text) => {
              return { text };
            };
        }
      }
      return columnSettings;
    });

  expandableRowKeys = this.props.config.columns
    .filter((item) => item.expandable)
    .sort((a, b) => a.expandableOrder - b.expandableOrder)
    .map((item) => {
      return {
        title: item.title,
        dataIndex: item.dataIndex,
        external: item.external,
        expandableOrder: item.expandableOrder,
      };
    });

  tableHeader = () => {
    const { header } = this.props.config;
    let _header = [];

    if (header && (header.left || header.right)) {
      for (let headerItem of Object.keys(header)) {
        switch (
          header[headerItem].component
            ? header[headerItem].component.toLowerCase()
            : ""
        ) {
          case "component":
            _header.push(
              <div
                className={"sort-table__" + headerItem}
                key={"header__" + headerItem}
              >
                {header[headerItem].render}
              </div>
            );
            break;
          case "text":
            _header.push(
              <div
                className={"sort-table__" + headerItem}
                key={"header__" + headerItem}
              >
                <h2>{header[headerItem].text.title}</h2>
                {header[headerItem].text.subtitle && (
                  <span>{header[headerItem].text.subtitle}</span>
                )}
              </div>
            );
            break;
          case "search":
            _header.push(
              <div
                className="sort-table__search"
                key={"header_search__" + headerItem}
              >
                <Search
                  placeholder={header[headerItem].placeholder}
                  onChange={(event) =>
                    this.handleSearch(event, header[headerItem].fields)
                  }
                />
              </div>
            );
            break;
          default:
            _header.push(
              <div
                className={"sort-table__" + headerItem}
                key={"header__" + headerItem}
              />
            );
        }
      }
      return _header;
    } else {
      return false;
    }
  };

  updateUserRole = (record) => {
    let _updatedData = this.state.data.map((item) => {
      if (item.AgentId === record.agentId) {
        return Object.assign({}, item, {
          RoleInAgency: record.roleInAgency,
          RoleName: record.roleInAgency,
        });
      }
      return item;
    });

    this.setState(
      {
        data: _updatedData,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  removeUser = (record) => {
    let _updatedData = this.state.data.filter((item) => {
      if (item.AgentId) {
        return item.AgentId !== record.agentId;
      } else {
        return item.Id !== record.agentId;
      }
    });

    this.setState(
      {
        data: _updatedData,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  addAdmin = (record) => {
    this.refreshData();
  };

  refreshData() {
    let urlGet = this.props.dataSource;

    fetch(urlGet, {
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        if (res.ResponseCode === 200) {
          this.setState({
            loadingData: false,
            data: res.ResponseObject.map((item, index) => {
              return Object.assign({}, item, { key: index });
            }),
            filteredData: res.ResponseObject.map((item, index) => {
              return Object.assign({}, item, { key: index });
            }),
          });

          this.handleSearch();
        } else {
          if (res.ResponseCode === 400) {
            // Set "No Data" label
            this.setState({
              loadingData: false,
              data: [],
              filteredData: [],
              noDataLabel: res.ResponseText,
            });
          }

          if (res.ResponseCode === 401 && res.ResponseText) {
            window.location.href = res.ResponseText;
          }
        }
      })
      .catch((error) => {
        this.setState({
          loadingData: false,
          data: [],
          filteredData: [],
        });
      });
  }

  handleSearch = (value, searchFields = this.state.searchFields) => {
    let searchValue = this.state.searchTerm;
    if (value) {
      searchValue = value.target.value;
    }

    let filtered = [];
    if (searchFields.length < 1) {
      filtered = this.state.data;
    } else {
      filtered = this.state.data.filter((item) => {
        let _filtered = false;

        searchFields.forEach((filter) => {
          if (
            item[filter] && item[filter]?.toString()
              .toLowerCase()
              .indexOf(searchValue.toString().toLowerCase()) !== -1
          ) {
            _filtered = true;
          }
        });

        return _filtered;
      });
    }

    if (searchValue && searchFields) {
      this.setState({
        searchTerm: searchValue,
        searchFields: searchFields,
        filteredData: filtered,
      });
    }

    if (searchValue && !searchFields) {
      this.setState({
        searchTerm: searchValue,
        filteredData: filtered,
      });
    }

    if (!searchValue && searchFields) {
      this.setState({
        searchFields: searchFields,
        filteredData: filtered,
      });
    }
  };

  render() {
    return (
      <div className="sort-table">
        <Table
          title={this.tableHeader() ? this.tableHeader : undefined}
          columns={this.columns}
          expandedRowRender={
            this.expandableRowKeys.length > 0
              ? (record) => {
                  if (
                    record[this.props.config.externalSource.key] !== null &&
                    record[this.props.config.externalSource.key] !== undefined
                  ) {
                    return (
                      <DeferredDetail
                        data={record}
                        keys={this.expandableRowKeys}
                        source={this.props.config.externalSource}
                      />
                    );
                  } else {
                    return null;
                  }
                }
              : null
          }
          rowClassName={
            this.expandableRowKeys && this.expandableRowKeys.length > 0
              ? (record) => {
                  if (
                    record[this.props.config.externalSource.key] === null ||
                    record[this.props.config.externalSource.key] === undefined
                  ) {
                    return "no-expandable-source";
                  } else {
                    return "";
                  }
                }
              : ""
          }
          onRow={
            this.props.config.onRowClick !== undefined &&
            this.props.config.onRowClick === "MBP"
              ? (record) => {
                  return {
                    onClick: () => {
                      if (record.BookingDetailsURL) {
                        window.open(record.BookingDetailsURL);
                      }
                    },
                  };
                }
              : null
          }
          expandRowByClick={true}
          dataSource={this.state.filteredData}
          pagination={{
            pageSize: this.config.paginationSize,
            hideOnSinglePage: true,
          }}
          loading={this.state.loadingData}
          locale={{ emptyText: this.state.noDataLabel }}
        />
      </div>
    );
  }
}

export default SortTable;
export { SortTable };
