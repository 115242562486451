import React from "react";
import moment from "moment";
import config from "../../app/config";

const Entry = ({ lang, id, title, startedAt }) => (
  <div>
    <a href={`/${lang}/InboxMessage?messageId=${id}&brand=${config.isWestJetMode ? "vwq" : "swg"}`}>
      <span>{moment(startedAt).format("DD MMMM, YYYY, hh:mm a")}</span>
      <br></br>
      <span>{title.length > 30 ? 
                    title.substring(0, 30) + "..." : 
                    title}</span>    
    </a>
    <hr/>
  </div>
);

export default Entry;