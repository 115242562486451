import currency from "currency.js";

const convertCurrency = (value, lang) => {
  let _currency;

  if (lang === "fr") {
    _currency = currency(value, { separator: " ", decimal: "," }).format();
    return _currency + "$";
  } else {
    _currency = currency(value, { separator: ",", decimal: "." }).format();
    return "$" + _currency;
  }
};

export default convertCurrency;
