import React, { useEffect } from "react";
import { RCL } from "../../components/RCL";
import { Section } from "@sunwing/components";
import MessageItem from "./MessageItem";
import styles from "./InboxMessages.module.scss";
import { useApp } from "../../hooks";
import { useRouting } from "../../hooks";

const InboxMessagePage = () => {
    const { inboxMessages } = useApp();
    const { setCurrentRoute } = useRouting();

    useEffect(() => {
        if (setCurrentRoute) {
          setCurrentRoute({
            en: { path: "/en/InboxMessage" + window.location.search },
            fr: { path: "/fr/InboxMessage" + window.location.search},
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
          
    const activeIndex = parseInt(window.location.search.indexOf("?") + 1 < window.location.search.length
    ? window.location.search.split("=")[1]: "0");

    return (
        <div className={styles.messages}>
            <Section
                title={RCL({ searchKey: "messages" })?.toUpperCase()}
                titleSize="h1"
                titleAlignment="left">
                <hr/>
                <div>
                    {inboxMessages &&
                        inboxMessages.messages
                        ?.sort((a, b) => ((a.startDateUtc !== null) && -a.startDateUtc.localeCompare(b.startDateUtc)))
                        .map((mes, index) => {
                            return (
                                <MessageItem
                                    key={index}
                                    active={activeIndex===index}
                                    title={mes.subject}
                                    startedAt={mes.startDateUtc}
                                    endDate={mes.endDateUtc}
                                    image={mes.keys?.filter(item => item.key === "image")?mes.keys?.filter(item => item.key === "image")[0]?.value:""}
                                    redirectUrl={mes.keys?.filter(item => item.key === "redirectTo")?mes.keys.filter(item => item.key === "redirectTo")[0]?.value:""}
                                    inboxText={mes.keys?.filter(item => item.key === "inboxText")?mes.keys.filter(item => item.key === "inboxText")[0]?.value:""}
                                />
                            )
                        })
                    }
                </div>
            </Section>
        </div>
    );
}

export default InboxMessagePage;
export { InboxMessagePage };