import React, { useContext, useEffect } from "react";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { Section } from "@sunwing/components";
import { useRouting } from "../../hooks";
import { MyLoyaltyProgramTabs } from "./MyLoyaltyProgramTabs";
import { RCL } from "../../components/RCL";
import { Breadcrumb } from "../../components/Breadcrumb";
import config from "../../app/config";
import styles from "./PlatinumClubVwq.module.scss";

const MyLoyaltyProgramPage = () => {
  const { setCurrentRoute } = useRouting();
  const { locale } = useContext(RCLContext);

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/my-loyalty-program" },
        fr: { path: "/fr/my-loyalty-program" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Breadcrumb pageSlug={"my-loyalty-program"} locale={locale} />
      <Section
        title={!config.isWestJetMode && RCL({ searchKey: "starheader" })} 
        as="p"
        titleSize="h1"
        titleAlignment="left"
      >
        {config.isWestJetMode && (<div className={styles.platinumClubHeading}>{RCL({ searchKey: "starheader" })} </div>)}
        {config.isWestJetMode ?"" : (<p>
          <RCL searchKey="stardesc" />
        </p>)}
        <MyLoyaltyProgramTabs />
      </Section>
    </div>
  );
};

export default MyLoyaltyProgramPage;
export { MyLoyaltyProgramPage };
