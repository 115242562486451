import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import qs from "query-string";
import config from "./config";
import CorsErrorModal from "../components/corserrormodal/CorsErrorModal";
import { RCLProvider } from "../components/RCL";
import { RoutingProvider, AppProvider } from "../hooks";
import { Routing } from "../Routing";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const [cookies] = useCookies(["nf_lang"]);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const { returnUrl } = qs.parse(location.search);

    if (returnUrl) {
      history.replace(toRelativeUrl(returnUrl, window.location.origin));
    } else {
      history.replace(
        toRelativeUrl(
          originalUri || `/${cookies.nf_lang ?? "en"}/home`,
          window.location.origin
        )
      );
    }
  };

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push(`/${cookies.nf_lang ?? "en"}`);
  };

  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);

  return (
    <RCLProvider>
      <RoutingProvider>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <AppProvider>
            <CorsErrorModal
              {...{ corsErrorModalOpen, setCorsErrorModalOpen }}
            />
            <Routing setCorsErrorModalOpen={setCorsErrorModalOpen} />
          </AppProvider>
        </Security>
      </RoutingProvider>
    </RCLProvider>
  );
};

export default App;
