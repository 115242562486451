/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";
import { GET_PAGE_SECTION } from "../../query/page.query";

export const useSectionItems = (contentfulId, sectionIndex, locale) => {
  const { oktaAuth } = useOktaAuth();
  const [items, setItems] = useState(null);

  const getSectionItems = async (contentfulId, sectionIndex, locale) => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: GET_PAGE_SECTION(sectionIndex),
        variables: { contentfulId : contentfulId, locale: locale, skip:sectionIndex},
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
          setItems(data?.page?.sectionsCollection?.items);
        }
      });
  };

  useEffect(() => {
    getSectionItems(contentfulId, sectionIndex, locale);
  }, [contentfulId, sectionIndex, locale]);

  return { items };
};
