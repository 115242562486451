const CARD_FRAGMENT = `
  fragment CardFields on Card {
    typename
    sys {
      id
    }
    displayTitle
    variant
    titleAs
    titleSize
    titleWeight
    titleIsUppercase
    colour
    backgroundColour
    image
    logo
    logoPosition
    content {
      json
    }
    link {
      ...LinkFields
    }
    effectiveDate
    expiryDate
  }
`;

export { CARD_FRAGMENT };
